import { RRuleFrequency } from '@fitness-force/enum';
import { RelativeDate } from './reportaccessrequest';

export interface CreateReportFilterRequest {
  name: string;
  payload: string;
  relative_date: string;
}

export interface CreateLongReportFilterRequest {
  payload: string;
  relative_date_range: RelativeDate;
}

export interface UpdateReportFilterRequest {
  name: string;
  payload: string;
  relative_date: RelativeDate;
}

export interface ReportFilter {
  created_by: number;
  created_date: string;
  id: number;
  name: string;
  payload: string;
  relative_date_range: string;
  updated_date: string;
}

export interface ScheduleReportFilterRequest {
  rule: string;
  start_date: string;
  email_ids: string;
}

export interface LongReportRequest {
  payload: string;
  email_id: string;
}

export interface ScheduleFilterDeleteRequest {
  tenant_id: number;
  report_key : string;
  filter_id  : number;
  schedule_id  : number;
}

export interface ReportFilterResponse {
  items: ReportFilter[];
}

export interface ReportFilterScheduleResponse
{
  id: number;
  report_filter_id: number;
  company_id: number;
  tenant_id: number;
  report_key: string;
  status: boolean;
  rule: string;
  ruleFrequency: string;
  email_ids: string;
  next_run_date: string;
  last_run_date: string;
  retry_count: number;
  track_mode: string;
  created_by: number;
  created_date: string;
  updated_date:string;
}

import { HttpClient } from '@angular/common/http';
import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  NgModule,
  ChangeDetectorRef,
  Inject,
  LOCALE_ID,
} from '@angular/core';
import { CommonService, ServiceCategoryService, TaxGroupService } from '@fitness-force/api';
import { ErrorMsgComponentModule, NotFoundError } from '@fitness-force/errors';
import { Country, TENANTSINTERFACE } from '@fitness-force/models';
import { Store } from '@ngrx/store';
import {
  TranslateModule,
  TranslateLoader,
  TranslateService,
} from '@ngx-translate/core';
import { COUNTRY_MASTER_STATE, TENANTS_INFO } from 'apps/ff-frontend/src/app/state/app.state';
import { MessageService } from 'primeng/api';

import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { DividerModule } from 'primeng/divider';
import { InputTextModule } from 'primeng/inputtext';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { RippleModule } from 'primeng/ripple';
import { ToastModule } from 'primeng/toast';

import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogModule,
  DynamicDialogRef,
} from 'primeng/dynamicdialog';

import { DayAgoPipeModule } from '../../filters/dayago.pipe';
import { FactoryHttpLoader } from '../helpsupport/helpsupport.component';

import { ToastComponentModule } from '../toast/toast.component';
import { CommonModule, getCurrencySymbol } from '@angular/common';
import {
  FormsModule,
  ReactiveFormsModule,
  FormBuilder,
  Validators,
  FormGroup,
  FormControl,
  AbstractControl,
} from '@angular/forms';
import { InputNumberModule } from 'primeng/inputnumber';
import { DropdownModule } from 'primeng/dropdown';
import { InputSwitchModule } from 'primeng/inputswitch';
import { MembershipBehaviour, PlanType } from 'libs/enum/src/lib/plansubscription';
import { BehaviorSubject, forkJoin, Observable } from 'rxjs';
import { Status } from '@fitness-force/enum';
import { ServiceCategoryCreateComponentModule } from '../service-category-create/service-category-create.component';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';

@Component({
  selector: 'plan-service-modal',
  templateUrl: './plan-service-modal.component.html',
  styleUrls: ['./plan-service-modal.component.scss'],
  providers: [MessageService, DialogService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlanServiceModalComponent implements OnInit {
  getAccessTenantList$: Observable<any> = new Observable();
  showOptions: boolean = false;
  tenantId: number = 0;
  name_primary_language!:string

  activeEditService!: any;
  activeEditIndex!:number;
  serviceCreateForm: FormGroup;
  membershipBehaviour:any[]=[];
  services=[];
  taxGroup=[];
  currencySymbol:any;
  subscriptionCategory!:any
  addCategory=false;



  constructor(
    private store: Store,
    private serviceCategoryService: ServiceCategoryService,
    private taxGroupService:TaxGroupService,
    private messageService: MessageService,
    private translate: TranslateService,
    private ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private fb: FormBuilder,
    private cd: ChangeDetectorRef,
    private commonService: CommonService,
    @Inject(LOCALE_ID) public locale: string,
  ) {
    if(this.config.data?.service){
      this.activeEditService=this.config.data?.service;
        this.activeEditIndex=this.config.data?.index;
    }
    if(this.config.data?.subscriptionCategory){
      this.subscriptionCategory=this.config.data?.subscriptionCategory;
    }
  }
  // Enums
  public get PlanType() {
    return PlanType;
  }

  ngOnInit(): void {
    this.getAccessTenantList$ = this.store.select(TENANTS_INFO);
    this.getAccessTenantList$.subscribe((tenant: TENANTSINTERFACE) => {
      this.tenantId = tenant.selected_tenant.id;
      if (this.tenantId > 0) {
        this.store.dispatch({ type: 'ROUTER_GO' });
      }

      this.preFormData();

      this.modifyFormByBehavior(this.activeEditService && this.activeEditService.behaviour?this.activeEditService.behaviour:"CheckIn");
      this.handleBehaviorChanges();
    });
    // Could be moved to any Facade service
    this.store.select(COUNTRY_MASTER_STATE).subscribe((country: Country) => {
      this.currencySymbol=getCurrencySymbol(country.currency_code, 'wide', this.locale);
    });


    for (let [key, value] of Object.entries(MembershipBehaviour)) {
      this.membershipBehaviour.push({ name: value, key });
    }
  }


  preFormData() {
    let forkArray = [
      this.serviceCategoryService.getServiceCategories(this.tenantId),
      this.taxGroupService.getTaxGroups(this.tenantId),
    ];

    forkJoin(forkArray).subscribe({
      next: (results: any) => {
        if(results[0] && results[0]?.items ){
          this.services= results[0]?.items?.filter(
            (value: any) =>
              value.status == Status.Active.toString() ||
              value.status == Status[Status.Active]
          );
        }else this.services=[]
        if(results[1] && results[1].items){
          this.taxGroup= results[1].items;
        }else this.taxGroup= [];
        this.buildForm();
      },
      error:(error:any)=>{
        this.handleError(error);
      }
    });
  }


  reloadServices(id:number){
    this.serviceCategoryService.getServiceCategories(this.tenantId).subscribe({
      next:(res:any)=>{
        this.services= res.items;
      let createdCategory = this.services.find((item: any) => item.id == id);
      this.CategoryId?.setValue(createdCategory);
      this.services= res.items.filter(
        (value: any) =>
          value.status == Status.Active.toString() ||
          value.status == Status[Status.Active]
      );
      this.cd.detectChanges();
      },
      error:(error:any)=>{

      }
    },)
  }

  handleError(error: any) {
    if (error instanceof NotFoundError) {
      this.translate
        .get([
          'common.errors.SERVER_SIDE.ERROR_OCCURRED',
          'common.errors.SERVER_SIDE.PAGE_NOT_FOUND',
        ])
        .subscribe((translations: any) => {
          this.messageService.add({
            severity: 'error',
            summary: translations['common.errors.SERVER_SIDE.ERROR_OCCURRED'],
            detail: translations['common.errors.SERVER_SIDE.PAGE_NOT_FOUND'],
          });
        });
    } else {
      this.translate
        .get([
          'common.errors.SERVER_SIDE.ERROR_OCCURRED',
          'common.errors.SERVER_SIDE.SOMETHING_WRONG',
        ])
        .subscribe((translations: any) => {
          this.messageService.add({
            severity: 'error',
            summary: translations['common.errors.SERVER_SIDE.ERROR_OCCURRED'],
            detail: translations['common.errors.SERVER_SIDE.SOMETHING_WRONG'],
          });
        });
    }
  }


   // Enums
   public get MembershipBehaviour() {
    return MembershipBehaviour;
  }

  get Behavior():AbstractControl | null {
    return this.serviceCreateForm?.get('behaviour');
  }

  get Sessions():AbstractControl | null {
    return this.serviceCreateForm?.get('sessions')?this.serviceCreateForm?.get('sessions'):null;
  }

  get CategoryId():AbstractControl | null  {
    return this.serviceCreateForm?.get('category_id');
  }

  get TaxGroupId():AbstractControl | null  {
    return this.serviceCreateForm?.get('taxgrp_id');
  }
  get TaxItemCode():AbstractControl | null  {
    return this.serviceCreateForm?.get('tax_item_code');
  }
  get BaseCostInMinorCurrency():AbstractControl | null  {
    return this.serviceCreateForm?.get('base_cost_in_minor_currency');
  }




  setBehavior(value:any) {
     this.Behavior?.setValue(value);
  }


  handleBehaviorChanges() {
    this.serviceCreateForm
      ?.get('behaviour')
      ?.valueChanges.subscribe((value) => {
        this.modifyFormByBehavior(value.key);
      });
  }


  buildForm() {
    this.serviceCreateForm = this.fb.group({
      base_cost_in_minor_currency: [
        this.activeEditService && this.activeEditService.base_cost_in_minor_currency?
        this.commonService.ConvertAmountFromMinorCurrency(this.activeEditService.base_cost_in_minor_currency):'',
        Validators.required
      ],
      behaviour: [
        this.initializeSubscriptionCategoryForm(),
        Validators.required
      ],
      category_id: [
        this.initializeCategoryIdForm(),
        Validators.required
      ],
      taxgrp_id: [
        this.initializeTaxGroupIdForm(),
        Validators.required
      ],
      tax_item_code: [
        this.activeEditService &&  this.activeEditService.tax_item_code?this.activeEditService.tax_item_code:'',
        Validators.required
      ],
      require_base_membership: [
        this.activeEditService &&  this.activeEditService.require_base_membership? this.activeEditService.require_base_membership:true,
        Validators.required
      ],
      is_session_owner_required: [
        this.activeEditService &&   this.activeEditService.is_session_owner_required?this.activeEditService.is_session_owner_required:false,
        Validators.required
      ],
      sessions: [
        this.activeEditService &&   this.activeEditService.sessions?this.activeEditService.sessions:0,
        Validators.required
      ],
      is_base_membership: [this.activeEditService && this.activeEditService.is_base_membership?this.activeEditService.is_base_membership:false, Validators.required],
      non_recurring_services: [
        this.activeEditService &&   this.activeEditService.non_recurring_services?this.activeEditService.non_recurring_services:false, Validators.required],

    });
    if(this.subscriptionCategory && (this.subscriptionCategory.name==PlanType.PaidInFull ||this.subscriptionCategory.name==PlanType.DropIn))
    {
      this.serviceCreateForm.get('non_recurring_services')?.setValue(true);
      this.serviceCreateForm.get('non_recurring_services')?.disable();
    }
    this.cd.detectChanges();
  }




  initializeSubscriptionCategoryForm() {
    if ( this.activeEditService && this.activeEditService.behaviour) {
      for (let [key, value] of Object.entries(MembershipBehaviour)) {
        if (key == this.activeEditService.behaviour) {
          return { name: value, key };
        }
      }
      return {
        name: MembershipBehaviour.MemberShip,
        key: 'MemberShip',
      };
    } else
      return {
        name: MembershipBehaviour.MemberShip,
        key: 'MemberShip',
      };
  }

  initializeCategoryIdForm(){
    if ( this.activeEditService && this.activeEditService.category_id){
        return this.services.filter((element:any)=>{
          return element.id==this.activeEditService.category_id
        })[0];
    }
    return null;
  }

  initializeTaxGroupIdForm(){
    if ( this.activeEditService && this.activeEditService.taxgrp_id){ /// this.activeEditService.taxgrp_id){
        return this.taxGroup?.filter((element:any)=>{
         return  element.id==this.activeEditService.taxgrp_id;   ///this.activeEditService.taxgrp_id
        })[0];

    }
    return null;
  }

  modifyFormByBehavior(value: string) {
    switch(value){
      case "MemberShip":

        this.Sessions?.clearValidators();
        break;
      case "AppointmentPackage":
        this.Sessions?.addValidators(Validators.required)
        break;
      case  "ClassPackage":
        this.Sessions?.addValidators(Validators.required)
        break;
    }

  }

  saveServiceCreateForm(){
    if(this.serviceCreateForm.valid){
      let service= this.cleanServiceFormValues(this.serviceCreateForm.getRawValue());
      service.base_cost_in_minor_currency= this.commonService.ConvertMinorCurrencyFromAmount(
        service.base_cost_in_minor_currency
      );
      if(this.activeEditService)
      this.ref.close({service,index:this.activeEditIndex});
      else this.ref.close({service});
    }else{
      Object.keys(this.serviceCreateForm.controls).forEach((field) => {
        const control = this.serviceCreateForm.get(field);
        control?.markAsTouched({ onlySelf: true });
      });
    }
  }

  cleanServiceFormValues(values:any){
    let serviceData={...values,
      behaviour:values.behaviour.key,
      category_id:values.category_id.id,
      taxgrp_id:values.taxgrp_id.id,
      name_primary_language:values.category_id.name_primary_language,
    };
    return serviceData;

  }


  closeDialog() {
    this.ref.close(null);
  }

  showCategoryDialog(){
    this.addCategory=true;
    this.cd.detectChanges();
  }
  changedEditCategory(event:any){
    if(event){
      this.reloadServices(event.id);
    }
    this.addCategory=false;
  }

}

@NgModule({
  declarations: [PlanServiceModalComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ButtonModule,
    InputTextModule,
    RippleModule,
    DialogModule,
    DividerModule,
    OverlayPanelModule,
    DayAgoPipeModule,

    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: FactoryHttpLoader,
        deps: [HttpClient],
      },
    }),
    ToastComponentModule,
    ErrorMsgComponentModule,
    ToastModule,
    DynamicDialogModule,
    InputNumberModule,
    DropdownModule,
    InputSwitchModule,
    ServiceCategoryCreateComponentModule,
    MessagesModule,
    MessageModule,

  ],
  exports: [PlanServiceModalComponent],
})
export class PlanServiceModalComponentModule {}


interface PreferedLanguages {
    COUNTRY: string;
    LANG_CODE: string;
    LANG_ABBRE: string;
}

interface CompanyDetailsInterafce {
    id: number;
    account_manager: CompanyAccountManager;
    status: string;
    ff_package_template_name: string;
    logo: string;
    member_portal_url: string;
    reservation_portal_url: string;
    purchase_portal_url: string;
    app_type: string;
    created_by: number;
    created_date: string;
    updated_by: number;
    updated_date: string;
    ff_package_template_id: number;
    name_primary_language: string;
    name_secondary_language: string;
    subdomain: string;
    purchase_portal_enable: boolean;
    member_portal_enable: boolean;
    member_app_android_link: string;
    member_app_ios_link: string;
    primary_language: string;
    is_secondary_language: boolean;
    secondary_language: string;
    email_id: string;
    website: string;
    phone1: string;
    phone2: string;
    address1: string;
    address2: string;
    about_us_primary_language: string;
    about_us_secondary_language: string;
    is_privacy_policy_url: false;
    is_terms_of_service_url: false;
    privacy_policy: string;
    terms_of_service: string;
    privacy_policy_url: string;
    terms_of_service_url: string;
    suspended_by: number;
    suspended_date: string;
    cancelled_by: number;
    cancelled_date: string;
    supported_languages: Array<string>;
    suspension_reason: string;
    cancelled_reason: string;
    allow_remote_fflogin: boolean;
}

interface AccMgrTiming {
    day: number;
    start_time: string;
    end_time: string;
    is_holiday: boolean;
}

interface CompanyAccountManager {
    id: number;
    name: string;
    picture: string;
    phone_number: string;
    email: string;
    description: string;
    timing: Array<AccMgrTiming>;
}

export interface CompanyInfoStoreInterface {
    compDetails: CompanyDetailsInterafce;
    selectedLang: PreferedLanguages;
    direction: string;
    err: { details: object, error: boolean }
}

export interface LoginDataModel {
    "email_id": string;
    "password": string;
    "company_id": number
  }
  
  export interface SignupDataModel {
      name: string;
      email: string;
      password: string;
      confirmpassword: string;
  }


  export interface LoginData {
    username: string;
    password: string;
  }
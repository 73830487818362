import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  NgModule,
  Output,
  EventEmitter,
  Input,
  ChangeDetectorRef,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { StyleClassModule } from 'primeng/styleclass';
import { CalendarModule } from 'primeng/calendar';
import { ChipsModule } from 'primeng/chips';
import { DropdownModule } from 'primeng/dropdown';
import {
  FormBuilder, FormControl, FormGroup, FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import {
  AppErrors,
  ErrorMsgComponentModule,
  NotFoundError,
} from '@fitness-force/errors';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  Client,
  Country,
  CreateOpportunityRequest,
  GetStaffListResponse,
  ListOpportunityStageMasterResponse,
  ListServiceTypeResponse,
  OpportunityResponse,
  OpportunityStageMasterResponse,
  PagedResults,
  PaginationFilter,
  ServiceTypeResponse,
  STAFFINTERFACE,
  TENANTSINTERFACE,
  UpdateOpportunityRequest,
} from '@fitness-force/models';
import { ActivatedRoute, Router } from '@angular/router';
import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { UserService } from 'libs/api/src/lib/user.service';

import { DialogModule } from 'primeng/dialog';
import { ServiceTypeService } from 'libs/api/src/lib/serviceType/service-type.service';
import { MultiSelectModule } from 'primeng/multiselect';
import { SelectButtonModule } from 'primeng/selectbutton';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputMaskModule } from 'primeng/inputmask';
import { InputSwitchModule } from 'primeng/inputswitch';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { OpportunityService } from 'libs/api/src/lib/CRM/opportunity.service';
import { OpportunityBoardType, Status, } from '@fitness-force/enum';
import { CONSTANTS_CRM } from '@fitness-force/constants';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import {
  COMPANY_INFO,
  COUNTRY_MASTER_STATE,
  STAGE_MASTER_STATE,
  TENANTS_INFO,
} from 'apps/ff-frontend/src/app/state/app.state';
import { CommonModule, DatePipe } from '@angular/common';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AssignedToComponentModule, ErrorsComponentModule } from '@fitness-force/shared';
import { MiniSearchComponentModule } from '../../../components/search/mini-search/mini-search.component';
import { AuthSevices, CommonService, OpportunityStageService, } from '@fitness-force/api';

import { MessageService } from 'primeng/api';
import { MessagesModule } from 'primeng/messages';
import { ToastModule } from 'primeng/toast';
import { DividerModule } from 'primeng/divider';
import { TextTransFormPipeModule } from '../../../filters/text-transform.pipe';
import { ShortNamePipeModule } from 'libs/shared/src/lib/filters/name-initials.pipe';
import { AvatarModule } from 'primeng/avatar';
import { ChipModule } from 'primeng/chip';

@Component({
  selector: 'create-opportunity',
  templateUrl: './create-opportunity.component.html',
  styleUrls: ['./create-opportunity.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [MessageService, DatePipe],
})
export class CreateOpportunityComponent implements OnInit, OnChanges {
  frmgroupCreateOpportunity: FormGroup = new FormGroup({});
  closeDialog: boolean = false;

  //used for popup close and open
  @Input('visible') showPopup: boolean = false;
  @Input('opportunity') oOpportunity: OpportunityResponse | any;
  @Input('clientInfo') clientInfo: any[] = [];

  @Output() hidePopup: EventEmitter<any> = new EventEmitter();

  public showMiniSearch: boolean = false;
  public clients: any = [];

  errMsg: Error | any;
  tenantId: number = 0;
  clientId: number = 0;

  //#region Variables
  convertibility: Convertibility[] = CONSTANTS_CRM.OPPORTUNITY_CONVERTITBILITY;
  serviceTypeCollection!: Option[];
  assignedCollection!: Option[];
  opportunityStageCollection!: Option[];

  interestedInSelectedTitle: string = '';
  titleValueChanged: boolean = false;

  showErrorPopup = new BehaviorSubject(false);
  errorMessages = [] as any;
  stageMaster$: Observable<any> = new Subject();

  _isEditMode: boolean = false;
  _selectedstaff = new BehaviorSubject<number>(0);
  country: Country;

  isClientEditMode: boolean = false;

  //#endregion

  public companyInfoDetail$!: Observable<any>;
  companyDetails: any;
  constructor(
    private fb: FormBuilder,
    public translate: TranslateService,
    private router: Router,
    private actRoute: ActivatedRoute,
    private userService: UserService,
    public store: Store,
    private serviceType: ServiceTypeService,
    private opportunityService: OpportunityService,
    private opportunityStageService: OpportunityStageService,
    private messageService: MessageService,
    public authService: AuthSevices,
    public changeDetectorRef: ChangeDetectorRef,
    public datepipe: DatePipe,
    private commonService: CommonService
  ) {
    this.componentInit();
    this.SetFormFields();
  }

  HideErrorPopup(event: any) {
    this.showErrorPopup?.next(false);
    this.errorMessages = [];
  }

  ngOnInit(): void {
    if (this.oOpportunity) {
      this.setOpportunityData(this.oOpportunity);
      this._isEditMode = true;
    }

    this.setVariable();
    this._selectedstaff.next(Number(this.authService.loggedInStaffId));
    this.store.select(COUNTRY_MASTER_STATE).subscribe((country: Country) => {
      this.country = country;
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.clientInfo && changes.clientInfo.currentValue && changes.clientInfo.currentValue.length) {
      this.clientInfo = changes.clientInfo.currentValue;
      this.clients = changes.clientInfo.currentValue;
    }

    if (changes.oOpportunity && changes.oOpportunity.currentValue) {
      this.clientInfo = [changes.oOpportunity.currentValue.client_info];
      this.clients = [changes.oOpportunity.currentValue.client_info];
      this.setOpportunityData(this.oOpportunity);
      this._isEditMode = true;
    }
  }

  get clientIds(): number[] {
    return this.clientInfo?.map((o: any) => { return o.id });
  }

  SetFormFields(): void {
    this.frmgroupCreateOpportunity = this.fb.group({
      ddlConvertibility: new FormControl('', [Validators.required]),
      ddlOpportunityStage: new FormControl('', [Validators.required]),
      serviceType: new FormControl('', [Validators.required]),
      expectedamount: new FormControl('', []),
      expecteddate: new FormControl('', []),
      title: new FormControl('', [Validators.required]),
      comments: new FormControl('', []),
      ddlSelectedAssigned: new FormControl('', [Validators.required]),
    });

    this.frmgroupCreateOpportunity.controls.title.valueChanges.subscribe(
      (value: any) => {
        //console.log(value);
      }
    );
  }

  componentInit() {
    this.companyInfoDetail$ = this.store.select(COMPANY_INFO);
    this.companyInfoDetail$.subscribe((responseData) => {
      this.companyDetails = responseData;
      this.translate.setDefaultLang(responseData.selectedLang.LANG_CODE);
      this.translate.use(responseData.selectedLang.LANG_CODE);
    });

    this.store.select(TENANTS_INFO).subscribe((tenant: TENANTSINTERFACE) => {
      this.tenantId = tenant.selected_tenant.id;
      if (this.tenantId > 0) {
        this.store.dispatch({ type: 'ROUTER_GO' });
      }
    });
    this.stageMaster$ = this.store.select(STAGE_MASTER_STATE);
  }

  setVariable() {
    this.showMiniSearch = this.clients.length == 0;
    //this.BindUsers();
    this.BindServiceType();
    this.BindOpportunityStageMaster();
  }

  // #region "getter"

  get SelectedOpportnityStage() {
    return this.frmgroupCreateOpportunity.controls
      .ddlOpportunityStage as FormControl;
  }

  get SelectedConvertibility() {
    return this.frmgroupCreateOpportunity.controls
      .ddlConvertibility as FormControl;
  }

  get SelectedAssigned(): FormControl {
    return this.frmgroupCreateOpportunity.controls
      .ddlSelectedAssigned as FormControl;
  }

  get ExpectedClosureDate(): FormControl {
    return this.frmgroupCreateOpportunity.controls.expecteddate as FormControl;
  }

  get ExpectedAmount(): FormControl {
    return this.frmgroupCreateOpportunity.controls
      .expectedamount as FormControl;
  }
  get SelectedTitle(): FormControl {
    return this.frmgroupCreateOpportunity.controls.title as FormControl;
  }

  get Comments(): FormControl {
    return this.frmgroupCreateOpportunity.controls.comments as FormControl;
  }

  get ServiceType(): FormControl {
    return this.frmgroupCreateOpportunity.controls.serviceType as FormControl;
  }

  // #region  "Request Response functions"

  BindUsers(): void {
    const paginationFilter: PaginationFilter = {
      page_number: 1,
      page_size: 9999999,
    };

    this.userService.getStaffList(paginationFilter, 0).subscribe({
      next: (response: PagedResults<GetStaffListResponse>) => {
        this.assignedCollection = [];
        this.assignedCollection.push({ name: 'Select', value: 0 });
        response.items.forEach((getStaffListResponse: GetStaffListResponse) => {
          this.assignedCollection.push({
            name: getStaffListResponse.name,
            value: getStaffListResponse.id,
          });
        });
      },
      error: (error) => {
        if (error instanceof NotFoundError) {
          this.errMsg = error.originalError.message;
        } else {
          alert('something went wrong');
        }
      },
    });
  }

  BindServiceType(): void {
    this.serviceType.GetServiceType(this.tenantId).subscribe({
      next: (serviceTypes: ListServiceTypeResponse) => {
        this.serviceTypeCollection = [];
        if (serviceTypes.items) {
          serviceTypes.items.forEach((serviceType: ServiceTypeResponse) => {
            let status = serviceType.status.toString();
            let varStatus: string = Status[Status.Active];
            if (this.oOpportunity) {
              if (status === varStatus || this.oOpportunity.service_type_id) {
                this.serviceTypeCollection.push({
                  name: serviceType.name_primary_language,
                  value: serviceType.id,
                });
              }
            } else {
              if (status === varStatus) {
                this.serviceTypeCollection.push({
                  name: serviceType.name_primary_language,
                  value: serviceType.id,
                });
              }
            }
          });
        }

        if (this.oOpportunity) {
          let data: Option[] = [];
          for (var iCtr in this.oOpportunity.service_type) {
            let oData = this.serviceTypeCollection.find(
              (x) => x.value == this.oOpportunity.service_type[iCtr].id
            );
            if (oData) data.push(oData);
          }
          this.ServiceType.setValue(data);
        }
      },
      error: (error) => {
        if (error instanceof NotFoundError) {
          this.errMsg = error.originalError.message;
        } else {
          alert('something went wrong');
        }
      },
    });
  }

  BindOpportunityStageMaster(): void {
    this.opportunityStageService
      .OpportunityStageMasterListService(
        this.tenantId,
        OpportunityBoardType.NewMemberBoard
      )
      .subscribe({
        next: (listOpportunityStage: ListOpportunityStageMasterResponse) => {
          this.opportunityStageCollection = [] as Option[];
          listOpportunityStage.items.forEach(
            (opportunityStage: OpportunityStageMasterResponse) => {
              this.opportunityStageCollection.push({
                name: opportunityStage.name_primary_language,
                value: opportunityStage.id,
              });
            }
          );
          if (this.oOpportunity) {
            let oData = this.opportunityStageCollection.find(
              (x) => x.value == this.oOpportunity.stage_master_id
            );
            this.SelectedOpportnityStage.setValue(oData);
          }

        },
        error: (error) => {
          if (error instanceof NotFoundError) {
            this.errMsg = error.originalError.message;
          } else {
            alert('something went wrong');
          }
        },
      });
  }

  SaveData(): void {    
    if (this._isEditMode) {
      this.UpdateOpportunity();
    } else {
      this.CreateOpportunity();
    }
  }

  UpdateOpportunity(): void {
    this.frmgroupCreateOpportunity.markAllAsTouched();

    if (!this.frmgroupCreateOpportunity.valid) {
      return;
    }
    let updateOpportunity: UpdateOpportunityRequest = {} as UpdateOpportunityRequest;
    updateOpportunity.comments = this.Comments.value;

    if (this.ExpectedAmount.value == undefined || this.ExpectedAmount.value == '')
      updateOpportunity.value_in_minor_currency = 0;
    else
      updateOpportunity.value_in_minor_currency = this.commonService.ConvertMinorCurrencyFromAmount(this.ExpectedAmount.value);

    if (this.ExpectedClosureDate.value == undefined || this.ExpectedClosureDate.value == '')
      updateOpportunity.expected_closure_date = undefined;
    else
      updateOpportunity.expected_closure_date = this.ExpectedClosureDate.value;

    updateOpportunity.name = this.SelectedTitle.value;
    updateOpportunity.hot_warm_cold = this.SelectedConvertibility.value.value;
    updateOpportunity.service_type_ids = Array.prototype.map.call(
      this.ServiceType.value,
      function (item) {
        return item.value;
      }
    ) as number[];
    updateOpportunity.stage_master_id = this.SelectedOpportnityStage.value.value;
    updateOpportunity.assigned_to_staff_id = this.SelectedAssigned.value.id;

    this.opportunityService
      .OpportunityUpdateService(
        this.tenantId,
        OpportunityBoardType.NewMemberBoard,
        this.oOpportunity.id,
        updateOpportunity
      )
      .subscribe({
        next: async (data: any) => {
          if (data || data?.id) {
            // console.log('DATA', data);
            this.translate
              .get('OPPORTUNITY.UPDATE.OPPORTUNITY_CREATE_MSG')
              .subscribe((translation) => {
                this.messageService.add({
                  severity: 'success',
                  summary: 'Success',
                  detail: translation, //'Scheduled Filter Deleted Successfully',
                });
              });

            this.setHidePopup(true);
          } else {
            this.errorMessages.push(data.info);
            this.showErrorPopup?.next(true);
          }
        },
        error: (error: AppErrors) => {
          // console.log(error, 'errororor');
          if (error instanceof NotFoundError) {
            this.errorMessages.push(error.originalError.error.errorMessage);
            this.showErrorPopup?.next(true);
          } else {
            if (error.originalError.status == 500) {
              this.errorMessages.push(error.originalError.error);
              this.showErrorPopup?.next(true);
            } else {
              error.originalError.error.forEach((e: any) => {
                this.errorMessages.push(e.errorMessage);
              });
              this.showErrorPopup?.next(true);
            }
          }
        },
      });
  }

  CreateOpportunity(): void {    
    this.frmgroupCreateOpportunity.markAllAsTouched();
    if (!this.frmgroupCreateOpportunity.valid) {
      return;
    }

    let createOpportunity: CreateOpportunityRequest = {} as CreateOpportunityRequest;
    createOpportunity.client_id = [this.clients?.clt_id || this.clientInfo[0]?.id]; //[this.clients[0]?.clt_id || this.clientInfo[0]?.id];
    createOpportunity.comments = this.Comments.value;

    if (this.ExpectedAmount.value)
      createOpportunity.value_in_minor_currency = this.commonService.ConvertMinorCurrencyFromAmount(this.ExpectedAmount.value);
    else createOpportunity.value_in_minor_currency = 0;

    if (this.ExpectedClosureDate.value == undefined || this.ExpectedClosureDate.value == '')
      createOpportunity.expected_closure_date = undefined;
    else
      createOpportunity.expected_closure_date = this.ExpectedClosureDate.value;

    createOpportunity.name = this.SelectedTitle.value;
    createOpportunity.hot_warm_cold = this.SelectedConvertibility.value.value;
    createOpportunity.service_type_ids = Array.prototype.map.call(
      this.ServiceType.value,
      function (item) {
        return item.value;
      }
    ) as number[];
    createOpportunity.stage_master_id = this.SelectedOpportnityStage.value.value;
    createOpportunity.assigned_to_staff_id = this.SelectedAssigned.value.id;

    this.opportunityService
      .OpportunityCreateService(
        this.tenantId,
        OpportunityBoardType.NewMemberBoard,
        createOpportunity
      )
      .subscribe({
        next: async (data: any) => {
          if (parseInt(data) > 0) {
            //  console.log('DATA', data);
            this.translate
              .get('OPPORTUNITY.CREATE.OPPORTUNITY_CREATE_MSG')
              .subscribe((translation) => {
                this.messageService.add({
                  severity: 'success',
                  summary: 'Success',
                  detail: translation,
                });
                this.setHidePopup(true);
              });

            await this.timeout(1000);
            this.setHidePopup();

          } else {
            this.errorMessages.push(data.info);
            this.showErrorPopup?.next(true);
          }
        },
        error: (error: AppErrors) => {
          if (error instanceof NotFoundError) {
            this.errorMessages.push(error.originalError.error.errorMessage);
            this.showErrorPopup?.next(true);
          } else if (
            error.originalError &&
            error.originalError.error &&
            error.originalError.error[0]
          ) {
            let currentError = error.originalError.error[0];
            if (currentError.errorCode == 'duplicatevalue') {
              this.frmgroupCreateOpportunity.controls['title'].setErrors({
                duplicate: true,
              });
              this.frmgroupCreateOpportunity.controls['serviceType'].setErrors({
                duplicate: true,
              });
              this.changeDetectorRef.detectChanges();
              return ;
            }
          }
          {
            if (error.originalError.status == 500) {
              this.errorMessages.push(error.originalError.error);
              this.showErrorPopup?.next(true);
            } else {
              error.originalError.error.forEach((e: any) => {
                this.errorMessages.push(e.errorMessage);
              });
              this.showErrorPopup?.next(true);
            }
          }
        },
      });
  }

  //#region utility functions
  setHidePopup(modified: boolean = false) {
    this.showPopup = false;
    this._isEditMode = false;
    this.oOpportunity = null;
    this.frmgroupCreateOpportunity.reset();
    this.hidePopup.emit({ showPopup: this.showPopup, modified });
    this.titleValueChanged = false;
  }

  setOpportunityData(opportunity: OpportunityResponse) {
    var d = new Date(opportunity.expected_closure_date);
    this.SelectedTitle.setValue(opportunity.name);
    this.ExpectedClosureDate.patchValue(d);
    this.ExpectedAmount.setValue(
      this.commonService.ConvertAmountFromMinorCurrency(
        opportunity.value_in_minor_currency
      )
    );
    this.Comments.setValue(opportunity.comments);
    if (opportunity.hot_warm_cold) {
      var data = this.convertibility.find(
        (x) => x.name == opportunity.hot_warm_cold.toString()
      );
      this.SelectedConvertibility.setValue(data);
    }
  }

  onChangeServiceTypeCollection(event: any) {
    let data = Array.prototype.map
      .call(event.value, function (item) {
        return item.name;
      })
      .join(', ')
      .replace(/, ([^,]*)$/, ' and $1');
    // console.log(data);
    if (!this.titleValueChanged) this.interestedInSelectedTitle = data;
    this.frmgroupCreateOpportunity.controls.title.setValue(data);
  }

  public selectedStaff(e: STAFFINTERFACE[]) {
    this.frmgroupCreateOpportunity.controls.ddlSelectedAssigned.setValue(e);
  }

  //#region "Omni Search"
  setClient(e: any) {
    //DND
    // // console.log(e);
    // this.clients = [];
    // this.clients.push(e);

    console.log(e);
    this.clients = null;
    this.clients = e;
  }
  //#endregion

  timeout(ms: any) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  //#endregion
}

function FactoryHttpLoader(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, 'assets/dist/i18n/', '.json');
}



@NgModule({
  declarations: [CreateOpportunityComponent],
  imports: [
    CommonModule,
    StyleClassModule,
    BrowserAnimationsModule,
    DialogModule,
    FormsModule,
    ChipsModule,
    CalendarModule,
    ReactiveFormsModule,
    DropdownModule,
    MultiSelectModule,
    SelectButtonModule,
    InputTextareaModule,
    InputNumberModule,
    InputMaskModule,
    InputSwitchModule,
    ButtonModule,
    InputTextModule,
    ErrorMsgComponentModule,
    AssignedToComponentModule,
    ErrorsComponentModule,
    MiniSearchComponentModule,
    ToastModule,
    MessagesModule,
    DividerModule,
    TextTransFormPipeModule,
    AvatarModule,
    ShortNamePipeModule,
    ChipModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: FactoryHttpLoader,
        deps: [HttpClient],
      },
    }),
  ],
  exports: [CreateOpportunityComponent],
})
export class CreateOpportunityComponentModule { }

interface Option {
  name: string;
  value: number;
}

interface Convertibility {
  name: string;
  value: string;
  icon: string;
}

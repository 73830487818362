import { CommonModule } from '@angular/common';
import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  NgModule,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { FactoryHttpLoader } from '@fitness-force/shared';
import { MenuItem } from 'primeng/api';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { BehaviorSubject, filter, Subject, takeUntil } from 'rxjs';
import {
  TranslateModule,
  TranslateLoader,
  TranslateService,
} from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { COMPANY_INFO } from 'apps/ff-frontend/src/app/state/app.state';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BreadcrumbComponent implements OnInit {
  static readonly ROUTE_DATA_BREADCRUMB = 'breadcrumb';
  menuItems = new BehaviorSubject<MenuItem[]>([]);

  // debug code
  counter=0;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public translate: TranslateService,
    private store: Store
  ) {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(async () =>
        this.menuItems.next(
          await this.createBreadcrumbs(this.activatedRoute.root)
        )
      );
  }

  ngOnInit(): void {}

  timeout(ms: any) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
  private async createBreadcrumbs(route: ActivatedRoute,url: string = '',breadcrumbs: MenuItem[] = []): Promise<MenuItem[]> {
    await this.timeout(100);
    const children: ActivatedRoute[] = route.children;

    if (children.length === 0) {
      return breadcrumbs;
    }

    for (const child of children) {
      const routeURL: string = child.snapshot.url
        .map((segment) => segment.path)
        .join('/');
      if (routeURL !== '') {
        url += `/${routeURL}`;
      }

      const label = child.snapshot.data[BreadcrumbComponent.ROUTE_DATA_BREADCRUMB];
      
      this.counter++;
      if (label) {
        this.translate.get(`BREADCRUM.LABELS.${label}`)
          .subscribe((translation: string) => {
            breadcrumbs.push({ label: translation, url });
          });
      }
      return this.createBreadcrumbs(child, url, breadcrumbs);
    }
    return breadcrumbs;
  }
}

@NgModule({
  declarations: [BreadcrumbComponent],
  imports: [CommonModule, BrowserModule, BreadcrumbModule],
  exports: [BreadcrumbComponent],
})
export class BreadcrumbComponentModule {}

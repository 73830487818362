import { ClientGender, ClientStatus, OpportunitiesType, OpportunityStageForNonMember, TaskAssignToType, TaskPriority, TaskType } from "@fitness-force/enum";

export interface CreateProspectRequest {
    Prospect: CreateClientRequest;
    Opportunity: CreateOpportunityOnClientRequest;
    Task: CreateTaskByOpportunityOnClientRequest;
}

export interface CreateClientRequest {
    full_name: string | null;
    mobile_country_code: string | null;
    mobile_number: number;
    email_id: string | null;
    date_of_birth: Date | null;
    gender: ClientGender | null;
    location: string | null;
    second_mobile_country_code: string | null;
    second_mobile_number: number | null;
    preferred_language: string | null;
    place_id: string | null;
    address: string | null;
    state_code: string | null;
    city_code: string | null;
    country_code: string | null;
    address_pin_code: number;
    emergency_contact_number: string | null;
    emergency_contact_person: string | null;
    source: string | null;
    channel: string | null;
    heartrate_monitoring_id: string | null;
    referred_by: number;
    promotional_sms: boolean;
    promotional_email: boolean;
    promotional_phone: boolean;
    medical_alert: string | null;
    note: string | null;
    taxnumber: string | null;
    custom_fields: string | null;
    current_keyfob: string | null;
    client_representative_id: number;
    createdin_tenant_id: number;
    place_id_request: string | null;
    migration_id: string | null;
    image_base64: string | null;
    password: string | null;
}

export interface CreateOpportunityOnClientRequest {
    name: string | null;
    stage_master_id: OpportunityStageForNonMember;
    service_type_ids: number[];
    value_in_minor_currency: number;
    expected_closure_date: Date;
    assigned_to_staff_id: number;
    comments: string | null;
    hot_warm_cold: OpportunitiesType;
}

export interface CreateTaskByOpportunityOnClientRequest {
    assigned_to_type: TaskAssignToType | string;
    assigned_to_staff_id: number | null;
    type: TaskType | string;
    category_id: number;
    title: string | null;
    notes: string | null;
    priority: TaskPriority | string;
    call_id: number | null;
    email_id: number | null;
    sms_id: number | null;
    scheduled_date: string | null;
}


export interface ListClientResponse {
    id: number;
    company_id: number;
    client_status: ClientStatus;
    is_mobile_verified: boolean;
    is_email_verified: boolean;
    image: string | null;
    home_club_tenant_id: number;
    is_waiver: boolean;
    is_blacklisted: boolean;
    online_booking_stopped_until: string;
    created_by: number;
    created_date: string;
    updated_by: number;
    updated_date: string;
    full_name: string;
    mobile_number: number;
    mobile_country_code: string;
    email_id: string;
    date_of_birth: Date | null;
    gender: ClientGender | null;
    location: string;
    client_representative_id: number;
    createdin_tenant_id: number;
    source: string;
    channel: string;
    total_outstanding_in_minor_currency: number;
    client_since: string;
    application_installed: boolean;
}
<p-dialog [(visible)]="blockClientModal" [resizable]="false" appendTo="body" [modal]="true" [closeOnEscape]="true"
  (onHide)="closeDialogEvent()" [breakpoints]="{'767px': '100vw'}" [style]="{width: '580px'}"
  styleClass="no-header-modal">

  <ng-template pTemplate="header" styleClass="hidden"></ng-template>
  <ng-template pTemplate="content" styleClass="p-0">
    <form [formGroup]="blockClientFromGroup" (ngSubmit)="blockUnBlockClient()">
      <!-- Content -->
      <div class="bg-white relative p-3 md:p-4">

        <div class="pi pi-times text-gray-500 absolute right-0 top-0 mt-3 mr-3 md:mt-4 md:mr-4 cursor-pointer"
          (click)="closeDialogEvent()"></div>

        <div class="text-center">
          <div class="relative inline-block mb-3">
            <i class="pi pi-ban absolute left-0 top-0 p-error text-6xl md:text-8xl"
              *ngIf="allClientData && !allClientData.is_blacklisted"></i>
            <i class="pi pi-check-circle absolute left-0 top-0 text-green-500 text-8xl"
              *ngIf="allClientData && allClientData.is_blacklisted"></i>
              
              <p-avatar *ngIf="!allClientData.image" [label]="allClientData.full_name | titlecase | shortName" shape="circle" styleClass="lg:w-6rem lg:h-6rem w-3rem h-3rem"></p-avatar>
              <p-avatar *ngIf="allClientData.image" [image]="allClientData.image" shape="circle" styleClass="lg:w-6rem lg:h-6rem w-3rem h-3rem"></p-avatar>
             
            </div>

          <h4 class="text-lg mb-1"><span
              *ngIf="allClientData &&  !allClientData.is_blacklisted">{{'BLOCK_CLIENT.BLOCK' | translate}}</span><span
              *ngIf="allClientData && allClientData.is_blacklisted">{{'BLOCK_CLIENT.UNBLOCK' | translate}}</span>
            {{allClientData.full_name}}{{'BLOCK_CLIENT.CLIENT_PROFILE' | translate}}</h4>
          <p class="text-gray-600 mb-0">{{'BLOCK_CLIENT.ARE_YOU_SURE_YOU_WANT_TO_BLOCK' | translate}} <span
              *ngIf="allClientData && !allClientData.is_blacklisted">{{'BLOCK_CLIENT.BLOCK' | translate}}</span> <span
              *ngIf="allClientData && allClientData.is_blacklisted">{{'BLOCK_CLIENT.UNBLOCK' | translate}}</span>
            {{'BLOCK_CLIENT.THIS_PROFILE' | translate}}</p>
        </div>

        <div class="border-bottom-1 border-gray-300 my-4"></div>

        <div class="mb-3">
          <h5 class="font-medium text-gray-700 text-xs mb-2 block uppercase"
            *ngIf="allClientData && allClientData.full_name">
            {{'BLOCK_CLIENT.PLEASE_CONFIRM' | translate}}{{allClientData.full_name}}
            {{'BLOCK_CLIENT.CAN_BE' | translate}} <span
              *ngIf="!allClientData.is_blacklisted">{{'BLOCK_CLIENT.BLOCKED' | translate}}</span> <span
              *ngIf="allClientData.is_blacklisted">{{'BLOCK_CLIENT.UNBLOCKED' | translate}}</span>{{'BLOCK_CLIENT.ON_THE_BELOW_TEXT_BOX' | translate}}
          </h5>
          <input type="text" pInputText placeholder="" class="p-inputtext-sm w-full"
            formControlName="confirmationText" />
          <app-error-msg *ngIf="ConfirmationText.touched && ConfirmationText.invalid" 
              [errors]="ConfirmationText.errors"
            [fieldLabel]="'BLOCK_CLIENT.CONFRIMATION_TEXT' | translate">
          </app-error-msg>
        </div>
        <ng-container *ngIf="allClientData && !allClientData.is_blacklisted">
          <h5 class="font-medium text-gray-700 text-xs mb-2 block uppercase">
            {{'BLOCK_CLIENT.REASON_FOR_BLOCKING' | translate}} {{allClientData.full_name}}</h5>
          <textarea pInputTextarea class="p-inputtext-sm w-full" formControlName="confirmationDescription"></textarea>
          <app-error-msg *ngIf="ConfirmationDescription.touched && ConfirmationDescription.invalid"
            [errors]="ConfirmationDescription.errors"
            [fieldLabel]="'BLOCK_CLIENT.CONFRIMATION_DESCRIPTION' | translate">
          </app-error-msg>
        </ng-container>

      </div>
      <!-- Content -->

      <!-- Content -->
      <div class="surface-100 p-4 md:flex justify-content-center">
        <ol class="list-decimal text-xs text-gray-600 md:pl-6 m-0">
          <li class="my-1">{{'BLOCK_CLIENT.CONTENT_LINE_1' | translate}}</li>
          <li class="my-1">{{'BLOCK_CLIENT.CONTENT_LINE_2' | translate}}</li>
          <li class="my-1">{{'BLOCK_CLIENT.CONTENT_LINE_3' | translate}}</li>
          <li class="my-1">{{'BLOCK_CLIENT.CONTENT_LINE_4' | translate}}</li>
          <li class="my-1">{{'BLOCK_CLIENT.CONTENT_LINE_5' | translate}}</li>
        </ol>
      </div>
      <!-- Content -->


      <!-- Modal Footer -->
      <div class="lg:px-4 px-2 py-3 border-top-1 border-gray-400 bg-white">
        <div class="flex align-items-center justify-content-between">
          <div class="w-4">
            <p-button type="button" [label]="'BLOCK_CLIENT.CANCEL' | translate" styleClass="p-button-link text-sm"
              (click)="closeDialogEvent()">
            </p-button>
          </div>
          <div class="w-8 text-right">
            <p-button type="submit" [label]="'BLOCK_CLIENT.CONTINUE' | translate"
              [styleClass]="allClientData && allClientData.is_blacklisted ? 'text-sm  p-button-success' : 'text-sm  p-button-danger'"
              [disabled]="!blockClientFromGroup.valid">
            </p-button>
          </div>
        </div>
      </div>
      <!-- Modal Footer -->
    </form>

  </ng-template>

</p-dialog>

<p-toast position="top-right"></p-toast>

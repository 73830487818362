export interface CreateAppointmentRequest {
    appointment_type_id: number;
    subtype_id: number;
    client_id: number;
    coach_id: number;
    start_date_time: Date | string;
    room_id: number;
    is_virtual: boolean;
    virtual_url: string;
    comments: string;
}

export interface CreateRecurringAppointmentRequest extends CreateAppointmentRequest {
    recurring_start_date?: Date | string;
    start_time?: string;
    recurring_rule?:string;
}

export interface CancelAppointmentRequest{
    cancellation_reason:string;
}

export interface UpdateAppointmentRequest {
    room_id: number;
    is_virtual: boolean;
    virtual_url: string;
    comments: string;
}
export interface SubstituteCoachRequest{
    coach_id:number;
}

export interface RescheduleAppointmentRequest{
    start_datetime:Date | string;
}

export interface RescheduleRecurringAppointmentRequest {
    recurring_start_date: Date | string;
    start_time: string;
    recurring_rule: string;
}
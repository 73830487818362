import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CONST_URL_SEGEMENTS } from '@fitness-force/constants';
import { OpportunityBoardType } from '@fitness-force/enum';
import { NotFoundError } from '@fitness-force/errors';
import { ListOpportunityStageMasterResponse } from '@fitness-force/models';
import { environment } from 'apps/ff-frontend/src/environments/environment';
import { AppErrors } from 'libs/errors/src/lib/app.errors';
import { catchError, map, Observable, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OpportunityStageService {

  constructor(private http: HttpClient, private router: Router) { }

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }

  //tenants/{tenant_id:int}/boards/{board_id:int}/opportunities
  OpportunityStageMasterListService(tenantId: number, boardType: OpportunityBoardType): Observable<ListOpportunityStageMasterResponse> {

    return this.http.get(`${environment.BASE_URL}/tenants/${tenantId}/boards/${boardType}${CONST_URL_SEGEMENTS.OPPORTUNITYSTAGEMASTER.OPPSTAGEMST}`, this.httpOptions)
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError((error: Response) => {
          if (error.status === 404) {
            return throwError(() => new NotFoundError(error));
          }
          return throwError(() => new AppErrors(error));
        })
      );
  }

  getOpportunityStageMaster(tenantId: number, boardType: OpportunityBoardType,opp_stage_mst_id:number): Observable<ListOpportunityStageMasterResponse> {
    return this.http.get(`${environment.BASE_URL}/tenants/${tenantId}/boards/${boardType}/opp_stage_mst/${opp_stage_mst_id}`, this.httpOptions)
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError((error: Response) => {
          if (error.status === 404) {
            return throwError(() => new NotFoundError(error));
          }
          return throwError(() => new AppErrors(error));
        })
      );
  }
}

<div class="required" class="p-error  text-xs mb-0">
    <div class="p-error mt-1 validationerror" *ngIf="errors && errors.required">{{'common.errors.validations.REQUIRED' | translate : {fieldlabel:fieldLabel} }}</div>
    <div class="p-error mt-1 validationerror" *ngIf="errors && errors.email">{{'common.errors.validations.EMAIL' | translate}}</div>
    <div class="p-error mt-1 validationerror" *ngIf="errors && errors.minlength">{{'common.errors.validations.MINLENGTH' | translate : {minlength:errors.minlength.requiredLength} }}</div>
    <div class="p-error mt-1 validationerror" *ngIf="errors && errors.maxlength">{{'common.errors.validations.MAXLENGTH' | translate : {maxlength:fieldLabel} }}</div>
    <div  class="p-error mt-1 validationerror" *ngIf="errors && errors.matching">{{'common.errors.validations.PASSWORD-NOT-MATCHED' | translate}}</div>
    <div  class="p-error mt-1 validationerror" *ngIf="errors && errors.wrongText">{{'common.errors.validations.CONFIRMATION-TEXT' | translate}}</div>
    <div  class="p-error mt-1  validationerror" *ngIf="errors && errors.minimumNumericalCheck">{{'common.errors.validations.PASSWORD_NUMBER' | translate}}</div>
    <div  class="p-error mt-1 validationerror" *ngIf="errors && errors.specialCharacterCheck">{{'common.errors.validations.PASSWORD_SPECIAL_CHAR' | translate}}</div>
    <div  class="p-error mt-1 validationerror" *ngIf="errors && errors.specialIpAddressCheck">{{'common.errors.validations.IP_ADDRESS' | translate}}</div>
    <div  class="p-error mt-1 validationerror" *ngIf="errors && errors.decimalNumberLimit">{{'common.errors.validations.VALID_DECIMAL' | translate}}</div>
    <div  class="p-error mt-1 validationerror" *ngIf="errors && errors.pattern">{{'common.errors.validations.PATTERN' | translate}}</div>
    <div  class="p-error mt-1 validationerror" *ngIf="errors && errors.lowercaseCharacterCheck">{{'common.errors.validations.PASSWORD_LOWERCASE_CHAR' | translate}}</div>
    <div  class="p-error mt-1 validationerror" *ngIf="errors && errors.uppercaseCharacterCheck">{{'common.errors.validations.PASSWORD_UPPERCASE_CHAR' | translate}}</div>
    <span  class="p-error validationerror" *ngIf="errors && errors.duplicate">{{'common.errors.validations.Duplicate' | translate : {fieldlabel:fieldLabel} }}</span>
    <span  class="p-error validationerror" *ngIf="errors && errors.fromToDate">{{'common.errors.validations.FromToDate' | translate : {fieldlabel:fieldLabel,secondFieldLabel:secondFieldLabel} }}</span>
</div>

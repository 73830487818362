import { IPSettings, Roles, StaffStatus } from "@fitness-force/enum";

export interface User {
  firstName?: string;
  lastName?: string;
  emailName?: string;
}

export interface GetStaffListResponse {
  id: number;
  company_id: number;
  password_last_changed: string;
  reports_to_name: string;
  created_by: number;
  created_date: string;
  updated_by: number;
  updated_date: string;
  is_system_staff: boolean;
  can_take_class: boolean;
  can_take_appointment: boolean;
  can_be_client_rep: boolean;
  name: string;
  claim_template_id: number;
  department: string;
  designation: string;
  role: Roles;
  is_public_bio_enabled: boolean;
  ip_setting: IPSettings;
  email_id: string;
  is_owner: boolean;
  status: StaffStatus;
  ff_picture:string;
}

export interface ClassBookingRequest {
  client_ids: number[];
  add_to_waitlist: boolean;
}

export interface RemoveClientFromClassRequest {
  client_ids: number[];
}

export interface MarkClientNoshowRequest {
  client_ids: number[];
}

export interface MarkClientCheckedinRequest {
  client_ids: number[];
}

import { InvoiceStatus, TrackMode } from "@fitness-force/enum";

    export interface Invoice {
        id: number;
        company_id: number;
        tenant_id: number;
        client_id: number;
        is_recurring: boolean;
        client_subscription_id: number;
        number: number;
        number_prefix: string;
        billing_name: string;
        sales_representative: number;
        total_base_amount_in_minor_currency: number;
        total_tax_amount_in_minor_currency: number;
        total_amount_in_minor_currency: number;
        total_payment_in_minor_currency: number;
        total_balance_due_in_minor_currency: number;
        issue_date: Date;
        track_mode: TrackMode;
        due_date: Date;
        created_by: number;
        created_date: Date;
        updated_by: number;
        updated_date: Date;
        status: InvoiceStatus;
        invoiceNumbers:string[]
    }


    export interface SalesReturnLineItem {
        invoice_line_id: number;
        description: string;
        net_unit_cost_in_minor_currency: number;
        quantity: number;
    }

    export interface RaiseSalesReturnRequest {
        sales_rep: number;
        issue_date: Date;
        migration_id: string;
        sales_return_line_items: SalesReturnLineItem[];
    }

    export interface voidInvoiceRequest{
        void_reason:string;
    }




import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  NgModule,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { DialogModule } from 'primeng/dialog';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';

import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { Store } from '@ngrx/store';
import { ValidationError } from '@fitness-force/models';
import { COMPANY_INFO } from 'apps/ff-frontend/src/app/state/app.state';
import { CommonModule } from '@angular/common';
import { ButtonModule } from 'primeng/button';
import { AppErrors } from '@fitness-force/errors';
import { TimeconversionService } from '@fitness-force/api';

@Component({
  selector: 'app-errors',
  templateUrl: './errors.component.html',
  styleUrls: ['./errors.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ErrorsComponent implements OnInit {
  @Input('visible') showErrorPopup: boolean = false;
  // @Input('errorData') validationError: ValidationError[] | any[];
  @Input('errorData') validationError: any[];
  @Input('error') error = new BehaviorSubject(null as (AppErrors | null));
  @Output() hideErrorPopup: EventEmitter<Observable<boolean>> =
    new EventEmitter();

  constructor(public store: Store, public translate: TranslateService, public timeService: TimeconversionService) { }

  ngOnInit(): void {
    this.componentInit();
    this.setValidationErrorObject();
  }

  setValidationErrorObject() {
    if (this.error && this.error.value && this.error.value?.originalError) {
      console.log(this.error.value?.originalError.status);
      if (this.error.value?.originalError.status) {
        if (Array.isArray(this.error.value?.originalError.error)) {
          for (let i = 0; i < this.error.value?.originalError.error.length; i++)
          {
            if(this.error.value?.originalError.error[i].errorCode.toLowerCase() =='overlap')
            {
             this.showOverlapErrorMessage(this.error.value?.originalError.error[i]);
            }
            else{
              this.validationError.push(this.error.value?.originalError.error[i].errorMessage);
            }
          }
        }
        else
          this.validationError.push(this.error.value?.originalError.error.errorMessage);
      }
      else if (this.error.value?.originalError.status == '400') {
        this.validationError.push('Error Occured');
      }
      else if (this.error.value?.originalError.status == '500') {
        this.validationError.push(this.error.value?.originalError.error);
      }
      else {
        this.validationError.push('Unknown Error');
      }
      this.showErrorPopup = true;
    }
  }

  showOverlapErrorMessage(error:any)
  {
    this.translate.get('SCHEDULE.MSG.OVERLAP_MESSAGE',
    { type:error.jsonObject.identifier,
     startDate: this.timeService.formatDate(this.timeService.ConvertUTCToLocalDateTime(error.jsonObject.startDateTime), 'medium'),
     endDate: this.timeService.formatDate(this.timeService.ConvertUTCToLocalDateTime(error.jsonObject.endDateTime),'medium')}).subscribe((translation) => {
      this.validationError.push(translation);
    });
  }

  setHidePopup() {
    this.showErrorPopup = false;
    this.validationError = [];
    this.hideErrorPopup.emit(of(this.showErrorPopup));
  }

  componentInit() {
    this.store.select(COMPANY_INFO).subscribe((responseData) => {
      this.translate.setDefaultLang(responseData.selectedLang.LANG_CODE);
      this.translate.use(responseData.selectedLang.LANG_CODE);
    });
  }

  SetErrorDetails(errosList: any) { }
}

function FactoryHttpLoader(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, 'assets/dist/i18n/', '.json');
}

@NgModule({
  declarations: [ErrorsComponent],
  imports: [
    DialogModule,
    BrowserAnimationsModule,
    CommonModule,
    BrowserModule,
    ButtonModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: FactoryHttpLoader,
        deps: [HttpClient],
      },
    }),
  ],
  exports: [ErrorsComponent],
})
export class ErrorsComponentModule { }

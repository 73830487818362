
    export interface Class {
        id: number;
        type_id: number;
        company_id: number;
        tenant_id: number;
        type_name_primary_language: string;
        type_name_secondary_language: string;
        start_datetime: string| Date;
        end_datetime: string| Date;
        tenant_name_primary_language: string;
        tenant_name_secondary_language: string;
        recurring_id: number;
        type_color: string;
        subtype_name_primary_language: string;
        subtype_name_secondary_language: string;
        sub_type_id: number;
        max_capacity: number;
        max_waitlist:number;
        behaviour: string;
        is_dropin_allowed: boolean;
        is_virtual: boolean;
        booking_opens_in_minutes: number;
        tag_name_primary_language: string[];
        tag_name_secondary_language: string[];
        coach_id: number;
        coach_name: string;
        coach_image_url: string;
        total_booking: number;
        total_waitlist_booking:number;
        place_id: string;
        latitude: number;
        longitude: number;
        class_duration:number;
        room_id:number;
        class_clients: ClassClient[];
        status: string;
        created_by: number;
        created_date: Date;
        updated_by: number;
        updated_date: Date;
        guid:string;
        type:string;
    }

    export interface ClassStatistic {
        id: number;
        type_id: number;
        company_id: number;
        tenant_id: number;
        type_name_primary_language: string;
        type_name_secondary_language: string;
        start_datetime: Date;
        end_datetime: Date;
        tenant_name_primary_language: string;
        tenant_name_secondary_language: string;
        recurring_id: number;
        type_color: string;
        subtype_name_primary_language: string;
        subtype_name_secondary_language: string;
        sub_type_id: number;
        max_capacity: number;
        behaviour: string;
        is_dropin_allowed: boolean;
        is_virtual: boolean;
        booking_opens_in_minutes: number;
        tag_name_primary_language: string[];
        tag_name_secondary_language: string[];
        coach_id: number;
        coach_name: string;
        coach_image_url: string;
        total_booking: number;
        place_id: string;
        latitude: number;
        longitude: number;
        created_by: number;
        created_date: Date;
        updated_by: number;
        updated_date: Date;
    }

    export interface ClassListResponse {
        items: Class[];
    }

    export interface ListClassRequest {
        tenant_id:number;
        start_date: string;
        end_date: string;
        getClientDetails:boolean
    }

    export interface ClassClient {
        class_booking_Id: number;
        class_booking_client_Id: number;
        full_name: string;
        mobile_country_code: string;
        mobile_no: number;
        emailid: string;
        image: string;
        class_booking_status: string;
        class_booking_is_waitlist: boolean;
        class_booking_is_payment_required: boolean;
        class_booking_is_first_time_class: boolean;
        class_booking_is_comp_session: boolean;
    }

    export interface ClassById extends Class {
        cancellation_reason: string;
        room_name_primary_language: string;
        room_name_secondary_language: string;
        subtype_description_primary_language: string;
        subtype_description_secondary_language: string;
        subtype_things_to_carry_primary_language: string;
        subtype_things_to_carry_secondary_language: string;
        is_recuring: boolean;
        recurring_rule: string;
        commission_mode: string;
        commission_flatfee_amount_in_minor_currency: number;
        commission_revenue_share_percentage: number;
        is_commission_processed: boolean;
        min_booking_cancel_time: number;
        virtual_url: string;
        coach_description: string;
        total_revenue: number;
        comments: string;
        track_mode: string;
       
    }

    export interface ListClassRecurringTemplate {
        items: Classes[];
    }
    export interface Classes {
        recurring_template_id: number;
        company_id: number;
        tenant_id: number;
        coach_id: number;
        class_type: ClassTypeObject;
        room: RoomObject;
        class_subtype: ClassSubtype;
        status: string;
        recurring_start_date: Date;
        recurring_end_date: Date;
        start_time: string;
        end_time: string;
        is_virtual: boolean;
        virtual_url: string;
        recurring_rule: string;
        tag_name_primary_language: string[];
        tag_name_secondary_language: string[];
        track_mode: string;
        created_by: number;
        created_date: Date;
        updated_by: number;
        update_date: Date;
        place_id: string;
        latitude: number;
        longitude: number;
        comments: string;
    }

    export interface ClassTypeObject {
        type_id: number;
        type_name_primary_language: string;
        type_name_secondary_language: string;
        behaviour: string;
        type_color: string;
    }

    export interface RoomObject {
        room_id: number;
        room_name_primary_language: string;
        room_name_secondary_language: string;
    }

    export interface ClassSubtype {
        subtype_id: number;
        subtype_name_primary_language: string;
        subtype_name_secondary_language: string;
    }

    export interface ListRecurringClassRequest {
        tenant_id:number;
        class_recurring_template_id : number;
        page_number: number;
        page_size: number;
    }

  




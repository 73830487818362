export interface Room {
    id: number;
    company_id: number;
    name_primary_language: string;
    name_secondary_language: string;
    tenant_id: number;
    status: string;
    created_by: number;
    created_date: Date;
    updated_by: number;
    updated_date: Date;
}

export interface ListRoomResponse {
    items: Room[];
}

export interface RoomStore {
    rooms: Room[];
}
export interface SubscriptionPlan {
  name_primary_language: string,
  name_secondary_language: string,
  description_primary_language: string,
  description_secondary_language: string,
  subscription_Category: string,
  billing_interval_type: string,
  billing_interval_value: number,
  bill_cycle_day: string,
  available_on_staff_facing_apps: boolean,
  available_on_member_facing_apps: boolean,
  available_on_website_widgits: boolean,
  contract_term: number,
  minimum_contract_commitment: number,
  number_of_occurance_as_deposit: number,
  auto_apply_deposit_end_minimum_commitment: boolean,
  feature_is_cash_recurring: boolean,
  feature_is_signup_fee_required: boolean,
  feature_is_early_cancellation_allowed: boolean,
  early_cancellation_charge_master_id: number,
  feature_is_online_termination_allowed: boolean,
  feature_is_freeze_allowed: boolean,
  freeze_type: string,
  freeze_allowed_number_of_attempts_in_year: number,
  freeze_can_only_billing_cycle: boolean,
  freeze_fee_per_instance_charge_master_id: number,
  freeze_max_days_per_instance: number,
  commission_value: number,
  commission_calculation_type: string,
  transferable: boolean,
  minimum_days_required_for_transfer: number,
  recurring_services: Array<SubscriptionPlanService>,
  non_recurring_services: Array<SubscriptionPlanService>,
  signup_charge_master_ids: Array<number>
}

export interface SubscriptionPlanService{
  base_cost_in_minor_currency: number,
  sessions: number,
  category_id: number,
  behaviour: string,
  tax_group_id: number,
  tax_item_code: string,
  require_base_membership: boolean,
  is_session_owner_required: boolean,
  is_base_membership: boolean,
  non_recurring_services?:boolean
}

import { Status, PlanIntervalType, PlanFreezeType, PlanCategory, PlanType } from "@fitness-force/enum";
import { PlanTaxGroup } from "@fitness-force/models";
import { PlanSummaryScheduling } from "../admin/appointment-types";
import { PlanSummaryClient, PlanSummaryCRM } from "../crm/billingcrm";

export interface GetPlanMasterListResponse {
    items: PlanMasterList[];
}

export interface GetPlanMasterByIdResponse {
    id: number;
    company_id: number;
    tenant_id: number;
    status: Status;
    name_primary_language: string;
    name_secondary_language: string;
    description_primary_language: string;
    description_secondary_language: string;
    subscription_category: PlanType;
    billing_interval_type: PlanIntervalType;
    billing_interval_value: number;
    bill_cycle_day: string;
    available_on_staff_facing_apps: boolean;
    available_on_member_facing_apps: boolean;
    available_on_website_widgits: boolean;
    contract_term: number;
    minimum_contract_commitment: number;
    number_of_occurance_as_deposit: number;
    auto_apply_deposit_end_min_commitment: boolean;
    feature_is_signup_fee_required: boolean;
    feature_is_cash_recurring: boolean;
    feature_is_early_cancellation_allowed: boolean;
    early_cancellation_chrgmst_id: PlanChargeMasterDetails;
    feature_is_online_termination_allowed: boolean;
    feature_is_freeze_allowed: boolean;
    freeze_type: PlanFreezeType;
    freeze_allowed_number_of_attempts_in_year: number;
    freeze_can_only_billing_cycle: boolean;
    freeze_fee_per_instance_chrgmst_id: PlanChargeMasterDetails;
    freeze_max_days_per_instance: number;
    commission_value: number;
    commission_calculation_type: number;
    transferable: boolean;
    min_days_req_for_transfer: number;
    recurring_services: PlanMasterServices[];
    non_recurring_services: PlanMasterServices[];
    signup_chrgmst_ids: PlanChargeMasterDetails[];
    created_by: number;
    created_date: string;
    updated_by: number;
    updated_date: string;
}

export interface PlanMasterList {
    id: number;
    company_id: number;
    tenant_id: number;
    status: Status;
    name_primary_language: string;
    name_secondary_language: string;
    description_primary_language: string
    description_secondary_language: string
    subscription_category: PlanCategory;
    billing_interval_type: PlanIntervalType;
    billing_interval_value: number;
    bill_cycle_day: string;
    contract_term: number;
    minimum_contract_commitment: number;
    feature_is_signup_fee_required: boolean;
    signup_chrgmst_ids: PlanChargeMasterList[];
    feature_is_cash_recurring: boolean;
    feature_is_freeze_allowed: boolean;
    freeze_type: PlanFreezeType;
    recurring_services_in_minor_currency: number;
    non_recurring_services_in_minor_currency: number;
    recurring_service_catagories: number[];
    non_recurring_service_catagories: number[];
    created_by: number;
    created_date: string;
    updated_by: number;
    updated_date: string;
}

export interface PlanMasterServices {
    id: number;
    type_id: number;
    base_cost_in_minor_curr: number;
    sessions: number;
    name_primary_language: string;
    name_secondary_language: string;
    description_primary_language: string;
    description_secondary_language: string;
    behaviour: string;
    taxgrp_id: number;
    tax_item_code: string;
    is_base_membership: boolean;
    require_base_membership: boolean;
    is_session_owner_required: boolean;
    icon: string;
    migration_id: string;
    taxes: PlanTaxGroup;
}

export interface PlanChargeMasterDetails {
    id: number;
    chrg_category_id: number;
    amount_in_minor_currency: number;
    icon: string;
    name_primary_language: string;
    name_secondary_language: string;
    comment: string;
    status: string;
    tax_grp_id: number;
    tax_item_code: string;
    migration_id: string;
    taxes: PlanTaxGroup;
}

export interface PlanChargeMasterList {
    id: number;
    chrg_category_id: number;
    amount_in_minor_currency: number;
    icon: string;
    category_primary_language: string;
    category_secondary_language: string;
    name_primary_language: string;
    name_secondary_language: string;
}

export interface PlanSummary {
    id: number;
    company_id: number;
    tenant_id: number;
    client: PlanSummaryClient;
    startdate: Date;
    couponcode: string | null;
    plan_selectable: boolean;
    CRM: PlanSummaryCRM;
    Scheduling: PlanSummaryScheduling | null;
}
import { Status } from "@fitness-force/enum";

export interface PaymentMethod {
    id: number;
    level1: string;
    network_type: string;
    expiry_date: Date;
    bank_name: string;
    cardholder_name: string;
    masked_account_number: string;
    upi_provider: string;
    vpa_id: string;
    masked_card_number: string;
    status: Status;
}
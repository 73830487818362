import { ClassBehaviour } from "@fitness-force/enum";

export interface ClassTypes {
  id: number;
  name_primary_language: string;
  name_secondary_language: string;
  behaviour: ClassBehaviour;
  color: string;
  status: boolean;
  maximum_capacity: number,
  maximum_waitlist: number,
  accessible_on_staff_facing_apps: boolean;
  accessible_on_member_facing_apps: boolean;
  accessible_on_website_widgits: boolean;
  logo: string;
  is_small_group_training: boolean;
  duration_in_minutes: number;
  is_room_mandatory: boolean;
  tags: Array<ClassTags>;
  created_by: number;
  updated_by: number;
  created_date: Date;
  updated_date: Date;
}

export interface ClassTags {
  id: number;
  company_id: number;
  tenant_id: number;
  name_primary_language: string;
  name_secondary_language: string;
  created_by: number;
  created_date: Date;
  updated_by: number;
  updated_date: Date;
  class_types: Array<ClassTypes>;
  appointment_types: Array<ClassTypes>;
}



export interface CreateClassTypesRequest {
  name_primary_language: string;
  name_secondary_language: string;
  service_type_id: number;
  behaviour: ClassBehaviour;   // TODO: cross check reuse
  color: string;
  accessible_on_staff_facing_apps: boolean;
  accessible_on_member_facing_apps: boolean;
  accessible_on_website_widgits: boolean;
  commission_mode: string;
  minimum_booking_cancel_time: number;
  mark_trainer_busy: boolean;
  is_room_mandatory: boolean;
  booking_opens_in_minutes: number;
  is_online_cancel_booking_allowed: boolean;
  duration_in_minutes: number;
  tags: Array<number>;
  logo_base64: string;
}

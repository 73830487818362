import { AuthSevices } from 'libs/api/src/lib/auth.service';
import { FormControl, ReactiveFormsModule, FormsModule } from '@angular/forms';
import { TENANTS_INFO, STAFF_STATE } from 'apps/ff-frontend/src/app/state/app.state';
import { select, Store } from '@ngrx/store';
import { Observable, of, BehaviorSubject, map } from 'rxjs';
import { CommonModule } from '@angular/common';
import { Component, OnInit, ChangeDetectionStrategy, NgModule, Input, Output, EventEmitter, LOCALE_ID, Inject } from '@angular/core';
import { Client, OmniSearchResponse, STAFFINTERFACE, TENANTSINTERFACE, TENANT_MODEL } from '@fitness-force/models';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { StyleClassModule } from 'primeng/styleclass';
import { DropdownModule } from 'primeng/dropdown';
import * as _ from 'lodash';
import { ButtonModule } from 'primeng/button';
import { CreateProspectComponentModule } from 'libs/shared/src/lib/components/omnisearch/create-prospect/create-prospect.component';
import { SearchPipeModule } from 'libs/shared/src/lib/filters/searchtenant.pipe';

import { DividerModule } from 'primeng/divider';
import { AvatarModule } from 'primeng/avatar';
import { AutoCompleteModule } from 'primeng/autocomplete';

import { Router, RouterModule } from '@angular/router';
import { FETCHED_TENANT_DETAILS } from 'apps/ff-frontend/src/app/state/actions';
import { ClientService, OmniSearchService } from '@fitness-force/api';
import { ClientStatus, ElasticProviderName } from '@fitness-force/enum';
import { ShortNamePipeModule } from '../../../../shared/src/lib/filters/name-initials.pipe';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { FactoryHttpLoader } from '@fitness-force/shared';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderComponent implements OnInit {
  @Input('tenantList') tenantList: TENANTSINTERFACE | any;
  @Output('emittedTenant') emittedTenant = new EventEmitter<TENANT_MODEL>();
  zoneWiseTenantList: any = [];
  selectedTenant?: TENANT_MODEL;
  searchTenants: FormControl = new FormControl('')
  allTenantsControls: FormControl = new FormControl('')
  tenantOriginalValue: TENANTSINTERFACE
  showCreateProspectPopup: Observable<boolean> = of(false);
  userDetails$ = new BehaviorSubject<any>([])
  allStaffs$ = new BehaviorSubject([])
  getAccessTenantList$: Observable<any> | any;
  selectedClient: any;
  filteredClient$: BehaviorSubject<Client[]> = new BehaviorSubject([] as Client[]);
  pageNumber: number = 0;
  currentUserDetails: any;


  constructor(
    private store: Store,
    private router: Router,
    private clientService: ClientService,
    private authService: AuthSevices,
    private omniSearchService: OmniSearchService,
    @Inject(LOCALE_ID) public locale: string,
  ) {

  }
  get SearchTenants() {
    return this.searchTenants.value
  }
  ngOnInit(): void {
    this.componentInit()
  }

  sidebarMinimized: boolean = false;

  componentInit() {
    this.getAccessTenantList$ = this.store.select(TENANTS_INFO);
    this.getAccessTenantList$.subscribe((tenant: TENANTSINTERFACE) => {

      if (tenant.selected_tenant.id > 0) {
        console.log();
        // TODO : This should go inside effects. .. refactoring needed
        localStorage.setItem(
          'TENANT_DEFAULT',
          tenant.selected_tenant.id.toString()
        );
        this.store.dispatch({ type: 'FETCH_STAFF_LIST' });
        this.store.dispatch({ type: 'FETCH_STAGE_MASTER' });
        this.store.dispatch({ type: 'FETCH_SERVICETYPE_LIST' });
        this.store.dispatch({ type: 'FETCH_CLASS_TYPE_LIST' });
        this.store.dispatch({ type: 'FETCH_CLASS_TYPE_LIST' });
        this.store.dispatch({ type: 'FETCH_COUNTRY_MASTER_LIST' });
        this.store.dispatch({ type: 'FETCH_STATE_MASTER_LIST' });
        this.store.dispatch({ type: 'FETCH_CITY_MASTER_LIST' });


        if (!this.selectedTenant)// IMPORTANT TO HIDE DROP DOWN..
          this.getGroupData(tenant.accessed_tenant);
        this.tenantOriginalValue = tenant;
        this.selectedTenant = tenant.selected_tenant;
      }
    });


    this.currentUserDetails = this.authService.currentUserDetails();

    if (this.currentUserDetails) {
      this.store.select(STAFF_STATE).subscribe((data: any) => {
        if (data && data.staffList && data.staffList.length > 0)
          var filterDate = data.staffList.filter((o: any) => { return o.id === parseInt(this.currentUserDetails.Id) });
        if (filterDate) {
          this.userDetails$.next(filterDate[0]);
        }
      });
    }

  }

  selectTenant(selected_tenant: TENANT_MODEL) {
    if (this.selectedTenant && selected_tenant.id == this.selectedTenant?.id)
      return;
    this.store.dispatch(FETCHED_TENANT_DETAILS({ selected_tenant }));

    this.emittedTenant.emit(selected_tenant);
  }

  get AllTenantsControls(): FormControl {
    return this.allTenantsControls as FormControl
  }

  getGroupData(tenantList: any): void {
    /* Filter tenant list zone wise */
    this.zoneWiseTenantList = _(tenantList)
      .groupBy('zone') // group the items
      .map((group, Zone) => ({ // map the groups to new objects
        Zone,
        Tenants: group
      }))
      .value();
  }

  hidePopup(isHide: boolean) {
    console.log(isHide);
    this.showCreateProspectPopup = of(isHide);
  }

  OpenPopup() {
    this.showCreateProspectPopup = of(true);
  }

  searchClient(event: any, pageNumber: number) {
    this.pageNumber = (pageNumber == 0) ? 0 : this.pageNumber;
    let request = {
      language: this.locale,
      query: event.query,
      providers: [{
        provider_name: ElasticProviderName.Clients,
        pagination: { page_number: 0, page_size: 10000 }
      }]
    };

    this.omniSearchService.Search(request).subscribe({
      next: (response: OmniSearchResponse) => {
        let client = response.searchResults.clients.items;
        this.filteredClient$.next(client);
      },
      error(msg) {
        console.log('Error Getting clients: ', msg);
      }
    });
  }

  navigateToClientProfile(client: Client) {
    this.selectedClient = null;
    this.router.navigate(['/client/profile/', client.clt_id]);
  }

  client2LetterName(name: string): string {
    if (name) {
      let nameArray: string[] = name.split(" ");
      let newNameArray: string[] = [];
      for (let i = 0; i < nameArray.length; i++) {
        if (newNameArray.length >= 2)
          break;

        if (nameArray[i]) {
          newNameArray.push(nameArray[i]);
        }
      }
      return newNameArray.join(" ");
    }
    else {
      return "";
    }
  }

  clientName(client: Client | null): string {
    if (client && client.clt_full_name)
      return client.clt_full_name.replace(this.selectedClient, '<strong class="text-primary">' + this.selectedClient + '</strong>');
    else
      return '';
  }

  clientStatus(client: Client | null): string {
    if (client && client.clt_client_status)
      return this.clientService.getClientStatusHtml(client.clt_client_status.toString());
    else
      return '';
  }
}

@NgModule({
  declarations: [HeaderComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    BrowserAnimationsModule,
    CreateProspectComponentModule,
    StyleClassModule,
    DropdownModule,
    ButtonModule,
    AutoCompleteModule,

    SearchPipeModule,
    ShortNamePipeModule,
    DividerModule,
    AvatarModule,
    RouterModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: FactoryHttpLoader,
        deps: [HttpClient],
      },
    }),
  ],
  exports: [HeaderComponent]
})
export class HeaderComponentModule {
}

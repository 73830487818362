import { Status, WaiverSusbscriptionTrigger } from "@fitness-force/enum";

export interface WaiverMasterResponse {
    id: number;
    company_id: number;
    tenant_id: number;
    title_primary_language: string;
    title_secondary_language: string;
    content_primary_language_md: string;
    content_secondary_language_md: string;
    susbscription_trigger: WaiverSusbscriptionTrigger;
    for_service_catagory_id: number[];
    at_trial: boolean;
    status: Status | null;
    created_by: number;
    created_date: Date;
    updated_by: number;
    updated_date: Date;
}

export interface WaiverMasterResponseList {
    items: WaiverMasterResponse[];
}
<!-- {{isReadonly}} | {{clientId}} | {{(clientIds$ | async)}} -->
<ng-container *ngIf="isReadonly">
    <ng-container *ngIf="clientId && (selectedClient$ | async) as selectedClient">
        <div
            class="h-3rem md:h-4rem md:px-4 px-3 flex align-items-center justify-content-between bg-blue-50 border-bottom-1 border-gray-300">
            <!-- Left Side -->
            <div class="flex align-items-center">
                <div [innerHTML]="clientStatus(selectedClient)"></div>
                <span class="w-2rem h-2rem mr-2 ml-2">
                    <ng-container *ngIf="selectedClient.clt_image">
                        <p-avatar image="{{selectedClient.clt_image}}" shape="circle"></p-avatar>
                    </ng-container>
                    <ng-container *ngIf="!selectedClient.clt_image">
                        <p-avatar *ngIf="!selectedClient.clt_image"
                            [label]="client2LetterName(selectedClient.clt_full_name) | titlecase | shortName"
                            shape="circle">
                        </p-avatar>
                    </ng-container>
                </span>
                <h3 class="mb-0 text-primary text-base">
                    {{selectedClient.clt_full_name}}
                </h3>
                <p class="mb-0 text-xs text-600 ml-2 hidden md:block">- {{selectedClient.clt_id}}</p>
            </div>
            <!-- Left Side -->

            <!-- Right Side -->
            <div class=" hidden md:flex align-items-center text-gray-600 text-sm ml-3">
                <i class="pi pi-mobile text-gray-500 mr-2"></i>
                <span>Mobile #: <strong>{{selectedClient.clt_mobile_country_code}}
                        {{selectedClient.clt_mobile_no}}</strong></span>
            </div>
            <!-- Right Side -->
        </div>
    </ng-container>
    <ng-container *ngIf="(clientIds$ | async) as ids">
        <div *ngIf="ids && ids.length > 0"
            class="h-auto md:h-3rem md:px-4 px-2 md:py-0 py-3 flex align-items-center relative border-bottom-1 border-gray-300">
            <div class="w-full md:flex align-items-center justify-content-between">
                <div class="mt-1">
                    <ng-container *ngFor="let member of (selectedClients$ | async); index as i;">
                        <p-chip styleClass="mr-2 bg-primary" [label]="client2LetterName(member.full_name)"
                            image="{{member.image}}" [removable]="false" [attr.data-index]="i" *ngIf="i<3">
                        </p-chip>
                    </ng-container>
                </div>
                <div *ngIf="ids.length>3"
                    class="mt-2 md:mt-0 text-primary font-bold text-sm cursor-pointer md:text-right text-left flex align-items-center"
                    (click)="showAllMembers = true">{{ 'Shared.SEARCH.MINI-SEARCH.N-MORE' | translate:paramNMore }}
                    <i class="pi pi-chevron-down ml-2"></i>
                </div>
            </div>
        </div>
    </ng-container>
</ng-container>

<ng-container *ngIf="!isReadonly">
    <div class="h-3rem md:h-4rem flex align-items-center ng-star-inserted">
        <div class="relative z-2 w-full">
            <ng-container *ngIf="!clientId">
                <i class="pi pi-search absolute mt-2 ml-3 text-400 z-1"></i>
                <p-autoComplete [(ngModel)]="selectedClient" [showEmptyMessage]="true" [suggestions]="members$ | async"
                    styleClass="w-full h-full pl-2 pr-2 border-round" [inputStyleClass]="'outline-none border-round text-sm text-gray-600 pr-3 pl-5'"
                    [panelStyleClass]="'omnisearch-dropdown absolute left-0 origin-top w-full bg-white border-1 border-gray-300 shadow-2 z-5 justify-content-between'"
                    (completeMethod)="searchClients($event)" field="clt_full_name" showClear="true"
                    [placeholder]="'Shared.SEARCH.MINI-SEARCH.PLACEHOLDER-SEARCH-CLIENT' | translate"
                    (onSelect)="setClient($event)">
                    <ng-template let-client pTemplate="item">
                        <div class="flex align-items-center justify-content-between">
                            <div class="flex align-items-center">
                                <ng-container *ngIf="client.clt_image">
                                    <p-avatar image="{{client.clt_image}}" shape="circle"></p-avatar>
                                </ng-container>
                                <ng-container *ngIf="!client.clt_image">
                                    <p-avatar *ngIf="!client.clt_image"
                                        [label]="client2LetterName(client.clt_full_name) | titlecase | shortName"
                                        shape="circle">
                                    </p-avatar>
                                    <!-- <p-avatar image="assets/img/avatar.jpg" shape="circle"></p-avatar> -->
                                </ng-container>
                                <span class="mx-2"
                                    innerHTML="<strong class='text-primary'>{{client.clt_full_name | highlight: selectedClient}}</strong>">
                                </span>
                                <span class="text-600 font-normal text-xs">
                                    <span class="hidden md:inline">CLIENT ID </span>
                                    {{client.clt_id}}
                                </span>
                            </div>
                            <div [innerHTML]="clientStatus(client)"> </div>
                        </div>
                    </ng-template>
                </p-autoComplete>
            </ng-container>
            <ng-container *ngIf="clientId && (selectedClient$ | async) as selectedClient">
                <div class="bg-white text-sm border-1 border-gray-300 border-round p-2 flex align-items-center justify-content-between mx-1"
                    pStyleClass=".search-dropdown" enterClass="hidden" enterActiveClass="scalein" leaveToClass="hidden"
                    leaveActiveClass="fadeout" [hideOnOutsideClick]="true">
                    <div class="flex align-items-center -my-1">
                        <ng-container *ngIf="selectedClient.clt_image">
                            <p-avatar image="{{selectedClient.clt_image}}" shape="circle"></p-avatar>
                        </ng-container>
                        <ng-container *ngIf="!selectedClient.clt_image">
                            <p-avatar *ngIf="!selectedClient.clt_image"
                                [label]="client2LetterName(selectedClient.clt_full_name) | titlecase | shortName"
                                shape="circle">
                            </p-avatar>
                        </ng-container>
                        <span class="mx-2"
                            innerHTML="<strong class='text-primary'>{{selectedClient.clt_full_name}}</strong>">
                        </span>
                        <span class="text-600 font-normal text-xs">
                            <span class="hidden md:inline">CLIENT ID</span>
                            {{selectedClient.clt_id}}
                        </span>
                    </div>
                    <div class="flex align-items-center">
                        <div [innerHTML]="clientStatus(selectedClient)"></div>
                        <span class='pi pi-times text-gray-500 ml-2 cursor-pointer' (click)='clearClient()'></span>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</ng-container>


<p-dialog [(visible)]="showAllMembers" [resizable]="false" appendTo="body" [modal]="true"
    [breakpoints]="{'1025px': '100vw', '640px': '100vw'}" [style]="{width: '25vw'}"
    styleClass="no-header-modal no-overflow-content">
    <ng-template pTemplate="header" styleClass="hidden"></ng-template>
    <ng-template pTemplate="content" styleClass="p-0">
        <!-- Header -->
        <div
            class="p-3 md:py-3 md:px-4 flex align-items-center justify-content-between relative border-bottom-1 border-gray-300">
            <div class="flex align-items-center mr-3">
                <i class="pi pi-plus text-gray-500 mr-3"></i>
                <h2 class="mb-0 font-normal text-lg"
                    [innerHTML]="'Shared.SEARCH.MINI-SEARCH.LIST-OF-N-Member' | translate:paramNList">
                </h2>
            </div>
            <div class="pi pi-times text-gray-500 cursor-pointer" (click)="showAllMembers = false"></div>
        </div>
        <!-- Header -->

        <!-- Search -->
        <div
            class="py-2 md:py-3 md:px-4 px-2 flex align-items-center surface-50 relative border-bottom-1 border-gray-300">
            <div class="p-inputgroup inline-flex w-full mb-3 md:mb-0">
                <span class="p-inputgroup-addon"><i class="pi pi-search text-gray-500"></i></span>
                <input type="text" [(ngModel)]="searchText" class="text-sm" pInputText
                    placeholder="{{ 'Shared.SEARCH.MINI-SEARCH.PLACEHOLDER-SEARCH-MEMBERS' | translate }}..." />
            </div>
        </div>
        <!-- Search -->

        <!-- Content -->
        <div class="surface-50 text-sm py-2 border-bottom-1 border-gray-300">
            <p-scrollPanel styleClass="w-full h-25rem">
                <div *ngFor="let member of filteredClient; index as i;" class="flex align-items-center my-2 px-4">
                    <span class="mr-3" index="{{i}}">
                        <ng-container *ngIf="member.image">
                            <p-avatar image="{{member.image}}" shape="circle"></p-avatar>
                        </ng-container>
                        <ng-container *ngIf="!member.image">
                            <p-avatar [label]="client2LetterName(member.full_name) | titlecase | shortName"
                                shape="circle">
                            </p-avatar>
                        </ng-container>
                    </span>
                    <strong class="text-primary">
                        {{member.full_name}}
                    </strong>
                </div>
            </p-scrollPanel>
        </div>
        <!-- Content -->
    </ng-template>
</p-dialog>
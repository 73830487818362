<div>
  <form
    *ngIf="serverCategoryForm"
    [formGroup]="serverCategoryForm"
    (ngSubmit)="updateServiceCategory()"
  >
    <!-- Header -->
    <div
      class="
        md:pl-4
        pl-3
        pr-5
        py-3
        flex
        align-items-center
        relative
        border-bottom-1 border-gray-300
      "
    >
      <div class="flex align-items-center">
        <h2 class="mb-0 text-base md:text-lg font-normal">
          {{activeCategory?('ADMIN.SERVICES.SERVICE_CATEGORY_EDIT' | translate):('ADMIN.SERVICES.SERVICE_CATEGORY_CREATE' | translate) }}
        </h2>
      </div>
      <div
        class="
          pi pi-times
          opacity-50
          absolute
          right-0
          top-0
          mt-3
          pt-1
          mr-3
          md:mr-4
          cursor-pointer
        "
        (click)="editCategoryCancel()"
      ></div>
    </div>
    <!-- Header -->

    <!-- Content -->
    <div class="surface-100 px-4 py-3 text-sm">
      <!-- Service Category Name (English) -->
      <div class="mb-1">
        <label class="text-sm mb-2 block">
          {{ 'ADMIN.SERVICES.SERVICE_CATEGORY_NAME' | translate }}
          <span *ngIf="isSecondaryLanguage">({{
            primaryLanguage | translate
          }}) </span></label
        >
        <input
          formControlName="name_primary_language"
          type="text"
          pInputText
          class="w-full text-sm"
          placeholder=""
          value="Personal Training"
        />
      </div>
      <app-error-msg
        *ngIf="NamePrimary?.touched"
        [errors]="NamePrimary?.errors"
        [fieldLabel]="'ADMIN.SERVICES.SERVICE_CATEGORY_NAME' | translate"
      >
      </app-error-msg>
      <!-- Service Category Name (English) -->

      <!-- Service Category Name (French) -->
      <div class="mb-1" *ngIf="isSecondaryLanguage">
        <label class="text-sm mb-2 block">
          {{ 'ADMIN.SERVICES.SERVICE_CATEGORY_NAME' | translate }} ({{
            secondaryLanguage | translate
          }})</label
        >
        <input
          formControlName="name_secondary_language"
          type="text"
          pInputText
          class="w-full text-sm"
          placeholder=""
          value="Personal Training"
        />
      </div>

      <app-error-msg
        *ngIf="NameSecondary?.touched"
        [errors]="NameSecondary?.errors"
        [fieldLabel]="'ADMIN.SERVICES.SERVICE_CATEGORY_NAME' | translate"
      >
      </app-error-msg>
      <!-- Service Category Name (French) -->

      <!-- Service Category Description (English) -->
      <div class="mb-1">
        <label class="text-sm mb-2 block">
          {{ 'ADMIN.SERVICES.SERVICE_CATEGORY_DESCRIPTION' | translate }} <span *ngIf="isSecondaryLanguage">({{
            primaryLanguage | translate
          }})
          </span> </label
        >
        <textarea
          pInputTextarea
          formControlName="description_primary_language"
          class="w-full text-sm"
        >
        {{
            'ADMIN.SERVICES.SERVICE_CATEGORY_DESCRIPTION_EXPLANATION'
              | translate
          }}
</textarea
        >
      </div>
      <app-error-msg
        *ngIf="DescriptionPrimary?.touched"
        [errors]="DescriptionPrimary?.errors"
        [fieldLabel]="'ADMIN.SERVICES.SERVICE_CATEGORY_DESCRIPTION' | translate"
      >
      </app-error-msg>
      <!-- Service Category Description (English) -->

      <!-- Service Category Description (French) -->
      <div class="mb-1"  *ngIf="isSecondaryLanguage">
        <label class="text-sm mb-2 block"
          >{{ 'ADMIN.SERVICES.SERVICE_CATEGORY_DESCRIPTION' | translate }} ({{
            secondaryLanguage | translate
          }})</label
        >
        <textarea
          pInputTextarea
          formControlName="description_secondary_language"
          class="w-full text-sm"
        >
        {{
            'ADMIN.SERVICES.SERVICE_CATEGORY_DESCRIPTION_EXPLANATION'
              | translate
          }}</textarea
        >
      </div>
      <app-error-msg
        *ngIf="DescriptionSecondary?.touched"
        [errors]="DescriptionSecondary?.errors"
        [fieldLabel]="'ADMIN.SERVICES.SERVICE_CATEGORY_DESCRIPTION' | translate"
      >
      </app-error-msg>
      <!-- Service Category Description (French) -->
    </div>
    <!-- Content -->

    <!-- Footer -->
    <div
      class="
        p-3
        flex
        justify-content-between
        align-items-center
        border-top-1 border-gray-300
      "
    >
      <button
        pButton
        pRipple
        type="button"
        label="{{ 'ADMIN.COMMON.CANCEL' | translate }}"
        styleClass="text-sm"
        class="p-button-text text-sm"
        (click)="categoryCancel()"
      ></button>
      <button
        pButton
        pRipple
        type="submit"
        label="{{ 'ADMIN.COMMON.SAVE' | translate }}"
        class="text-sm"
        [disabled]="serverCategoryForm.invalid"
      ></button>
    </div>
    <!-- Footer -->
  </form>
</div>
<p-toast position="top-right"></p-toast>

<div class="hidden md:flex align-items-center text-xs">
<div *ngIf="status.toLowerCase() === 'cold'">
    <svg xmlns="http://www.w3.org/2000/svg"
    class="icon icon-tabler icon-tabler-snowflake text-primary mr-1" width="24" height="24"
    viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round"
    stroke-linejoin="round">
    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
    <path d="M10 4l2 1 2-1m-2-2v6.5l3 1.72"></path>
    <path d="M10 4l2 1 2-1m-2-2v6.5l3 1.72" transform="rotate(60 12 12)"></path>
    <path d="M10 4l2 1 2-1m-2-2v6.5l3 1.72" transform="rotate(120 12 12)"></path>
    <path d="M10 4l2 1 2-1m-2-2v6.5l3 1.72" transform="rotate(180 12 12)"></path>
    <path d="M10 4l2 1 2-1m-2-2v6.5l3 1.72" transform="rotate(240 12 12)"></path>
    <path d="M10 4l2 1 2-1m-2-2v6.5l3 1.72" transform="rotate(300 12 12)"></path>
    </svg>
</div>
<div *ngIf="status.toLowerCase() === 'warm'">
    <svg xmlns="http://www.w3.org/2000/svg"
    class="icon icon-tabler icon-tabler-temperature text-orange-400" width="24" height="24"
    viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round"
    stroke-linejoin="round">
    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
    <path d="M10 13.5a4 4 0 1 0 4 0v-8.5a2 2 0 0 0-4 0v8.5"></path>
    <line x1="10" y1="9" x2="14" y2="9"></line>
  </svg>
</div>
<div *ngIf="status.toLowerCase() === 'hot'">
    <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-flame p-error"
    width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
    stroke-linecap="round" stroke-linejoin="round">
    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
    <path
      d="M12 12c2-2.96 0-7-1-8 0 3.038-1.773 4.741-3 6-1.226 1.26-2 3.24-2 5a6 6 0 1 0 12 0c0-1.532-1.056-3.94-2-5-1.786 3-2.791 3-4 2z">
    </path>
  </svg>
</div>

<span class="text-xs text-600 font-medium">{{status}}</span>
</div>
import { PlanCategory, PlanIntervalType, SignUpStatus } from "@fitness-force/enum";

export interface OverLappingSubscriptionRequest {
    planId: number;
    planCompanyId: number;
    planTenantId: number;
    planClientId: number;
    planSubscriptionCategory: PlanCategory;
    planBillingIntervalValue: number;
    planBillingIntervalType: PlanIntervalType;
    planContractTerm: number;
    planBillingCycleDay: number;
    planMinimumContractCommitment: number;
    planFeatureIsCashRecurring: boolean;
    planStartDate: string;
    services: OverLappingServiceCategory[];
}

export interface OverLappingServiceCategory {
    serviceId: number;
    serviceCategoryId: number;
    serviceIsBaseMembership: boolean;
}

export interface OverLappingSubscriptionResponse {
    overlapping_errormessage: string;
    new_date: OverLappingSubscriptionNewDate;
    overlapping_subscription: OverLappingSubscriptionAndServiceDetails[];
}

export interface OverLappingSubscriptionNewDate {
    subs_plan_id: number;
    subs_category: PlanCategory;
    subs_start_date: string;
    subs_end_date: string;
}

export interface OverLappingSubscriptionAndServiceDetails {
    subs_id: number;
    subs_plan_id: number;
    subs_company_id: number;
    subs_client_id: number;
    subs_tenant_id: number;
    name_primary_language: string;
    name_secondary_language: string;
    subs_start_date: string;
    subs_end_date: string;
}

export interface OverLappingSubscriptionSignUpServices {
    id: number;
    category_id: number;
    is_basemembership: boolean;
    is_recurring: boolean;
}

export interface OverLappingSubscriptionSignUpResponse {
    overlapping_subscription_signup: OverLappingSubscriptionSignUp;
    overlapping_client_subscription: OverLappingSubscriptionResponse;
}

export interface OverLappingSubscriptionSignUp {
    overlapping_errormessage: string;
    new_date: OverLappingSubscriptionNewDate;
    overlapping_subscription_signup_details: OverLappingSubscriptionSignUpDetails[];
}

export interface OverLappingSubscriptionSignUpDetails {
    sigup_sts_id: number;
    sigup_sts_company_id: number;
    sigup_sts_client_id: number;
    subs_plan_id: number;
    sigup_sts_tenant_id: number;
    sigup_sts_status: SignUpStatus;
    sigup_sts_short_link: string;
    subs_category: PlanCategory;
    subs_start_date: string;
    subs_end_date: string | null;
    services: OverLappingSubscriptionSignUpServices[];
}
<p-dialog [(visible)]="showCallOutcome" [resizable]="false" appendTo="body" [modal]="true"
  [breakpoints]="{ '1199px': '100vw' }" (onHide)="setHidePopup()" [style]="{ width: '1220px' }"
  styleClass="no-header-modal">
  <ng-template pTemplate="header" styleClass="hidden"></ng-template>

  <ng-template pTemplate="content" styleClass="p-0">

    <div class="grid m-0">
      <!-- left side -->
      <div class="
          col-12
          lg:col-4
          hidden
          lg:block
          surface-100
          p-0
          border-right-2 border-gray-300
        ">
        <!-- Header -->
        <div class="bg-blue-50 py-2 px-3 h-3rem md:h-4rem flex align-items-center" *ngIf="modelData.client_info">
          <span class="
              bg-green-500
              px-2
              py-1
              line-height-3
              font-bold
              border-round
              text-white text-xxs
            ">{{ modelData?.client_info?.status | uppercase }}</span>
          <div class="
              relative
              mx-2
              w-6rem
              h-2rem
              overflow-hidden
              flex
              align-items-center
            ">
            <ng-container *ngIf="(getStageStatus(modelData?.stage_master_id) | async) as strStage">
              <img src="/assets/img/icons/opportunity-stage-bg.svg" class="absolute w-6rem h-2rem" /><span class="
              relative
              z1
              text-xs text-primary
              font-bold
              ml-2
              w-10
              block
              overflow-hidden
              text-overflow-ellipsis
              white-space-nowrap
              text-ellipsis
              block
              line-height-4">
                {{ strStage }}
              </span>
            </ng-container>

          </div>
          <span class="w-2rem h-2rem mr-2">
            <p-avatar image="assets/img/avatar.jpg" shape="circle"></p-avatar>
          </span>
          <h3 class="mb-0 text-primary text-base">
            {{ modelData.client_info.full_name }}
          </h3>
          <p class="mb-0 text-xs text-600 ml-2 hidden md:block">
            - {{ modelData.client_info.id }}
          </p>
        </div>
        <!-- Header -->

        <div class="
          border-top-1
          border-bottom-1
          border-gray-300
            bg-white
            p-3
            flex
            align-items-center
            text-xs
          " *ngIf="modelData.client_info">
          <div class="flex align-items-center mr-5 white-space-nowrap">
            <i class="pi pi-phone mr-3"></i>
            <strong>{{ modelData.client_info.mobile_country_code }}
              {{ modelData.client_info.mobile_number }}</strong>
          </div>
          <div class="flex align-items-center break-word">
            <i class="pi pi-envelope text-gray-600 mr-2"></i>
            <span class="text-primary">{{
              modelData.client_info.email_id
              }}</span>
          </div>
        </div>

        <div class="p-3">
          <!-- <app-task-opportunity-info [taskOpportunityInfo]="modelData"></app-task-opportunity-info> -->
          <app-task-opportunity-info [taskOpportunityInfo]="modelData" (ErrorData)="GetErrorData($event)"
            (UpdatedData)="GetUpdatedData($event)"></app-task-opportunity-info>
        </div>
      </div>

      <!-- left side -->

      <!-- Right Side -->
      <div class="col-12 lg:col-8 px-0 relative pb-8 pt-0">
        <!-- Top Area -->
        <div>
          <!-- Header -->
          <div class="
              h-3rem
              md:h-4rem md:pl-4
              pl-3
              pr-5
              flex
              align-items-center
              relative
              border-bottom-2 border-gray-300
            ">
            <div class="flex align-items-center">
              <h2 class="mb-0 text-lg">
                {{ 'OUTCOME.CALLOUTCOME.HOW-DID-CALL-GO' | translate }}
              </h2>
            </div>
            <div class="
                pi pi-times
                opacity-50
                absolute
                right-0
                top-0
                mt-3
                mr-3
                md:mt-4 md:mr-4
                cursor-pointer
              " (click)="setHidePopup()"></div>
          </div>
          <!-- Header -->

          <!-- Dropdown -->
          <div class="relative z-1">
            <div class="
                bg-blue-50
                flex
                align-items-center
                justify-content-between
                py-2
                px-3
                h-3rem
                md:h-4rem
                border-bottom-2 border-gray-300
                lg:hidden
              " pStyleClass="@next" enterClass="hidden" enterActiveClass="scalein" leaveToClass="hidden"
              leaveActiveClass="fadeout">
              <div class="flex align-items-center my-1">
                <span class="mr-2"><img class="border-circle" width="32" src="assets/img/avatar.jpg"
                    alt="Avatar" /></span>
                <span class="text-primary font-bold text-lg mr-2">{{
                  modelData.client_info.full_name
                  }}</span>
                <span class="text-600 font-normal text-sm">{{
                  modelData.client_info.id
                  }}</span>
              </div>
              <div class="flex align-items-center">
                <div [innerHTML]="clientStatus(modelData.client_info)"></div>
                <i class="pi pi-chevron-down ml-2 opacity-50"></i>
              </div>
            </div>

            <div class="
                surface-50
                p-0
                m-0
                border-round
                shadow-0
                lg:shadow-2 lg:absolute
                hidden
                origin-top
                w-full
              ">
              <!-- Content -->
              <div class="p-3">
                <!-- <app-task-opportunity-info [taskOpportunityInfo]="modelData"></app-task-opportunity-info> -->
                <app-task-opportunity-info [taskOpportunityInfo]="modelData" (ErrorData)="GetErrorData($event)"
                  (UpdatedData)="GetUpdatedData($event)"></app-task-opportunity-info>
              </div>
              <!-- Content -->
            </div>
          </div>
          <!-- Dropdown -->

          <div class="
          border-bottom-1
          border-gray-300
          lg:hidden
            bg-white
            p-3
            flex
            align-items-center
            text-xs
          " *ngIf="modelData.client_info">
            <div class="flex align-items-center mr-5 white-space-nowrap">
              <i class="pi pi-phone text-gray-600 mr-2"></i>
              <strong>{{ modelData.client_info.mobile_country_code }}
                {{ modelData.client_info.mobile_number }}</strong>
            </div>
            <div class="flex align-items-center mr-3 break-word">
              <i class="pi pi-envelope text-gray-600 mr-2"></i>
              <span class="text-primary">{{
                modelData.client_info.email_id
                }}</span>
            </div>
          </div>

          <!-- Content -->
          <div class="px-3 py-4">
            <h6 class="text-xs font-normal md:mb-0 mb-2 text-gray-600">
              {{ 'OUTCOME.CALLOUTCOME.CALL-OUTCOME' | translate }}
            </h6>

            <div class="p-buttonset hidden lg:block">
              <button pButton pRipple class="text-sm mt-2" (click)="SelectOutcome(item)"
                *ngFor="let item of outcome; let i = index" label="{{ item.name }}" icon="{{ item.icon }}" [ngClass]="
                  selectedOutcome == item ? item.class : 'p-button-secondary'
                "></button>
            </div>

            <div class="block lg:hidden mt-2">
              <p-dropdown placeholder=" {{ 'OUTCOME.CALLOUTCOME.SELECT' | translate }}" [options]="outcome"
                (onChange)="SelectOutcomeMob($event)" optionLabel="name" styleClass="w-full">
                <ng-template pTemplate="selectedItem">
                  <div class="flex align-items-center" *ngIf="selectedOutcome">
                    <span class="mr-2"><i style="color:'{{ selectedOutcome.iconColor }}'"
                        class="{{ selectedOutcome.icon }}"></i></span>
                    <strong>{{ selectedOutcome.name }}</strong>
                  </div>
                </ng-template>
                <ng-template let-outcome pTemplate="item">
                  <div class="flex align-items-center">
                    <span class="mr-2"><i style="color:'{{ outcome.iconColor }}'" class="{{ outcome.icon }}"></i></span>
                    <div class="text-sm text-gray-600">{{ outcome.name }}</div>
                  </div>
                </ng-template>
              </p-dropdown>
            </div>
          </div>
          <!-- Content -->

          <!-- Info Box -->
          <div class="surface-100 p-3 flex align-items-center">
            <i class="pi pi-info-circle text-500 mr-3"></i>
            <span class="text-xs text-600">{{
              'OUTCOME.CALLOUTCOME.CALL-OUTCOME-ABOUT' | translate
              }}</span>
          </div>
          <!-- Info Box -->

          <!-- Choose Your Outcome -->
          <ng-container *ngTemplateOutlet="selectedOutcome ? getoutcome : chooseoutcome"></ng-container>
          <!-- Choose Your Outcome -->
        </div>
        <!-- Top Area -->
      </div>
      <!-- Right Side -->

      <!-- </form> -->
    </div>
  </ng-template>

  <ng-template #chooseoutcome>
    <div class="px-3 py-5 text-center">
      <h2 class="mb-4 text-lg md:text-2xl">
        {{ 'OUTCOME.TOUROUTCOME.CHOOSEOUTCOME' | translate }}
      </h2>
      <img width="260" src="assets/img/call-outcome-img.svg" alt="Image" />
    </div>
  </ng-template>

  <ng-template #noanswer>
    <form [formGroup]="frmCallOutComeNoAnswer" (ngSubmit)="CreateCallOutcomeNoAnswer(frmCallOutComeNoAnswer)">
      <!-- Choose Your Outcome -->
      <div class="px-3 py-5">
        <div class="flex align-items-center md:mb-6 mb-4">
          <p-inputSwitch formControlName="noanswer_markascomplete" (onChange)="toggleNoAnswer($event.checked)">
          </p-inputSwitch>
          <h5 class="text-sm mb-0 ml-3 -mt-1">
            {{ 'OUTCOME.TOUROUTCOME.MARKTASKCOMPLETED' | translate }}
          </h5>
        </div>

        <div class="grid">
          <div class="col-12 py-0">
            <h5 class="text-sm font-normal mb-0">
              {{ 'OUTCOME.CALLOUTCOME.SELECT-NEXT-FOLLOWUP-DATE' | translate }}
            </h5>
          </div>
          <div class="col-12 md:col-4">
            <p-calendar [showIcon]="true" formControlName="noanswer_expecteddate" (keyup)="$event.preventDefault()"
              placeholder="{{ 'OUTCOME.CALLOUTCOME.SELECTDATE' | translate }}" inputStyleClass="text-sm"></p-calendar>
            <app-error-msg *ngIf="
                getNoAnswer_ExpectedDate.touched &&
                getNoAnswer_ExpectedDate.invalid
              " [errors]="getNoAnswer_ExpectedDate.errors" [fieldLabel]="'OUTCOME.CALLOUTCOME.DATE' | translate">
            </app-error-msg>
          </div>
          <div class="col-12 md:col-4 mt-1 md:mt-0">
            <p-calendar [showIcon]="true" icon="pi pi-clock" hourFormat="12" [timeOnly]="true" inputId="timeonly"
              [showIcon]="true" formControlName="noanswer_expectedtime"
              placeholder="{{ 'OUTCOME.CALLOUTCOME.SELECTTIME' | translate }}" StyleClass="text-sm"
              inputStyleClass="text-sm"></p-calendar>
            <app-error-msg *ngIf="
                getNoAnswer_ExpectedTime.touched &&
                getNoAnswer_ExpectedTime.invalid
              " [errors]="getNoAnswer_ExpectedTime.errors" [fieldLabel]="'OUTCOME.CALLOUTCOME.TIME' | translate">
            </app-error-msg>
          </div>
          <div class="col-12 mt-4">
            <h5 class="font-medium text-gray-700 text-xs mb-2 block uppercase">
              {{ 'OUTCOME.CALLOUTCOME.NOTES' | translate }}
            </h5>
            <textarea pInputTextarea class="p-inputtextarea p-inputtext p-component p-element w-full"
              formControlName="noanswer_notes"></textarea>
          </div>
        </div>
      </div>
      <!-- Choose Your Outcome -->
      <div class="
          px-4
          py-3
          border-top-1 border-gray-400
          absolute
          right-0
          bottom-0
          w-full
        ">
        <div class="flex align-items-center justify-content-between">
          <div class="w-6">
            <p-button label="{{ 'common.BUTTONS.CANCEL-BUTTON-TEXT' | translate }}" styleClass="p-button-link text-sm"
              (click)="setHidePopup()"></p-button>
          </div>
          <div class="col-6 text-right">
            <button pButton pRipple label="{{ 'common.BUTTONS.SUBMIT-BUTTON-TEXT' | translate }}"
              type="submit"></button>
          </div>
        </div>
      </div>
    </form>
  </ng-template>

  <ng-template #contactmade>
    <form [formGroup]="frmCallOutcomeContactmade" (ngSubmit)="CreateCallOutcomeContactMade(frmCallOutcomeContactmade)">
      <div class="px-3 py-5">
        <div class="grid">
          <div class="col-12 py-0">
            <h5 class="text-sm font-normal mb-0">
              {{ 'OUTCOME.CALLOUTCOME.SELECT-NEXT-FOLLOWUP-DATE' | translate }}
            </h5>
          </div>
          <div class="col-12 md:col-4">
            <p-calendar [showIcon]="true" formControlName="contactmade_expecteddate" (keyup)="$event.preventDefault()"
              placeholder="{{ 'OUTCOME.CALLOUTCOME.SELECTDATE' | translate }}" inputStyleClass="text-sm"></p-calendar>
            <app-error-msg *ngIf="
                getContactMade_ExpectedDate.touched &&
                getContactMade_ExpectedDate.invalid
              " [errors]="getContactMade_ExpectedDate.errors" [fieldLabel]="'OUTCOME.CALLOUTCOME.DATE' | translate">
            </app-error-msg>
          </div>
          <div class="col-12 md:col-4 mt-1 md:mt-0">
            <p-calendar [showIcon]="true" icon="pi pi-clock" hourFormat="12" [timeOnly]="true" inputId="timeonly"
              [showIcon]="true" formControlName="contactmade_expectedtime"
              placeholder="{{ 'OUTCOME.CALLOUTCOME.SELECTTIME' | translate }}" StyleClass="text-sm"
              inputStyleClass="text-sm"></p-calendar>
            <app-error-msg *ngIf="
                getContactMade_ExpectedTime.touched &&
                getContactMade_ExpectedTime.invalid
              " [errors]="getContactMade_ExpectedTime.errors" [fieldLabel]="'OUTCOME.CALLOUTCOME.TIME' | translate">
            </app-error-msg>
          </div>
          <div class="col-12 mt-4">
            <h5 class="font-medium text-gray-700 text-xs mb-2 block uppercase">
              {{ 'OUTCOME.CALLOUTCOME.NOTES' | translate }}
            </h5>
            <textarea pInputTextarea class="p-inputtextarea p-inputtext p-component p-element w-full"
              formControlName="contactmade_notes"></textarea>
            <app-error-msg *ngIf="getContactMade_Notes.touched && getContactMade_Notes.invalid"
              [errors]="getContactMade_Notes.errors" [fieldLabel]="'OUTCOME.CALLOUTCOME.NOTES' | translate">
            </app-error-msg>
          </div>
        </div>
      </div>
      <!-- Choose Your Outcome -->
      <div class="
          px-4
          py-3
          border-top-1 border-gray-400
          absolute
          right-0
          bottom-0
          w-full
        ">
        <div class="flex align-items-center justify-content-between">
          <div class="w-6">
            <p-button label="{{ 'common.BUTTONS.CANCEL-BUTTON-TEXT' | translate }}" styleClass="p-button-link text-sm"
              (click)="setHidePopup()"></p-button>
          </div>
          <div class="w-6 text-right">
            <button pButton pRipple class="text-sm" label="{{ 'common.BUTTONS.SUBMIT-BUTTON-TEXT' | translate }}"
              type="submit"></button>
          </div>
        </div>
      </div>
    </form>
  </ng-template>

  <ng-template #notintrested>
    <form [formGroup]="frmCallOutNotInterested" (ngSubmit)="CreateNotInteresredNotInterested(frmCallOutNotInterested)">
      <!-- Choose Your Outcome -->
      <div class="px-3 py-5">
        <div class="flex align-items-center mb-4">
          <p-inputSwitch formControlName="notintrested_markcompleted" [ngModel]="truevalue" [disabled]="true">
          </p-inputSwitch>
          <h5 class="text-sm mb-0 ml-3 -mt-1">
            {{ 'OUTCOME.CALLOUTCOME.MARKTASKCOMPLETED' | translate }}
          </h5>
        </div>
        <h5 class="font-medium text-gray-700 text-xs mb-2 block uppercase">
          {{ 'OUTCOME.CALLOUTCOME.NOTES' | translate }}
        </h5>
        <textarea pInputTextarea class="p-inputtextarea p-inputtext p-component p-element w-full"
          formControlName="notintrested_notes"></textarea>
        <app-error-msg *ngIf="
            getNotInterested_Notes.touched && getNotInterested_Notes.invalid
          " [errors]="getNotInterested_Notes.errors" [fieldLabel]="'OUTCOME.CALLOUTCOME.NOTES' | translate">
        </app-error-msg>
      </div>
      <!-- Choose Your Outcome -->
      <div class="
          px-4
          py-3
          border-top-1 border-gray-400
          absolute
          right-0
          bottom-0
          w-full
        ">
        <div class="flex align-items-center justify-content-between">
          <div class="w-6">
            <p-button label="{{ 'common.BUTTONS.CANCEL-BUTTON-TEXT' | translate }}" styleClass="p-button-link text-sm"
              (click)="setHidePopup()"></p-button>
          </div>
          <div class="w-6 text-right">
            <button pButton pRipple class="text-sm" label="{{ 'common.BUTTONS.SUBMIT-BUTTON-TEXT' | translate }}"
              type="submit"></button>
          </div>
        </div>
      </div>
    </form>
  </ng-template>

  <ng-template #booktour>
    <form [formGroup]="frmCallOutcomeBookTour" (ngSubmit)="CreateCallOutcomeBookTour(frmCallOutcomeBookTour)">
      <!-- Choose Your Outcome -->
      <div class="px-3 py-5">
        <div class="grid">
          <div class="col-12 py-0">
            <h5 class="text-sm font-normal mb-0">
              {{ 'OUTCOME.CALLOUTCOME.SELECT-NEXT-FOLLOWUP-DATE' | translate }}
            </h5>
          </div>
          <div class="col-12 md:col-4">
            <p-calendar [showIcon]="true" formControlName="booktour_expecteddate"
              placeholder="{{ 'OUTCOME.CALLOUTCOME.SELECTDATE' | translate }}" inputStyleClass="text-sm"></p-calendar>

            <app-error-msg *ngIf="
                getBookTour_ExpectedDate.touched &&
                getBookTour_ExpectedDate.invalid
              " [errors]="getBookTour_ExpectedDate.errors" [fieldLabel]="'OUTCOME.CALLOUTCOME.DATE' | translate">
            </app-error-msg>
          </div>
          <div class="col-12 md:col-4 mt-1 md:mt-0">
            <p-calendar hourFormat="12" [timeOnly]="true" [showIcon]="true" icon="pi pi-clock" inputId="timeonly"
              [showIcon]="true" formControlName="booktour_expectedtime"
              placeholder="{{ 'OUTCOME.CALLOUTCOME.SELECTTIME' | translate }}" StyleClass="text-sm"
              inputStyleClass="text-sm"></p-calendar>

            <app-error-msg *ngIf="
                getBookTour_ExpectedTime.touched &&
                getBookTour_ExpectedTime.invalid
              " [errors]="getBookTour_ExpectedTime.errors" [fieldLabel]="'OUTCOME.CALLOUTCOME.TIME' | translate">
            </app-error-msg>
          </div>
          <div class="col-12 mt-4">
            <h5 class="font-medium text-gray-700 text-xs mb-2 block uppercase">
              {{ 'OUTCOME.CALLOUTCOME.NOTES' | translate }}
            </h5>
            <textarea pInputTextarea class="p-inputtextarea p-inputtext p-component p-element w-full"
              formControlName="booktour_notes"></textarea>
          </div>
        </div>
      </div>
      <!-- Choose Your Outcome -->
      <div class="
          px-4
          py-3
          border-top-1 border-gray-400
          absolute
          right-0
          bottom-0
          w-full
        ">
        <div class="flex align-items-center justify-content-between">
          <div class="w-6">
            <p-button label="{{ 'common.BUTTONS.CANCEL-BUTTON-TEXT' | translate }}" styleClass="p-button-link text-sm"
              (click)="setHidePopup()"></p-button>
          </div>
          <div class="w-6 text-right">
            <button pButton pRipple class="text-sm" label="{{ 'common.BUTTONS.SUBMIT-BUTTON-TEXT' | translate }}"
              type="submit"></button>
          </div>
        </div>
      </div>
    </form>
  </ng-template>
</p-dialog>

<billing *ngIf="showPaidFullSidebar" [visible]="showPaidFullSidebar" [planSummarydata]="planSummarydataObject$"
  [showBilling]="showPaidFullSidebar" closeOnEscape="false" (closeSidePopupMember)="hideBillingSummary($event)">
</billing>

<p-toast></p-toast>

<app-errors *ngIf="ShowErrorPopup | async as showPopup" [visible]="showPopup" [errorData]="errorMessages"
  (hideErrorPopup)="HideErrorPopup($event)">
</app-errors>

<app-lost-deal [showDailog]="showChooseReason" (closeMainDailog)="closeDailog()" [lostDealInfo]="lostDealItemInfo">
</app-lost-deal>
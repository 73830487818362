import { TaskAssignToType, TaskPriority, TaskType, TourOutcome } from "@fitness-force/enum";

export interface CreateTaskByClientRequest {
    client_id: number[];
    assigned_to_type: TaskAssignToType;
    assigned_to_staff_id: number;
    type: TaskType;
    category_id: number;
    title: string;
    notes: string;
    priority: TaskPriority;
    scheduled_date: Date;
}

export interface CreateTaskByOpportunityRequest {
    opportunity_id: number[];
    assigned_to_type: TaskAssignToType;
    assigned_to_staff_id: number;
    type: TaskType;
    category_id: number;
    title: string;
    notes: string;
    priority: TaskPriority;
    scheduled_date: Date;
}

export interface CreateRecurringTaskByClientRequest {
    client_id: number[];
    assigned_to_type: TaskAssignToType;
    assigned_to_staff_id: number;
    type: TaskType;
    category_id: number;
    title: string;
    notes: string;
    priority: TaskPriority;
    start_date: Date;
    time: string;
    repeat_rule: string;
}

export interface CreateRecurringTaskByOpportunityRequest {
    opportunity_id: number[];
    assigned_to_type: TaskAssignToType;
    assigned_to_staff_id: number;
    type: TaskType;
    category_id: number;
    title: string;
    notes: string;
    priority: TaskPriority;
    start_date: Date;
    time: string;
    repeat_rule: string;
}

export interface UpdateTask {
    category_id: number,
    assigned_to_type: TaskAssignToType,
    assigned_to_staff_id: number,
    priority: TaskPriority,
    title: string,
    notes: string,
    scheduled_date: string
}
export interface TASK_TYPE {
    paramName: string;
    paramValue: string | Date;
}

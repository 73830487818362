import { OpportunitiesOutcome, OpportunitiesType, OpportunityBoardType } from "@fitness-force/enum";

export interface OpportunityResponse {
    id: number;
    company_id: number;
    tenant_id: number;
    board_type: OpportunityBoardType;
    name: string;
    client_info: OpportunityClientInfo;
    stage_master_id: number;
    service_type: OpportunityCardServicety[];
    value_in_minor_currency:number;
    expected_closure_date:Date;
    assigned_to_staff_id:number;
    opp_staff_info: OpportunityStaffInfo;
    tour_id: number;
    trial_id: number;
    comments:string;
    hot_warm_cold: OpportunitiesType;
    is_fresh: boolean;
    is_stale: boolean;
    outcome: OpportunitiesOutcome;
    created_by: number;
    created_date: string;
    updated_by: number;
    updated_date: string;
    latest_task:LatestTask
}

export interface LatestTask{
    id:number;
    type: string;
    category_id:number;
    name_primary_language:string;
    name_secondary_language:string;
    priority:string;
    scheduled_date:string;
    status:string;
    title:string;
}



// export interface CreateOpportunityRequest {
//     name: string;
//     client_id: number;
//     opp_stage_master_id: number;
//     service_type_id: number[];
//     staff_id: number;
//     comments: string;
//     hot_warm_cold: OpportunitiesType;
//     outcome: OpportunitiesOutcome;
// }

// import { OpportunitiesType } from "./opportunitiesType";

export interface CreateOpportunityRequest {
    name: string;
    client_id: number[];
    stage_master_id: number;
    service_type_ids: number[];
    value_in_minor_currency: number;
    expected_closure_date?: Date;
    assigned_to_staff_id: number;
    comments: string;
    hot_warm_cold: OpportunitiesType;
}

export interface UpdateOpportunityRequest {    
    name: string;
    service_type_ids: number[];
    value_in_minor_currency:number;
    expected_closure_date?:Date | null;
    assigned_to_staff_id:number;
    comments: string;
    hot_warm_cold: OpportunitiesType;
    stage_master_id:number;
}

export interface OpportunityClientInfo {
    client_id: number;
    client_full_name: string;
    mobile_country_code: string;
    mobile_no: number;
    email_id: string;
    image: string;
}

export interface OpportunityCardServicety {
    servicety_id: number;
    name_primary_language: string;
    name_secondary_language: string;
}

export interface OpportunityStaffInfo {
    staff_id: number;
    staff_name: string;
    staff_image: string;
}


export interface ListOpportunityResponse {
    items: OpportunityResponse[];
}
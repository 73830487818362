export interface STAGE_MASTER_MODEL  {
    id: number;
    board_type: string;
    company_id: number;
    tenant_id: number;
    name_primary_language: string;
    name_secondary_language: string;
    order: number;
    status: string;
    stage_master_key: number;
    created_by: number;
    created_date: string;
    updated_by: number;
    updated_date: string;
}

export interface STAGE_MASTER_RESPONSE_MODEL {
    stages: Array<STAGE_MASTER_MODEL>
}
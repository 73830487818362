import { PayModeLevel1, Status } from "@fitness-force/enum";

export interface ListPaymentModeMastersResponse {
    items: PaymentModeMastersResponse[];
}

export interface PaymentModeMastersResponse {
    id: number;
    tenant_id: number;
    company_id: number;
    type: PayModeLevel1;
    name_primary_language: string;
    name_secondary_language: string;
    description_primary_language: string;
    description_secondary_language: string;
    status: Status;
    created_by: number;
    updated_by: number;
    created_date: string;
    updated_date: string;
}
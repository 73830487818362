
import { FormControl } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { Component, OnInit, ChangeDetectionStrategy, NgModule, Input, EventEmitter, Output, ViewChild, ElementRef, NgZone, ViewContainerRef, AfterContentInit, AfterViewInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { FormsModule, ReactiveFormsModule, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { ChipModule } from 'primeng/chip';
import { ChipsModule } from 'primeng/chips';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { InputNumberModule } from 'primeng/inputnumber';
import { StyleClassModule } from 'primeng/styleclass';
import { LostDealBannerComponentModule } from '../lost-deal-banner/lost-deal-banner.component';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { InputMaskModule } from 'primeng/inputmask';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { FactoryHttpLoader } from '@fitness-force/shared';
import { HttpClient } from '@angular/common/http';
import { ErrorMsgComponentModule } from '@fitness-force/errors';
import { OpportunityService } from 'libs/api/src/lib/CRM/opportunity.service';
import { OpportunityBoardType } from '@fitness-force/enum';

import { AgmCoreModule, GoogleMapsAPIWrapper, MapsAPILoader } from '@agm/core';
import { GooglePlacesComponentModule } from '../../../../google-places/google-places.component';
import { CONSTANTS_DATA } from '@fitness-force/constants';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';
import { ClientService } from '@fitness-force/api';

@Component({
  selector: 'app-lost-deal-reason',
  templateUrl: './lost-deal-reason.component.html',
  styleUrls: ['./lost-deal-reason.component.scss'],
  providers: [MessageService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LostDealReasonComponent implements OnInit, OnDestroy {
  @Input() reason: any = { title: '', name: '' }
  @Input() showLostReason: boolean = false
  @Input() dealInfo: any;
  @Output() goBack: any = new EventEmitter()
  @Output() closeDailogs: any = new EventEmitter()
  @Output() reloadData: any = new EventEmitter()
  contractEndDate: Date | undefined;
  selectedReason: any;
  reasons = CONSTANTS_DATA.LOST_DEALS_OTHER_REASONS;
  tags: string[] | undefined;

  lostToCompetitors: FormGroup = new FormGroup({})
  lostDueToHighPrice: FormGroup = new FormGroup({})
  missingFacility: FormGroup = new FormGroup({})
  otherReasons: FormGroup = new FormGroup({})
  notContactable: FormGroup = new FormGroup({})
  lostDealModal = {}
  latitude: number | any;
  longitude: number | any;
  placeId: string = ''
  constructor(private messageService: MessageService ,private clientService:ClientService, private mapsAPILoader: MapsAPILoader, private changeDetectorRef: ChangeDetectorRef, private ngZone: NgZone, private fb: FormBuilder, public translate: TranslateService, private oppService: OpportunityService) {
  }

  ngOnInit(): void {

    this.componentInit();
  }


  componentInit() {
    this.lostToCompetitors = this.fb.group({
      competitorName: ['', [Validators.required]],
      contractEndDate: ['', [Validators.required]],
      lostToCompetitorComments: ['', [Validators.required]]
    })
    this.lostDueToHighPrice = this.fb.group({
      expectedPrice: ['', [Validators.required]],
      lostHighPriceComments: ['', [Validators.required]]
    })
    this.missingFacility = this.fb.group({
      selectFacilities: ['', [Validators.required]],
      missingFacilityComments: ['', [Validators.required]]
    })
    this.otherReasons = this.fb.group({
      reason: ['', [Validators.required]],
      otherReasonsComments: ['', [Validators.required]]
    })
    this.notContactable = this.fb.group({
      notContactableComments: ['', [Validators.required]]
    })

  }

  get CompetitorName(): FormControl {
    return this.lostToCompetitors.get('competitorName') as FormControl;
  }
  get ContractEndDate(): FormControl {
    return this.lostToCompetitors.get('contractEndDate') as FormControl;
  }
  get LostToCompetitorComments(): FormControl {
    return this.lostToCompetitors.get('lostToCompetitorComments') as FormControl;
  }
  get ExpectedPrice(): FormControl {
    return this.lostDueToHighPrice.get('expectedPrice') as FormControl;
  }
  get LostHighPriceComments(): FormControl {
    return this.lostDueToHighPrice.get('lostHighPriceComments') as FormControl;
  }
  get SelectFacilities(): FormControl {
    return this.missingFacility.get('selectFacilities') as FormControl;
  }
  get MissingFacilityComments(): FormControl {
    return this.missingFacility.get('missingFacilityComments') as FormControl;
  }
  get Reason(): FormControl {
    return this.otherReasons.get('reason') as FormControl;
  }
  get OtherReasonsComments(): FormControl {
    return this.otherReasons.get('otherReasonsComments') as FormControl;
  }
  get NotContactableComments(): FormControl {
    return this.notContactable.get('notContactableComments') as FormControl;
  }

  closeReasonDailog() {
    this.goBack.emit(false)
    this.resetDialogs()
  }
  closeAllDialogs() {
    this.resetDialogs()
    this.closeDailogs.emit(true)
  }


  saveLostAsDeal(type: string) {
    if (type == 'LostToCompetitor') {
      this.lostDealModal = {
        "reason_type": "LostToCompetitor",
        "reason_other": null,
        "competitor_name": this.CompetitorName.value,
        "competitor_latitude": this.latitude.toString(),
        "competitor_longitude": this.longitude.toString(),
        "competitor_place_id": this.placeId,
        "contract_end_date": this.ContractEndDate.value,
        "expected_price_in_minor_currency": null,
        "missing_facility": null,
        "comments": this.LostToCompetitorComments.value
      }
    } else if (type == 'HighPrice') {
      this.lostDealModal = {
        "reason_type": "HighPrice",
        "reason_other": null,
        "competitor_name": null,
        "competitor_latitude": null,
        "competitor_longitude": null,
        "competitor_place_id": null,
        "contract_end_date": null,
        "expected_price_in_minor_currency": this.ExpectedPrice.value,
        "missing_facility": null,
        "comments": this.LostHighPriceComments.value
      }
    } else if (type == 'MissingFacility') {
      this.lostDealModal = {
        "reason_type": "MissingFacility",
        "reason_other": null,
        "competitor_name": null,
        "competitor_latitude": null,
        "competitor_longitude": null,
        "competitor_place_id": null,
        "contract_end_date": null,
        "expected_price_in_minor_currency": null,
        "missing_facility": this.SelectFacilities.value,
        "comments": this.MissingFacilityComments.value
      }
    } else if (type == 'OtherReasons') {
      this.lostDealModal = {
        "reason_type": "Other",
        "reason_other": this.Reason.value.name,
        "competitor_name": null,
        "competitor_latitude": null,
        "competitor_longitude": null,
        "competitor_place_id": null,
        "contract_end_date": null,
        "expected_price_in_minor_currency": null,
        "missing_facility": null,
        "comments": this.OtherReasonsComments.value
      }
    } else if (type == 'NotContactable') {
      this.lostDealModal = {
        "reason_type": "NotContactable",
        "reason_other": null,
        "competitor_name": null,
        "competitor_latitude": null,
        "competitor_longitude": null,
        "competitor_place_id": null,
        "contract_end_date": null,
        "expected_price_in_minor_currency": null,
        "missing_facility": null,
        "comments": this.NotContactableComments.value
      }
    }
    this.oppService.OpportunityMarkAsLost(this.dealInfo.tenant_id, OpportunityBoardType.NewMemberBoard, this.dealInfo.id, this.lostDealModal).subscribe((res: any) => {
      this.closeAllDialogs()
      console.log('res', res)
      this.messageService.add({ severity: 'success', summary: 'success', detail: 'Deal marked as lost' });
      this.reloadData.emit(res)
    })
  }

  getPlaceDetails(place: any) {
    console.log('llllll', place)
    this.latitude = place.geometry.location.lat()
    this.longitude = place.geometry.location.lng()
    this.placeId = place.place_id
  }

  resetDialogs() {
    this.latitude = '';
    this.longitude = '';
    this.placeId = '';
    this.lostToCompetitors.reset();
    this.lostDueToHighPrice.reset();
    this.missingFacility.reset();
    this.otherReasons.reset();
    this.notContactable.reset();
  }

  ngOnDestroy(): void {
    this.resetDialogs()
  }

  clientStatus(client: any): string {
    if (client && client.status)
      return this.clientService.getClientStatusHtml(client.status.toString());
    else
      return '';
  }
}

@NgModule({
  declarations: [LostDealReasonComponent],
  imports: [
    CommonModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: FactoryHttpLoader,
        deps: [HttpClient]
      }
    }),
    ErrorMsgComponentModule,
    DialogModule,
    InputTextModule,
    InputNumberModule,
    ButtonModule,
    StyleClassModule,
    DropdownModule,
    ReactiveFormsModule,
    FormsModule,
    CalendarModule,
    LostDealBannerComponentModule,
    ChipModule,
    ChipsModule,
    InputTextareaModule,
    InputMaskModule,
    GooglePlacesComponentModule,
    ToastModule
  ],
  providers: [GoogleMapsAPIWrapper],
  exports: [LostDealReasonComponent]
})
export class LostDealReasonComponentModule {
}

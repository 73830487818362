<strong *ngIf="!edit" (click)="edit = true" class="cursor-pointer border-round bg-gray-300 p-2">
  <span pTooltip="{{ value }}">{{
    value && value.length > 24 ? (value | slice: 0:24) + '...' : value
  }}</span>
  {{ !value ? '-' : '' }}
</strong>
<div *ngIf="edit">
  <span [formGroup]="inplaceForm" class="p-inputgroup w-12rem">
    <input  (keyup.enter)="onKeyEnter($event)"  (keyup.escape)="onKeyEscape($event)"  class="text-sm" type="text" pInputText [formControlName]="'value'" />
    <button
      (click)="updateValue()"
      pButton
      type="button"
      class="p-button-secondary border-top-none border-left-none border-bottom-none border-right-1 border-white"
      icon="pi pi-check"
      iconPos="left"

    ></button>
    <button
      (click)="close()"
      pButton
      type="button"
      class="p-button-secondary"
      icon="pi pi-times"
      iconPos="left"
    ></button>
  </span>
  <app-error-msg
    *ngIf="inplaceForm.get('value')?.touched && inplaceForm.invalid"
    [errors]="inplaceForm.errors"
    [errors]="inplaceForm.get('value')?.errors"
    [fieldLabel]="'Shared.TASK.TASK-CREATE.TASK_CATEGORY_NAME' | translate"
  >
  </app-error-msg>
</div>

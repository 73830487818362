<p-dialog [(visible)]="showLostReason" [resizable]="false" appendTo="body" [modal]="true"
  [breakpoints]="{'1199px': '100vw'}" [style]="{width: '57vw'}" styleClass="no-header-modal">

  <ng-template pTemplate="header" styleClass="hidden"></ng-template>
  <ng-template pTemplate="content" styleClass="p-0">


    <!-- Header -->
    <div class="h-3rem md:h-4rem md:pl-4 pl-3 pr-5 flex align-items-center relative border-bottom-2 border-gray-300">
      <div class="h-full pt-2 flex align-items-center text-primary border-right-2 border-gray-300 pr-3 mr-3 cursor-pointer"
        (click)="closeReasonDailog()">
        <i class="pi pi-chevron-left mr-2"></i>
        <span class="text-sm">BACK</span>
      </div>
      <div class="flex align-items-center">
        <h2 class="mb-0 text-lg capitalize" *ngIf="reason && reason.name">{{reason.name}}</h2>
      </div>
      <div class="pi pi-times opacity-50 absolute right-0 top-0 mt-3 mr-3 md:mt-4 md:mr-4 cursor-pointer" (click)="closeReasonDailog()"
        ></div>
    </div>
    <!-- Header -->

    <!-- Header -->
    <div class="flex align-items-center justify-content-between py-2 px-4 border-bottom-2 border-gray-300">
      <div class="flex align-items-center my-1">
        <span class="mr-2"><img class="border-circle" width="32" *ngIf="dealInfo && dealInfo.client_info.image" [src]="dealInfo.client_info.image"  alt="Avatar" /></span>
        <span class="text-primary font-bold text-lg mr-2" *ngIf="dealInfo">{{dealInfo.client_info.full_name}}</span>
        <span class="text-600 font-normal text-sm" *ngIf="dealInfo">Client ID - {{dealInfo.client_info.id}}</span>
      </div>
      <div [innerHTML]="clientStatus(dealInfo.client_info)"></div>
      <!-- <span class="bg-red px-2 py-1 font-bold border-round text-white text-xxs" *ngIf="dealInfo">{{dealInfo.client_info.status}}</span> -->
    </div>
    <!-- Header -->


    <!-- Content -->
    <div class="flex">

      <!-- Left Side-->
    <div class="border-right-2 border-gray-300 lg:w-8 w-full">
        <ng-container *ngIf="reason && reason.title && reason.title === 'lost-to-competitor-img'">
            <form [formGroup]="lostToCompetitors" (ngSubmit)="saveLostAsDeal('LostToCompetitor')">
                <div class="p-4 surface-100">

                <div class="mb-3">
                    <h4 class="text-base font-normal mb-2">{{'OPPORTUNITY.LOSTDEAL.NAMEOFCOMPETITOR' | translate}}</h4>
                   <ff-google-places [competitorName]="CompetitorName" [placeHolderName]="'OPPORTUNITY.LOSTDEAL.NAMEOFCOMPETITOR' | translate" (fetchPlaceInfo)="getPlaceDetails($event)"></ff-google-places>
                    <app-error-msg *ngIf="CompetitorName.touched && CompetitorName.invalid" [errors]="CompetitorName.errors"
                    [fieldLabel]="'OPPORTUNITY.LOSTDEAL.NAMEOFCOMPETITOR' | translate"></app-error-msg>
                </div>

                <div class="mb-3">
                    <h4 class="font-medium text-gray-700 text-xs mb-1 block uppercase">{{'OPPORTUNITY.LOSTDEAL.CONTRACTENDDATE' | translate}}</h4>
                    <p class="mb-2 text-gray-600 text-sm">{{'OPPORTUNITY.LOSTDEAL.CONTRACTENDDATEINSTRUCTOR' | translate}}</p>
                    <p-calendar [showIcon]="true"  formControlName="contractEndDate" placeholder="Select Date"
                    inputStyleClass="text-sm w-full" styleClass="w-full"></p-calendar>
                    <app-error-msg *ngIf="ContractEndDate.touched && ContractEndDate.invalid" [errors]="ContractEndDate.errors"
                    [fieldLabel]="'OPPORTUNITY.LOSTDEAL.CONTRACTENDDATE' | translate"></app-error-msg>    
                </div>

                <div class="mb-2 md:mb-3">
                    <h4 class="font-medium text-gray-700 text-xs mb-1 block uppercase">{{'OPPORTUNITY.LOSTDEAL.COMMENT' | translate}}</h4>
                    <h5 class="text-sm mb-2 text-gray-700">{{'OPPORTUNITY.LOSTDEAL.WHATCOULDHAVEDONEBETTER' | translate}}</h5>
                    <p class="mb-2 text-gray-600 text-sm">{{'OPPORTUNITY.LOSTDEAL.LOSTDUETOCOMPETITORINSTRUCTOR' | translate}}</p>
                    <textarea pInputTextarea class="w-full h-7rem text-sm" formControlName="lostToCompetitorComments" placeholder="Add Comments..."></textarea>
                    <app-error-msg *ngIf="LostToCompetitorComments.touched && LostToCompetitorComments.invalid" [errors]="LostToCompetitorComments.errors"
                    [fieldLabel]="'OPPORTUNITY.LOSTDEAL.COMMENT' | translate"></app-error-msg>    
                </div>
                </div>

                <!-- Modal Footer -->
                <div class="lg:px-4 px-2 pt-3 pb-1 border-top-1 border-gray-400 bg-white">
                <div class="grid align-items-center justify-content-between">
                    <div class="col-4">
                    <p-button label="Cancel" styleClass="p-button-link text-sm"  (click)="closeAllDialogs()"></p-button>
                    </div>
                    <div class="col-8 text-right">
                    <button  type="submit" class="text-sm" pButton pRipple label="Save" [disabled]="!lostToCompetitors.valid" ></button>
                    </div>
                </div>
                </div>
                <!-- Modal Footer -->
                
            </form>
        </ng-container>
        <ng-container *ngIf="reason && reason.title && reason.title === 'lost-to-high-price'">
            <form [formGroup]="lostDueToHighPrice" (ngSubmit)="saveLostAsDeal('HighPrice')">
            <div class="p-4 surface-100">

                <div class="mb-5">
                  <h4 class="font-medium text-gray-700 text-xs mb-2 block uppercase">What was the expected price?</h4>
                  <p class="mb-2 text-gray-600 text-sm">An automatic follow-up will be setup 2 weeks prior to the end date
                    provided.</p>
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">$</span>
                    <p-inputNumber [format]="false" class="w-full text-sm" placeholder="Amount..."  formControlName="expectedPrice"></p-inputNumber> 
                </div>
                <app-error-msg *ngIf="ExpectedPrice.touched && ExpectedPrice.invalid" [errors]="ExpectedPrice.errors"
                    [fieldLabel]="'Expected price'"></app-error-msg>   
                </div>
      
      
                <div class="mb-2 md:mb-5">
                  <h4 class="font-medium text-gray-700 text-xs mb-1 block uppercase">Comments</h4>
                  <p class="mb-2 text-gray-600 text-sm">An automatic follow-up will be setup 2 weeks prior to the end date
                    provided.</p>
                  <textarea pInputTextarea class="w-full h-9rem text-sm" formControlName="lostHighPriceComments" placeholder="Add Comments..."></textarea>
                  <app-error-msg *ngIf="LostHighPriceComments.touched && LostHighPriceComments.invalid" [errors]="LostHighPriceComments.errors"
                  [fieldLabel]="'Comments'"></app-error-msg>    
                </div>
      
              </div>
      
              <!-- Modal Footer -->
              <div class="lg:px-4 px-2 pt-3 pb-1 border-top-1 border-gray-400">
                <div class="grid align-items-center justify-content-between">
                  <div class="col-4">
                    <p-button label="Cancel" styleClass="p-button-link text-sm"   (click)="closeAllDialogs()"
                      ></p-button>
                  </div>
                  <div class="col-8 text-right">
                    <button type="submit" class="text-sm" pButton pRipple label="Save" [disabled]="!lostDueToHighPrice.valid"
                      ></button>
                  </div>
                </div>
              </div>
              <!-- Modal Footer -->  
            </form>   
        </ng-container>
        <ng-container *ngIf="reason && reason.title && reason.title === 'lost-to-missing-facility-img'">
            <form [formGroup]="missingFacility" (ngSubmit)="saveLostAsDeal('MissingFacility')">

              <div class="p-4 surface-100">

                <div class="mb-5">
                  <h4 class="font-medium text-gray-700 text-xs mb-1 block uppercase">Select Facilities</h4>
                  <p class="mb-2 text-gray-600 text-sm">An automatic follow-up will be setup 2 weeks prior to the end date
                    provided.</p>
                  <p-chips formControlName="selectFacilities"  separator="," [addOnTab]="true" [addOnBlur]="true" inputStyleClass="text-sm" styleClass="w-full text-sm p-0" placeholder="Type and select your options…">
                  </p-chips>
                  <app-error-msg *ngIf="SelectFacilities.touched && SelectFacilities.invalid" [errors]="SelectFacilities.errors"
                  [fieldLabel]="'Select Facilities'"></app-error-msg> 
                </div>
      
                <div class="mb-2 md:mb-5">
                  <h4 class="font-medium text-gray-700 text-xs mb-1 block uppercase">Comments</h4>
                  <p class="mb-2 text-gray-600 text-sm">An automatic follow-up will be setup 2 weeks prior to the end date
                    provided.</p>
                  <textarea pInputTextarea class="w-full h-9rem text-sm" formControlName="missingFacilityComments"  placeholder="Add Comments..."></textarea>
                  <app-error-msg *ngIf="MissingFacilityComments.touched && MissingFacilityComments.invalid" [errors]="MissingFacilityComments.errors"
                  [fieldLabel]="'Comments'"></app-error-msg> 
                </div>
      
              </div>
      
              <!-- Modal Footer -->
              <div class="lg:px-4 px-2 pt-3 pb-1 border-top-1 border-gray-400">
                <div class="grid align-items-center justify-content-between">
                  <div class="col-4">
                    <p-button label="Cancel" styleClass="p-button-link text-sm"  (click)="closeAllDialogs()"></p-button>
                  </div>
                  <div class="col-8 text-right">
                    <button type="submit" class="text-sm" pButton pRipple label="Save"  [disabled]="!missingFacility.valid"></button>
                  </div>
                </div>
              </div>
              <!-- Modal Footer -->
            </form>
        </ng-container>
        <ng-container *ngIf="reason && reason.title && reason.title === 'lost-to-other-reasons-img'">
            <form [formGroup]="otherReasons" (ngSubmit)="saveLostAsDeal('OtherReasons')">

           
            <div class="p-4 surface-100">

                <div class="mb-5">
                  <h4 class="font-medium text-gray-700 text-xs mb-1 block uppercase">Reason</h4>
                  <p class="mb-2 text-gray-600 text-sm">An automatic follow-up will be setup 2 weeks prior to the end date
                    provided.</p>
      
                    <p-dropdown [options]="reasons" formControlName="reason"  optionLabel="name" filterBy="name" styleClass="w-full text-sm" placeholder="Select reason...">
                            <ng-template pTemplate="selectedItem">
                              <div class="text-sm" *ngIf="Reason">
                                {{Reason.value.name | uppercase}}
                              </div>
                            </ng-template>
                            <ng-template let-reason pTemplate="item">
                              <div class="text-sm">{{reason.name | uppercase}}</div>
                            </ng-template>
                          </p-dropdown>
                          <app-error-msg *ngIf="Reason.touched && Reason.invalid" [errors]="Reason.errors"
                          [fieldLabel]="'Reason'"></app-error-msg> 
                </div>
      
                <div class="mb-2 md:mb-5">
                  <h4 class="font-medium text-gray-700 text-xs mb-1 block uppercase">Comments</h4>
                  <p class="mb-2 text-gray-600 text-sm">An automatic follow-up will be setup 2 weeks prior to the end date
                    provided.</p>
                  <textarea pInputTextarea  formControlName="otherReasonsComments"  class="w-full h-9rem text-sm" placeholder="Add Comments..."></textarea>
                  <app-error-msg *ngIf="OtherReasonsComments.touched && OtherReasonsComments.invalid" [errors]="OtherReasonsComments.errors"
                  [fieldLabel]="'Comments'"></app-error-msg> 
                </div>
      
              </div>
      
              <!-- Modal Footer -->
              <div class="lg:px-4 px-2 pt-3 pb-1 border-top-1 border-gray-400">
                <div class="grid align-items-center justify-content-between">
                  <div class="col-4">
                    <p-button label="Cancel" styleClass="p-button-link text-sm"  (click)="closeAllDialogs()"></p-button>
                  </div>
                  <div class="col-8 text-right">
                    <button type="submit" class="text-sm" pButton pRipple label="Save" [disabled]="!otherReasons.valid"></button>
                  </div>
                </div>
              </div>
              <!-- Modal Footer -->
               </form>
        </ng-container>
        <ng-container *ngIf="reason && reason.title && reason.title === 'lost-to-no-contactable-img'">
            <form [formGroup]="notContactable" (ngSubmit)="saveLostAsDeal('NotContactable')">
            <div class="p-4 surface-100">

                <div class="mb-2 md:mb-5">
                  <h4 class="font-medium text-gray-700 text-xs mb-1 block uppercase">Comments</h4>
                  <p class="mb-2 text-gray-600 text-sm">Lorem Ipsum is simply dummy text of the printing and typesetting
                    industry.</p>
                  <textarea pInputTextarea formControlName="notContactableComments" class="w-full h-9rem text-sm" placeholder="Add Comments..."></textarea>
                  <app-error-msg *ngIf="NotContactableComments.touched && NotContactableComments.invalid" [errors]="NotContactableComments.errors"
                  [fieldLabel]="'Comments'"></app-error-msg> 
                </div>
      
              </div>
      
              <!-- Modal Footer -->
              <div class="lg:px-4 px-2 pt-3 pb-1 border-top-1 border-gray-400">
                <div class="grid align-items-center justify-content-between">
                  <div class="col-4">
                    <p-button label="Cancel" styleClass="p-button-link text-sm" (click)="closeAllDialogs()"
                    ></p-button>
                  </div>
                  <div class="col-8 text-right">
                    <button type="submit" class="text-sm" pButton pRipple label="Save"  [disabled]="!notContactable.valid"
                      ></button>
                  </div>
                </div>
              </div>
              <!-- Modal Footer -->
              </form>
        </ng-container>
    </div>
      <!-- Left Side -->

      <!-- Right Side -->
      <app-lost-deal-banner [title]="reason.title" class="p-2 align-items-center justify-content-center w-4 hidden lg:flex"></app-lost-deal-banner>
      <!-- Right Side -->

    </div>
    <!-- Content -->

  </ng-template>

</p-dialog>
<p-toast></p-toast>
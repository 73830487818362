import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  NgModule,
  Input,
  EventEmitter,
  Output,
  ElementRef,
  ViewChild,
} from '@angular/core';
import {
  ReactiveFormsModule,
  FormsModule,
  FormGroup,
  FormBuilder,
} from '@angular/forms';
import { PaymentService } from '@fitness-force/api';
import { AppErrors } from '@fitness-force/errors';
import { FactoryHttpLoader } from '@fitness-force/shared';
import {
  TranslateModule,
  TranslateLoader,
  TranslateService,
} from '@ngx-translate/core';
import { AvatarModule } from 'primeng/avatar';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { DividerModule } from 'primeng/divider';
import {InputTextareaModule} from 'primeng/inputtextarea';
import { DropdownModule } from 'primeng/dropdown';
import { RippleModule } from 'primeng/ripple';
import { StyleClassModule } from 'primeng/styleclass';
import { TableModule } from 'primeng/table';
import { TagModule } from 'primeng/tag';
import { TimelineModule } from 'primeng/timeline';
import { ToastModule } from 'primeng/toast';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { MessageService } from 'primeng/api';
import { voidInvoiceRequest } from '@fitness-force/models';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'payment-details',
  templateUrl: './payment-details.component.html',
  styleUrls: ['./payment-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PaymentDetailsComponent implements OnInit {

  @Input('showPayment') showPaymentPopup: boolean = false;
  @Input('paymentDetails') paymentDetails: any;
  @Input('clientId') clientId: number = 0;
  @Input('tenantId') tenantId: number = 0;

  @Output('closePayment') closePayment: EventEmitter<boolean> =new EventEmitter();
  


  showVoidPaymentModal: boolean = false;
  voidPaymentForm: FormGroup = new FormGroup({});
  pdfSrc= new BehaviorSubject<string>('');

  constructor(
    private paymentService: PaymentService,
    private translate: TranslateService,
    private messageService: MessageService,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.GetPaymentPdf();
  }

  ngAfterViewInit() {
    this.voidPaymentForm = this.fb.group({
      void_reason: [''],
    });
  }



  GetPaymentPdf() {
    this.paymentService.getPaymentPDF(this.clientId, this.paymentDetails.id)
      .subscribe({
        next: (data: any) => {
          this.pdfSrc.next(data);
        },
        error: (error: AppErrors) => {
          this.ShowToastErrors(error);
        },
      });
  }

  printPaymentPDF() {
    window.open(this.pdfSrc.value, '_blank');
  }

  downloadPaymentPDF() {
    var file = new Blob([this.pdfSrc.value], { type: 'application/pdf' });
    var fileURL = URL.createObjectURL(file);
    var a = document.createElement('a');
    a.href = fileURL;
    a.download = this.pdfSrc.value;
    document.body.appendChild(a);
    a.click();
  }

  SendPaymentEmail() {
    this.paymentService.SendEmail(this.clientId, this.paymentDetails.id)
      .subscribe({
        next: (data: any) => {
          this.translate.get('TRANSACTION.MSG.PAYMENT_MAIL_MSG')
          .subscribe((translation) => {
            this.messageService.add({
              severity: 'success',
              summary: 'Success',
              detail: translation,
            });
          });
        },
        error: (error: AppErrors) => {
          this.ShowToastErrors(error);
        },
      });
  }

  deletePayment(form: FormGroup) {
    let voidRequest: voidInvoiceRequest = {} as voidInvoiceRequest;
    voidRequest.void_reason = form.value.void_reason;
    this.paymentService.voidPayment(
        this.tenantId,
        this.clientId,
        this.paymentDetails.id,
        voidRequest
      )
      .subscribe({
        next: (data: any) => {
          this.translate
            .get('TRANSACTION.MSG.PAYMENT_VOID_MSG')
            .subscribe((translation) => {
              this.messageService.add({
                severity: 'success',
                summary: 'Success',
                detail: translation,
              });
            });
            this.showVoidPaymentModal= false;
        },
        error: (error: AppErrors) => {
          this.ShowToastErrors(error);
        },
      });
  }

  closePaymentPopup()
  {
    this.showPaymentPopup= false;
    this.closePayment.emit(false);
  }

  ShowToastErrors(error: any) {
    if (error.originalError.error.length > 0) {
      error.originalError.error.forEach((e: any) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: e.errorMessage,
        });
      });
    } else {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: error.originalError.error.errorMessage,
      });
    }
  }

}

@NgModule({
  declarations: [PaymentDetailsComponent],
  imports: [
    CommonModule,
    DividerModule,
    TimelineModule,
    ToastModule,
    TableModule,
    ReactiveFormsModule,
    FormsModule,
    ButtonModule,
    RippleModule,
    DropdownModule,
    AvatarModule,
    TagModule,
    StyleClassModule,
    DialogModule,
    InputTextareaModule,
    PdfViewerModule,
    OverlayPanelModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: FactoryHttpLoader,
        deps: [HttpClient],
      },
    })],
  exports: [PaymentDetailsComponent]
})
export class PaymentDetailsComponentModule {
}

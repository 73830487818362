export interface StaffDiscountCalculationRequest {
    client_id: number;
    subscription_bill_cycle_day: number;
    subscription_start_date: string;
    issue_date: string;
    charging_today: StaffDiscountChargingTodayRequest;
    charging_recurring: StaffDiscountChargeRecurring;
}

export interface StaffDiscountChargeRecurring {
    service_recurring: StaffDiscountServiceRecurringRequest;
}

export interface StaffDiscountChargingTodayRequest {
    service_first_charge: StaffDiscountServiceFirstChargeRequest;
    service_non_recurring: StaffDiscountServiceNonRecurringRequest;
    signup_charges: StaffDiscountSignUpChargeRequest[];
}

export interface StaffDiscountServiceFirstChargeRequest {
    discount_amt_in_minor_currency: number;
}

export interface StaffDiscountSignUpChargeRequest {
    id: number;
    discount_amt_in_minor_currency: number;
}

export interface StaffDiscountServiceNonRecurringRequest {
    discount_amt_in_minor_currency: number;
}

export interface StaffDiscountServiceRecurringRequest {
    discount_amt_in_minor_currency: number;
}
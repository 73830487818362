import { NgModule, Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'DayAgo',
  pure: true
})
export class DayAgoPipe implements PipeTransform {


  // convert date to local date with time zone passed as string ...
  // i.e
  // date = convertTimezoneToLocal(date, timezone, true);
  convertTimezoneToLocal(date: Date, timezone: string, reverse: boolean): Date {
    const reverseValue = reverse ? -1 : 1;
    const dateTimezoneOffset = date.getTimezoneOffset();
    const timezoneOffset = this.timezoneToOffset(timezone, dateTimezoneOffset);
    return this.addDateMinutes(date, reverseValue * (timezoneOffset - dateTimezoneOffset));
  }

  addDateMinutes(date: Date, minutes: number) {
    date = new Date(date.getTime());
    date.setMinutes(date.getMinutes() + minutes);
    return date;
  }


  // Convert sting time zone to offset.
  timezoneToOffset(timezone: string, fallback: number): number {
    // Support: IE 9-11 only, Edge 13-15+
    // IE/Edge do not "understand" colon (`:`) in timezone
    timezone = timezone.replace(/:/g, '');
    const requestedTimezoneOffset = Date.parse('Jan 01, 1970 00:00:00 ' + timezone) / 60000;
    return isNaN(requestedTimezoneOffset) ? fallback : requestedTimezoneOffset;
  }




  transform(value: any, args?: any): any {
    if (value) {
      // Create date object without timezone added..
      let newDate= new Date(value);

      // -1 to Reverse.
      // add timezone value to date.
      newDate= this.addDateMinutes(newDate,-1*newDate.getTimezoneOffset())
        const seconds = Math.floor((+new Date() - +newDate) / 1000);
        if (seconds < 29) // less than 30 seconds ago will show as 'Just now'
            return 'Just now';
        const intervals: { [key: string]: number } = {
            'year': 31536000,
            'month': 2592000,
            'week': 604800,
            'day': 86400,
            'hour': 3600,
            'minute': 60,
            'second': 1
        };
        let counter;
        for (const i in intervals) {
            counter = Math.floor(seconds / intervals[i]);
            if (counter > 0)
                if (counter === 1) {
                    return counter + ' ' + i + ' ago'; // singular (1 day ago)
                } else {
                    return counter + ' ' + i + 's ago'; // plural (2 days ago)
                }
        }
    }
    return value;
}


}
export function getCutomDate(daysCount:any, stDate:any){
  const currentDate = new Date();
  const startDate = new Date(stDate)
  const newTargetDate = new Date(startDate.getTime() + (daysCount * 24 * 60 * 60 * 1000));
  const last = new Date(newTargetDate.getTime() - newTargetDate.getTime());
  return `${last.getDate()}-${last.getMonth()+1}-${last.getFullYear()}`
}
@NgModule({
  declarations: [DayAgoPipe],
  imports: [],
  exports: [DayAgoPipe],
})
export class DayAgoPipeModule {}

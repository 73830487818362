import { Status } from "@fitness-force/enum";

export interface ListServiceTypeResponse {
    items: ServiceTypeResponse[];
}

export interface ServiceTypeResponse {
    id: number;
    company_id: number;
    tenant_id: number;
    name_primary_language: string;
    name_secondary_language: string;
    status: Status;
    icon: string;
    created_by: number;
    created_date: string;
    updated_by: number;
    updated_date: string;
}
export interface CreateClassRequest {
    class_type_id: number;
    start_date_time: Date | string;
    comments: string;
    room_id: number;
    sub_type_id: number;
    max_capacity: number;
    max_waitlist: number;
    is_virtual: boolean;
    virtual_url: string;
    coach_id: number;
    is_dropin_allowed: boolean;
    place_id: string;
    latitude: number;
    longitude: number;
}

export interface CreateRecurringClassRequest extends CreateClassRequest {
    start_date?: string;
    start_time?: string;
    recurring_rule?: string;
}

export interface UpdateClassRequest {
    is_dropin_allowed: boolean;
    comments: string;
    room_id: number;
    max_capacity: number;
    max_waitlist: number;
    is_virtual: boolean;
    virtual_url: string;
    place_id: string;
    latitude: number;
    longitude: number;
}


export interface SubstituteCoachInClassRequest{
    coach_id:number;
}

export interface RescheduleClassRequest{
    start_datetime:Date | string;
}

export interface CancelClassRequest{
    cancellation_reason:string;
}

export interface RescheduleRecurringClassRequest {
    start_date: string;
    start_time: string;
    recurring_rule: string;
}
import { Component, OnInit, ChangeDetectionStrategy, NgModule, Input } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TaskCategoryService } from '@fitness-force/api';
import { ListTaskCategoryResponse, TaskCategoryResponse, TENANTSINTERFACE } from '@fitness-force/models';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'apps/ff-frontend/src/environments/environment';
import { DropdownModule } from 'primeng/dropdown';
import { BehaviorSubject, Observable } from 'rxjs';
import { CommonModule } from '@angular/common';
import { Store } from '@ngrx/store';
import { TENANTS_INFO } from 'apps/ff-frontend/src/app/state/app.state';

@Component({
  selector: 'task-category',
  templateUrl: './task-category-dropdown.component.html',
  styleUrls: ['./task-category-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TaskCategoryDropdownComponent implements OnInit {
  @Input() formGroup: FormGroup = new FormGroup({});
  @Input() controlName: FormControl = new FormControl();
  @Input('placeHolder') placeHolder: string = 'Select';
  @Input('styleClass') styleClass: string = '-ml-2 border-none surface-50 w-full text-smtext-sm';

  taskCategory$ = new BehaviorSubject<TaskCategoryResponse[]>([]);

  constructor(private store: Store, private taskCategoryService: TaskCategoryService) { }

  GetTaskCategory(tenantId: number): void {
    this.taskCategoryService.GetTaskCategory(tenantId).subscribe((data: ListTaskCategoryResponse) => {
      let odata = data.items.filter((o:TaskCategoryResponse)=>{
        return String(o.status).toLowerCase() == 'active'});
      this.taskCategory$.next(odata);
    });
  }

  ngOnInit(): void {
    this.store.select(TENANTS_INFO).subscribe((tenant: TENANTSINTERFACE) => {
      if (tenant.selected_tenant && tenant.selected_tenant.id > 0) {
        this.GetTaskCategory(tenant.selected_tenant.id);
      }
    });
  }

  get TaskCategory(): any {
    return this.controlName?.value;
  }

}

@NgModule({
  declarations: [TaskCategoryDropdownComponent],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    DropdownModule,
  ],
  exports: [TaskCategoryDropdownComponent]
})
export class TaskCategoryDropdownComponentModule {
}

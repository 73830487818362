import { PayLinkSteps } from "@fitness-force/enum";

export interface PayLinkStepOption {
    name: PayLinkSteps;
    isProcessed: boolean;
    data: any;
    redirectPath: string;
    isCurrentPage: boolean
}

export interface PayLinkStepOptionPayload {
    steps: any;
}
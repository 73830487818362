/*=================================================================================================================
File Name: appinit.service.ts
Description: This service is responsible for
Note: Carefully to while doing modification.

Created By: Sangram, Creation Date: 07th May 2021, Modified By: Sangram, Modification Date: 07th May 2021
===================================================================================================================*/

import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { map, catchError, shareReplay } from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';
import { AppErrors, NotFoundError } from '@fitness-force/errors';
import { CONSTANTS_DATA, CONST_URL_SEGEMENTS } from '@fitness-force/constants';
import { CyptoSevice } from '../crypto.service';

@Injectable({
  providedIn: 'root',
})
export class AppInitService {
  getSubDomain: any;
  getStoredDomain: any;
  isResponse = '';
  constructor(
    private http: HttpClient,
    @Inject(DOCUMENT) private document: Document,
    private router: Router,
    private cryp: CyptoSevice
  ) // private store: Store<AppState>
  {}

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  getCompanyInformation(subdomain: string, APP_URL: string): Observable<any> {
    return this.http
      .get(
        APP_URL +
          CONST_URL_SEGEMENTS.INIT.GET_COMPANY_SUBDOMAIN +window.location.hostname,
        {
          headers: this.httpOptions.headers,
        }
      )
      .pipe(
        map((res: any) => {
          if (res) {
            return res;
          } else {
            return false;
          }
        }),
        shareReplay(1),
        catchError((error: Response) => {
          if (error.status === 404) {
            return throwError(() => new NotFoundError(error));
          }
          return throwError(() => new AppErrors(error));
        })
      );
  }

  getBlogData(): Observable<any> {
    return this.http
      .get(CONST_URL_SEGEMENTS.BLOG_API, {
        headers: new HttpHeaders()
          .set('Content-Type', 'text/xml')
          .append('Access-Control-Allow-Methods', 'GET')
          .append('Access-Control-Allow-Origin', '*')
          .append(
            'Access-Control-Allow-Headers',
            'Access-Control-Allow-Headers, Access-Control-Allow-Origin, Access-Control-Request-Method'
          ),
        responseType: 'text',
      })
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError((error: Response) => {
          if (error.status === 404) {
            return throwError(() => new NotFoundError(error));
          }
          return throwError(() => new AppErrors(error));
        })
      );
  }

  parseLanguage(lang: string) {
    return CONSTANTS_DATA.ALL_LANGUAGES.find((l) => l.LANG_CODE === lang);
  }
}


<!-- Header -->
<div class="p-3 md:px-4 flex align-items-center justify-content-between border-bottom-1 border-gray-300 -mx-3 -mt-3">
  <h2 class="mb-0 text-base md:text-lg font-normal">Upload Appointment Image</h2>
  <i class="pi pi-times cursor-pointer text-gray-500 ml-3"  (click)="ref.close()"></i>
</div>
<!-- Header -->

<!-- Content -->

<div class="p-4 flex -mx-3">
  <div class="w-12 md:w-8">

    <image-cropper
    class="max-h-1rem  max-w-1rem m-auto"
      *ngIf="imageFile"
      [imageFile]="imageFile"
      [maintainAspectRatio]="true"
      [containWithinAspectRatio]="containWithinAspectRatio"
      [aspectRatio]="4 / 3"
      [resizeToWidth]="256"
      [cropperMinWidth]="128"
      [onlyScaleDown]="true"
      [roundCropper]="false"
      [canvasRotation]="canvasRotation"
      [transform]="transform"
      [alignImage]="'left'"
      [style.display]="showCropper ? null : 'none'"
      format="png"
      (imageCropped)="imageCropped($event)"
      (imageLoaded)="imageLoaded()"
      (cropperReady)="cropperReady($event)"
      (loadImageFailed)="loadImageFailed()"
    ></image-cropper>
  
  </div>
  <div class="md:w-4 hidden md:block">
    <h5 class="text-sm mb-3">Preview</h5>
    <img
      [src]="croppedImage"
      [style.border]="croppedImage ? '1px solid var(--gray-300)' : 'none'"
    />
  </div>
</div>

<!-- Content -->


<!-- Modal Footer -->
<div class="p-3 border-top-1 border-gray-300 -mx-3 -mb-3">
  <div class="flex align-items-center justify-content-between">
    <div class="w-6">
      <p-button (click)="ref.close()" label="Cancel" styleClass="p-button-link text-sm"></p-button>
    </div>
    <div class="w-6 text-right">
      <button class="text-sm" (click)="doneCropping()" pButton pRipple label="Save"></button>
    </div>
  </div>
</div>






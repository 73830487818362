import { OpportunityBoardType, OpportunityStageForNonMember, Status } from "@fitness-force/enum";

export interface OpportunityStageMasterResponse {
    id: number;
    board_type: OpportunityBoardType;
    company_id: number;
    tenant_id: number;
    name_primary_language: string;
    name_secondary_language: string;
    order: number;
    stage_master_key: OpportunityStageForNonMember,
    tour_guard: boolean;
    trial_guard: boolean;
    status: Status;
    created_by: number;
    created_date: string;
    updated_by: number;
    updated_date: string;
}

export interface ListOpportunityStageMasterResponse {
    items: OpportunityStageMasterResponse[];
}
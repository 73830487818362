import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { AuthSevices } from './auth.service';
import { CyptoSevice } from './crypto.service';
import { TenantService } from './tenant.service';
import { ClientService } from './client/client.service';
import { TaskCallOutcomeService } from './CRM/taskoutcome.service';
import { ServiceTypeService } from './serviceType/service-type.service';
import { AdminsettingsService } from './adminsetting.service';


@NgModule({
  imports: [CommonModule],
  providers: [
    AuthSevices,
    CyptoSevice,
    TenantService,
    ClientService,
    TaskCallOutcomeService,
    ServiceTypeService,
    AdminsettingsService,
    DatePipe
  ]
})
export class ApiModule { }

import { PaymentStatus, PayModeLevel1 } from "@fitness-force/enum";
import { Invoice } from "./invoices";

export interface Payments {
    id: number;
    log_id: number;
    company_id: number;
    tenant_id: number;
    paid_by_client_id: number;
    paid_for_client_id: number;
    amount_in_minor_currency: number;
    is_online: boolean;
    paymode_level1: PayModeLevel1;
    payment_mode_level2_id: string;
    payment_mode_master_id: number;
    number: number;
    number_prefix: string;
    status: PaymentStatus;
    void_reason: string;
    bulk_uuid: string;
    is_number_generated: boolean;
    created_by: number;
    created_date: Date;
    updated_by: number | null;
    updated_date: Date | null;
    invoices: Invoice[];
    invoiceNumbers:string[]
}
<!-- Invoice Modal  -->
<p-dialog [(visible)]="showInvoicePopup" (onHide)="closeInvoicePopup()" [resizable]="false" appendTo="body" [modal]="true"
  [breakpoints]="{'767px': '100vw'}" [style]="{width: '820px'}" styleClass="no-header-modal">

  <ng-template pTemplate="header" styleClass="hidden"></ng-template>
  <ng-template pTemplate="content" styleClass="p-0">

    <!-- Header -->
    <div class="px-4 py-3 flex align-items-center justify-content-between border-bottom-1 border-gray-300">

      <h2 class="mb-0 text-base md:text-lg font-normal">{{'INVOICE.INVOICE_TTTLE' | translate}}<strong>
          {{invoiceDetails.number_prefix}}</strong></h2>

      <div class="flex align-items-center justify-content-between">
        <div class="flex align-items-center mr-3">
          <span class="border-2 border-gray-400 border-round p-1 text-gray-600 w-2rem h-2rem flex align-items-center justify-content-center cursor-pointer mr-1"
           (click)="printInvoice()">
            <i class="pi pi-print"></i>
          </span>
          <span class="border-2 border-gray-400 border-round p-1 text-gray-600 w-2rem h-2rem flex align-items-center justify-content-center cursor-pointer mr-1"
           (click)="SendInvoiceEmail()">
            <i class="pi pi-envelope"></i>
          </span>
          <span class="border-2 border-gray-400 border-round p-1 text-gray-600 w-2rem h-2rem flex align-items-center justify-content-center cursor-pointer mr-1"
          (click)="downloadInvoice()">
            <i class="pi pi-download"></i>
          </span>
          <span class="border-2 border-gray-400 border-round p-1 text-gray-600 w-2rem h-2rem flex align-items-center justify-content-center cursor-pointer mr-1"
            (click)="moreInfo.toggle($event)">
            <i class="pi pi-ellipsis-v"></i>
          </span>

          <p-overlayPanel #moreInfo styleClass="overflow-hidden ml-9 text-sm w-11rem">
            <ng-template pTemplate>
              <ul class="list-none -m-4 px-0 py-1 text-xs text-gray-600">
                <li class="cursor-pointer hover:bg-gray-300 py-2 px-3 flex align-items-center" (click)="showVoidInvoiceModal = true">
                  <i class="pi pi-ban text-gray-500 mr-2">
                  </i>{{'ADMIN.COMMON.VOID' | translate}}
                </li>
              </ul>
            </ng-template>
          </p-overlayPanel>

        </div>
        <div class="pi pi-times text-gray-500 cursor-pointer" (click)="closeInvoicePopup()"></div>
      </div>
    </div>
    <!-- Header -->

    <!-- Content -->
    <div class="surface-100 p-3">
      <!-- <br><br><br><br><br><br><br><br><br>
      <div class="text-center"> PDF will be here</div>
      <br><br><br><br><br><br><br><br><br> -->
      <pdf-viewer [src]="pdfSrc | async" [render-text]="true" [original-size]="false" style="width: 100%; height: 500px"></pdf-viewer>
    </div>
    <!-- Content -->

  </ng-template>

</p-dialog>
<!-- Invoice Modal -->

 <!-- Void Invoice Modal -->
 <p-dialog [(visible)]="showVoidInvoiceModal" [resizable]="false" appendTo="body" [modal]="true"
 [breakpoints]="{'767px': '100vw'}" [style]="{width: '630px'}" styleClass="no-header-modal">

 <ng-template pTemplate="header" styleClass="hidden"></ng-template>
 <ng-template pTemplate="content" styleClass="p-0">

   <!-- Content -->
   <form [formGroup]="voidInvoiceForm" >
   <div class="relative text-center p-4">

     <div class="pi pi-times opacity-50 absolute right-0 top-0 mt-3 mr-3 md:mt-4 md:mr-4 cursor-pointer"
       (click)="showVoidInvoiceModal = false"></div>

     <img width="160" class="block mx-auto" src="/assets/img/void-invoice-img.svg" alt="Image" />

     <h4 class="text-base md:text-lg my-3 font-bold">{{'INVOICE.INVOICE_DEL_CONFI'| translate}} <br> {{invoiceDetails.number_prefix}} ?</h4>
     <p class="text-gray-600 md:w-26rem mx-auto mb-0">{{'INVOICE.INVOICE_DEL_INFO'| translate}}</p>

   </div>
   <!-- Content -->

   <!-- Content -->
   <div class="surface-100 p-4">
     <label class="font-medium text-gray-700 text-xs mb-2 block uppercase">{{'INVOICE.REASON'| translate}}</label>
     <textarea pInputTextarea formControlName="void_reason" class="text-sm w-full h-5rem"></textarea>
   </div>
   <!-- Content -->

   <!-- Modal Footer -->
   <div class="flex align-items-center justify-content-between px-4 py-3 border-top-1 border-gray-300">
     <button pButton pRipple type="button" [label]="'common.BUTTONS.CANCEL-BUTTON-TEXT' | translate" class="p-button-secondary p-button-text text-sm"
       (click)="showVoidInvoiceModal = false"></button>
     <button pButton pRipple type="button" [label]="'INVOICE.VOID_LABEL' | translate" class="p-button-danger text-sm"
       (click)="deleteInvoice(voidInvoiceForm)"></button>
   </div>
   <!-- Modal Footer -->
 </form>

 </ng-template>

</p-dialog>
<!-- Void Invoice Modal -->

<p-toast position="top-right"></p-toast>

<p-dialog [(visible)]="showErrorPopup" [resizable]="false" appendTo="body" [modal]="true"
  [breakpoints]="{'767px': '100vw'}" [style]="{width: '470px'}" styleClass="no-header-modal">
  <ng-template pTemplate="header" styleClass="hidden"></ng-template>
  <ng-template pTemplate="content" styleClass="p-0">
    <!-- Content -->
    <div class="relative text-center px-5 pt-5 pb-3">

      <div class="pi pi-times opacity-50 absolute right-0 top-0 mt-3 mr-3 md:mt-4 md:mr-4 cursor-pointer"
        (click)="setHidePopup()"></div>

      <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-alert-triangle w-5rem h-5rem"
        width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="#dc3545" fill="none" stroke-linecap="round"
        stroke-linejoin="round">
        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
        <path d="M12 9v2m0 4v.01"></path>
        <path d="M5 19h14a2 2 0 0 0 1.84 -2.75l-7.1 -12.25a2 2 0 0 0 -3.5 0l-7.1 12.25a2 2 0 0 0 1.75 2.75">
        </path>
      </svg>

    <!-- <h4 class="text-lg mb-2 my-5">Are you sure?</h4>
    <p class="text-gray-600 mb-3">If you terminate this member, he will not be able to:</p> -->
    <ul class="list-none pl-0 ml-2 mb-5 text-gray-600 text-left line-height-4" *ngFor="let error of validationError">
        <li class="my-2 relative pl-5">
          <i class="pi pi-arrow-right absolute left-0 mt-2"></i>{{error}}</li>
    </ul>

    <button pButton pRipple type="button" label="Cancel" class="p-button-secondary p-button-text mt-2 w-full"
      (click)="setHidePopup()"></button>
  </div>
  <!-- Content -->
</ng-template>
</p-dialog>

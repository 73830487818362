import { CallOutcome, TourOutcome } from "@fitness-force/enum";

export interface TaskCommonAttributes {
    id: number;
    company_id: number;
    tenant_id: number;
}

export interface CreateLogCallNotesRequest {
    transaction_notes: string;
    transaction_log_date: string;
}

export interface CreateFollowupTaskByDateRequest {
    scheduled_datetime: string | null;
}

export interface CreateCallOutcomeRequest extends TaskCommonAttributes {
    outcome: CallOutcome;
    mark_as_complete: boolean;
    log_call: CreateLogCallNotesRequest;
    book_tour: CreateTourRequest;
    book_trial: CreateFollowupTaskByDateRequest;
    reschedule_task: CreateFollowupTaskByDateRequest;
}

export interface CreateTourOutcomeRequest {
    tour_outcome: TourOutcome;
    log_tour: CreateLogTourNotesRequest;
    book_trial: CreateFollowupTaskByDateRequest | null;
    reschedule_task: CreateFollowupTaskByDateRequest;
    mark_as_complete: boolean;
}
export interface CreateLogTourNotesRequest {
    transaction_notes: string;
    staff_id: number;
    transaction_log_date: string
}


export interface CreateTourRequest {
    assigned_to_staff_id: number;
    scheduled_datetime: string | null;
}

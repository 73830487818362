import { Component, OnInit, ChangeDetectionStrategy, NgModule, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DialogModule } from 'primeng/dialog';
import { StyleClassModule } from 'primeng/styleclass';
import { InputTextModule } from 'primeng/inputtext';
import { CommonModule } from '@angular/common';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { SkeletonModule } from 'primeng/skeleton';
import { DividerModule } from 'primeng/divider';
import { CalendarModule } from 'primeng/calendar';

@Component({
  selector: 'digital-signature',
  templateUrl: './digital-signature.component.html',
  styleUrls: ['./digital-signature.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DigitalSignatureComponent implements OnInit {
  @Input('visible') signHereModal: boolean = false;
  @Output('signatureEmitter') hideDialog = new EventEmitter<string>();

  signature: string = "";
  selectDrawActive: boolean = true;
  signaturePad: any;

  fg: FormGroup = new FormGroup({});

  constructor(
    private fb: FormBuilder,
  ) {

  }

  ngOnInit(): void {
    this.setFormGroup();
    this.boostrapSignaturePad();
  }

  setFormGroup(): void {
    this.fg = this.fb.group({
      fullName: new FormControl('', []),
      initials: new FormControl('', []),
    });
  }

  //#region getter
  get FullName(): string {
    return this.fg.controls.fullName.value as string;
  }

  get Initials(): string {
    return this.fg.controls.initials.value as string;
  }
  //#endregion getter

  public boostrapSignaturePad() {
    let timeOut = setTimeout(() => {
      let canvas: any = document.getElementById('signature-pad');
      if (canvas) {
        let signaturePad = (window as any).SignaturePad;
        (window as any).onresize = this.resizeCanvas(canvas);
        this.signaturePad = new signaturePad(canvas, { backgroundColor: 'rgb(255, 255, 255)' });
      }
      clearTimeout(timeOut);
    }, 100);

    console.log('boostrapSignaturePad');
  }

  resizeCanvas(canvas: any) {
    let ratio = Math.max(window.devicePixelRatio || 1, 1);
    canvas.width = canvas.offsetWidth * ratio;
    canvas.height = canvas.offsetHeight * ratio;
    canvas.getContext("2d").scale(ratio, ratio);
  }

  clearSignatuore(): void {
    this.signaturePad.clear();
  }

  saveSignature(): void {
    let signature = this.signaturePad.toDataURL('image/png');
    if (signature) {
      this.signature = signature;
      this.closeDialog();
    }
  }

  closeDialog() {
    this.signHereModal = false;
    this.hideDialog.emit(this.signature);
  }
}

@NgModule({
  declarations: [DigitalSignatureComponent],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    ButtonModule,
    CommonModule,

    CheckboxModule,
    SkeletonModule,
    DividerModule,
    ButtonModule,
    StyleClassModule,
    CalendarModule,
    DialogModule,
    InputTextModule,
  ],
  exports: [DigitalSignatureComponent]
})
export class DigitalSignatureComponentModule {
}

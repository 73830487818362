import { ClientService } from '@fitness-force/api';
import { BehaviorSubject } from 'rxjs';
import { FormsModule, ReactiveFormsModule, FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { Component, OnInit, ChangeDetectionStrategy, NgModule, Input, EventEmitter, Output, ViewChild, ElementRef } from '@angular/core';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { AvatarModule } from 'primeng/avatar';
import { ButtonModule } from 'primeng/button';
import { RippleModule } from 'primeng/ripple';
import { StyleClassModule } from 'primeng/styleclass';
import { BlockUIModule} from 'primeng/blockui';
import { InputTextModule } from 'primeng/inputtext';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { FactoryHttpLoader } from '@fitness-force/shared';
import { HttpClient } from '@angular/common/http';
import { BadRequestError, ErrorMsgComponentModule, NotFoundError, UnauthorizedError } from '@fitness-force/errors';
import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { ClientChannel, ClientGender, EnquirySource, Gender } from '@fitness-force/enum';

@Component({
  selector: 'edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.scss'],
  providers: [MessageService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditProfileComponent implements OnInit {
  
  @Input() editProfileModal: boolean | null = false;
  @Input() clientData$ = new BehaviorSubject(null)
  @Output() closeEditProfileDialog =  new EventEmitter()
  @ViewChild("uploadClientImage") uploadClientImage: ElementRef;
  

  editProfileFormGroup: FormGroup =  new FormGroup({})
  channelSource:string = EnquirySource.phone
  promotionPreferenceMap = new Map()
 
  selectedGender: { name: string };
  uploadedImageSrc = null
  allClientData:any
  clientInputData$ = new BehaviorSubject(null)
  EnumGender = Object.entries(ClientGender).filter(key => typeof key[1] != 'number').map(([key, value]) => ({ key, value }));
  channelCollection: any[] = [
    { channel: ClientChannel[ClientChannel.Online], source: ['Facebook', 'Website', 'Instagram', 'Class Pass', 'Gym Pass'] },
    { channel: ClientChannel[ClientChannel.WalkIn], source: ['Hoarding', 'Referral', 'Passing By'] },
    { channel: ClientChannel[ClientChannel.Phone], source: ['Hoarding', 'Website', 'Referral', 'Passing By'] },
    { channel: ClientChannel[ClientChannel.DataEntry], source: ['Pop-up Store', 'Referral'] },
  ];
  constructor(private fb: FormBuilder,public translate: TranslateService, private clientService: ClientService,  private messageService: MessageService,) { }

  ngOnInit(): void {
    this.componentInit()
  }
  componentInit(){
    this.editProfileFormGroup = this.fb.group({
      mobileNumber: ['', [Validators.required]],
      fullName: ['', [Validators.required]],
      gender: ['', [Validators.required]],
      emailId: ['', [Validators.required, Validators.email]],
      channel: ['', [Validators.required]],
      source: ['', [Validators.required]],
      referedBy: ['', []],
      promotionPreferance: ['', [Validators.required]],
      clientProfile: ['', [Validators.required]]
    })
   
    this.addProfileData();

    this.editProfileFormGroup.controls.channel.valueChanges.subscribe((mode: any) => {
      if (mode && mode.channel) {
        this.editProfileFormGroup.controls.source.setValidators([Validators.required]);
      }
      else {
        this.editProfileFormGroup.controls.source.clearValidators();
      }
      this.editProfileFormGroup.controls.source.updateValueAndValidity();
    });
  }
  get MobileNumber(): FormControl{
    return this.editProfileFormGroup.get('mobileNumber') as FormControl;
  }
  get FullName(): FormControl{
    return this.editProfileFormGroup.get('fullName') as FormControl;
  }
  get Gender(): FormControl{
    return this.editProfileFormGroup.get('gender') as FormControl;
  }
  get EmailId(): FormControl{
    return this.editProfileFormGroup.get('emailId') as FormControl;
  }
  get Channel(): FormControl{
    return this.editProfileFormGroup.get('channel') as FormControl;
  }
  get SourceControl(): FormControl { return this.editProfileFormGroup.controls.source as FormControl; }

  get ReferedBy(): FormControl{
    return this.editProfileFormGroup.get('referedBy') as FormControl;
  }

  get PromotionPreferance(): FormControl{
    return this.editProfileFormGroup.get('promotionPreferance') as FormControl;
  }

  get ClientProfile(): FormControl{
    return this.editProfileFormGroup.get('clientProfile') as FormControl;
  }

  get Source(): string[] {
    return this.Channel.value.source;
  }

  set Channel(value: any) {
    this.Channel.setValue(value);
  }

  get DisableSource(): boolean {
    return !(this.Source && this.Source.length > 0);
  }

  GetSelectedGender(): ClientGender {
    let selectedGender: ClientGender = ClientGender.PreferNotToRespond;
    let gender = this.Gender.value;
    switch (String(gender.value).toLowerCase()) {
      case "male": selectedGender = ClientGender.Male; break;
      case "female": selectedGender = ClientGender.Female; break;
      case "nonbinaryornonconforming": selectedGender = ClientGender.NonBinaryOrNonConforming; break;
      case "prefernottorespond": selectedGender = ClientGender.PreferNotToRespond; break;
      case "transgender": selectedGender = ClientGender.Transgender; break;
      default: console.log('wrong gender selected'); break;
    }
    return selectedGender;
  }

  addProfileData(){
    this.clientData$.subscribe(
      (client:any)=>{
      this.allClientData =  client
      this.MobileNumber.setValue(client.mobile_number)
      this.FullName.setValue(client.full_name)
      this.Gender.setValue(this.EnumGender.find(g => g.value == client.gender))
      this.EmailId.setValue(client.email_id)
      this.Channel.setValue(this.channelCollection.find(c=> c.channel.toLowerCase() == client.channel.toLowerCase())!);
      this.SourceControl.setValue(this.Channel.value.source.find((s: any)=> s.toLowerCase() == client.source.toLowerCase())!);
      this.channelSource =  client.channel ?  client.channel.toLowerCase() : '';
      this.promotionPreferenceMap.set(client.source.toLowerCase(),  client.source.toLowerCase())
      this.PromotionPreferance.setValue(this.getPromotionPreferenceMapData)
      this.ClientProfile.setValue(client.image)
    })
  }

  closeDialogEvent(){
    this.closeEditProfileDialog.emit(false)
  }

  updateProfile(){
    const updateProfileData = {
      "home_club_tenant_id": this.allClientData.home_club_tenant_id,
      "full_name": this.FullName.value,
      "mobile_country_code": this.allClientData.mobile_country_code,
      "mobile_number": this.MobileNumber.value,
      "email_id": this.EmailId.value,
      "date_of_birth": this.allClientData.date_of_birth,
      "gender": this.GetSelectedGender(),
      "location": this.allClientData.location,
      "second_mobile_country_code": this.allClientData.second_mobile_country_code,
      "second_mobile_number": this.allClientData.second_mobile_number,
      "preferred_language":this.allClientData.preferred_language,
      "place_id": this.allClientData.place_id,
      "address":this.allClientData.address,
      "city_code": this.allClientData.city_code,
      "state_code": this.allClientData.state_code,
      "country_code": this.allClientData.country_code,
      "address_pin_code": this.allClientData.address_pin_code,
      "emergency_contact_number": this.allClientData.emergency_contact_number,
      "emergency_contact_person": this.allClientData.emergency_contact_person,
      "source": this.SourceControl.value,
      "channel": this.Channel.value.channel,
      "heartrate_monitoring_id":  this.allClientData.heartrate_monitoring_id,
      "referred_by": this.allClientData.referred_by,
      "promotional_sms": this.hasPromotionalPreference('sms'),
      "promotional_email": this.hasPromotionalPreference('web'),
      "medical_alert": this.allClientData.medical_alert,
      "note": this.allClientData.note,
      "taxnumber":this.allClientData.taxnumber,
      "custom_fields": this.allClientData.custom_fields,
      "current_keyfob": this.allClientData.current_keyfob,
      "client_representative_id": this.allClientData.client_representative_id,
      "createdin_tenant_id":  this.allClientData.createdin_tenant_id,
      "place_id_request": this.allClientData.place_id_request,
      "migration_id": this.allClientData.migration_id,
      "image_base64": this.ClientProfile.value ? this.ClientProfile.value.substr(this.ClientProfile.value.indexOf(',') + 1) : null,
      "password": this.allClientData.password
    }
    this.clientService.updateClientById(this.allClientData.id, this.allClientData.home_club_tenant_id, updateProfileData).subscribe({
      next: (res)=>{
       this.closeDialogEvent()
      },
      error: (error)=>{
        if (error instanceof NotFoundError) {
          this.messageService.add({severity:'error', summary:'Error has occured!', detail:'Page Not Found'});
        } else if (error instanceof BadRequestError) {
          this.messageService.add({severity:'error', summary:'Error has occured!', detail: error.originalError.error[0].errorMessage});
        }
        else {
          this.messageService.add({severity:'error', summary:'Error has occured!', detail:'Something went wrong'});
        }
      }
     }
    )
  }

  promotionalPreferenceSelection(preference:string){
    if( this.promotionPreferenceMap.has(preference)){
      this.promotionPreferenceMap.delete(preference)
    } else {
      this.promotionPreferenceMap.set(preference, preference)
    }
    this.PromotionPreferance.setValue(this.getPromotionPreferenceMapData)
  }
  
  imageUpload(){
    const uploadedImageRef = this.uploadClientImage.nativeElement
    if (uploadedImageRef.files && uploadedImageRef.files[0]) {
      let fileToUpload = uploadedImageRef.files[0];
      let reader = new FileReader();
      reader.readAsDataURL(fileToUpload);
      reader.onloadend = (files:any) => {
        console.log('fileToUpload', fileToUpload,files.target.result)
        this.uploadedImageSrc = files.target.result
        this.ClientProfile.setValue(files.target.result)
      }
    }
  }
 
  hasPromotionalPreference(key:string){
    return this.promotionPreferenceMap.has(key)
  }
  get getPromotionPreferenceMapData(): Array<any> {
    return [...this.promotionPreferenceMap.values()];
  }

}

@NgModule({
  declarations: [EditProfileComponent],
  imports: [
    CommonModule,
    DropdownModule,
    FormsModule,
    ReactiveFormsModule,
    DialogModule,
    AvatarModule,
    ButtonModule,
    RippleModule,
    BlockUIModule,
    StyleClassModule,
    InputTextModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: FactoryHttpLoader,
        deps: [HttpClient],
      }
    }),
    ErrorMsgComponentModule,
    ToastModule
  ],
  exports: [EditProfileComponent]
})
export class EditProfileComponentModule {
}

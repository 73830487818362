import { Component, OnInit, ChangeDetectionStrategy, NgModule, Input, Output, EventEmitter, HostListener } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DialogModule } from 'primeng/dialog';
import { SelectButtonModule } from 'primeng/selectbutton';
import { InputMaskModule } from 'primeng/inputmask';
import { InputSwitchModule } from 'primeng/inputswitch';
import { DropdownModule } from 'primeng/dropdown';
import { MultiSelectModule } from 'primeng/multiselect';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { CalendarModule } from 'primeng/calendar';
import { ServiceTypeService } from 'libs/api/src/lib/serviceType/service-type.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AppErrors, ErrorMsgComponentModule, NotFoundError } from '@fitness-force/errors';
import { Country, CreateClientRequest, CreateOpportunityOnClientRequest, CreateProspectRequest, CreateTaskByOpportunityOnClientRequest, ListOpportunityStageMasterResponse, ListServiceTypeResponse, ListTaskCategoryResponse, OpportunityStageMasterResponse, ServiceTypeResponse, TaskCategoryResponse, TENANTSINTERFACE } from '@fitness-force/models';
import { ClientChannel, OpportunitiesType, OpportunityBoardType, OpportunityStageForNonMember, ClientGender, TaskAssignToType, TaskPriority } from '@fitness-force/enum';
import { BehaviorSubject, count, Observable, of } from 'rxjs';
import { Store } from '@ngrx/store';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { COMPANY_INFO, TENANTS_INFO } from 'apps/ff-frontend/src/app/state/app.state';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AuthSevices, CountryService, OpportunityStageService, ClientService, TaskCategoryService } from '@fitness-force/api';
import { environment } from 'apps/ff-frontend/src/environments/environment';
import { ErrorsComponentModule } from '@fitness-force/shared';
import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';

interface TaskOn {
  name: string,
  value: string,
  date: any
}

interface Option {
  name: string,
  value: number
}

interface Task_Type {
  name: string,
  icon: string
}

interface Preference {
  name: string,
  code: string,
  icon: string
}

@Component({
  selector: 'create-prospect',
  templateUrl: './create-prospect.component.html',
  styleUrls: ['./create-prospect.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [MessageService],
})
export class CreateProspectComponent implements OnInit {
  @Input('visible') showPopup: boolean = false;
  @Output() hidePopup: EventEmitter<boolean> = new EventEmitter();

  closeDialog: boolean = false;
  fg: FormGroup = new FormGroup({});


  //#region default variable
  getAccessTenantList$: Observable<any> = new Observable();
  companyInfoDetail$: Observable<any> = new Observable();
  selectedTenant: number = 0;
  state_code: string = '';
  city_code: string = '';
  country_code: string = '';
  mobile_country_code: string = '91';
  taskCategoryId: number = 0;
  stageMasterId: number = 0;
  EnumGender = Object.entries(ClientGender).filter(key => typeof key[1] != 'number').map(([key, value]) => ({ key, value }));
  //#endregion default variable

  error = new BehaviorSubject(null as (AppErrors | null));
  showErrorPopup = new BehaviorSubject(false);
  errorMessages = [] as any;
  HideErrorPopup(event: any) {
    this.showErrorPopup?.next(false);
    this.errorMessages = [];
  }


  //#region options

  interestedInCollection: Option[] = [];

  taskOnCollection: TaskOn[] = [
    {
      name: "Tomorrow", value: "tomorrow", date: (): Date => {
        return new Date((new Date()).setDate((new Date()).getDate() + 1));
      }
    },
    {
      name: "In 2 days", value: "in 2 buisness days", date: (): Date => {
        return new Date((new Date()).setDate((new Date()).getDate() + 2));
      }
    },
    {
      name: "In 3 days", value: "in 3 buisness days", date: (): Date => {
        return new Date((new Date()).setDate((new Date()).getDate() + 3));
      }
    },
    {
      name: "In 1 Week", value: "in 1 week", date: (): Date => {
        return new Date((new Date()).getTime() + 7 * 24 * 60 * 60 * 1000);
      }
    },
    {
      name: "In 2 Weeks", value: "in 2 week", date: (): Date => {
        return new Date((new Date()).getTime() + 14 * 24 * 60 * 60 * 1000);
      }
    },
    {
      name: "In 1 Month", value: "in 1 month", date: (): Date => {
        let now = new Date();
        let lastDateOfNewDate: number = new Date(now.getFullYear(), now.getMonth() + 2, 0).getDate();
        let date = (now.getDate() >= lastDateOfNewDate ? lastDateOfNewDate : now.getDate()) - 1;
        return new Date(now.getFullYear(), now.getMonth() + 1, date);
      }
    },
    {
      name: "In 2 Months", value: "in 2 months", date: (): Date => {
        let now = new Date();
        let lastDateOfNewDate: number = new Date(now.getFullYear(), now.getMonth() + 3, 0).getDate();
        let date = (now.getDate() >= lastDateOfNewDate ? lastDateOfNewDate : now.getDate()) - 1;
        return new Date(now.getFullYear(), now.getMonth() + 2, date);
      }
    },
    {
      name: "In 6 Months", value: "in 6 months", date: (): Date => {
        let now = new Date();
        let lastDateOfNewDate: number = new Date(now.getFullYear(), now.getMonth() + 7, 0).getDate();
        let date = (now.getDate() >= lastDateOfNewDate ? lastDateOfNewDate : now.getDate()) - 1;
        return new Date(now.getFullYear(), now.getMonth() + 6, date);
      }
    },
    {
      name: "Custom Date", value: "custom date", date: (): Date => {
        return this.TaskOnDate;
      }
    }
  ];

  taskTypeCollection: Task_Type[] = [
    // { name: 'Email', icon: 'pi pi-envelope' },
    // { name: 'Sms', icon: 'pi pi-comments' },
    { name: 'Call', icon: 'pi pi-phone' },
    { name: 'Task', icon: 'pi pi-users' },
    { name: 'Tour', icon: 'pi pi-map-marker' },
  ];

  promotionalPreferenceCollection: Preference[] = [
    { name: 'Email', code: 'Email', icon: 'pi pi-envelope' },
    { name: 'SMS', code: 'SMS', icon: 'pi pi-comments' },
    { name: 'Phone', code: 'Phone', icon: 'pi pi-mobile' }
  ];

  channelCollection: any[] = [
    { channel: ClientChannel[ClientChannel.Online], source: ['Facebook', 'Website', 'Instagram', 'Class Pass', 'Gym Pass'] },
    { channel: ClientChannel[ClientChannel.WalkIn], source: ['Hoarding', 'Referral', 'Passing By'] },
    { channel: ClientChannel[ClientChannel.Phone], source: ['Hoarding', 'Website', 'Referral', 'Passing By'] },
    { channel: ClientChannel[ClientChannel.DataEntry], source: ['Pop-up Store', 'Referral'] },
  ];

  //#endregion options

  constructor(
    private serviceType: ServiceTypeService,
    private taskCategory: TaskCategoryService,
    private opportunityStageService: OpportunityStageService,
    private fb: FormBuilder,
    private store: Store,
    public translate: TranslateService,
    public clientService: ClientService,
    public countryService: CountryService,
    public authService: AuthSevices,
    private messageService: MessageService,
  ) {
    console.log('constructor');
    this.componentInit();
    this.SetFormFields();
  }

  componentInit() {
    this.companyInfoDetail$ = this.store.select(COMPANY_INFO)
    this.companyInfoDetail$.subscribe((responseData) => {
      this.translate.setDefaultLang(responseData.selectedLang.LANG_CODE);
      this.translate.use(responseData.selectedLang.LANG_CODE);
    });

    this.getAccessTenantList$ = this.store.select(TENANTS_INFO);
    this.getAccessTenantList$.subscribe((tenant: TENANTSINTERFACE) => {
      this.selectedTenant = tenant.selected_tenant.id;
      this.state_code = tenant.selected_tenant.state;
      this.city_code = tenant.selected_tenant.city;
      this.GetServiceType(tenant.selected_tenant.id);
      this.GetCountry(tenant.selected_tenant.country);
      this.GetTaskCategory(tenant.selected_tenant.id);
      this.GetOpportunityStageMaster(tenant.selected_tenant.id);
    });
  }

  GetServiceType(tenantId: number): void {
    this.serviceType.GetServiceType(tenantId).subscribe({
      next: (serviceTypes: ListServiceTypeResponse) => {
        if(serviceTypes.items){
          serviceTypes.items.forEach((serviceType: ServiceTypeResponse) => {
            this.interestedInCollection.push({
              name: serviceType.name_primary_language,
              value: serviceType.id
            });
          });
        }
      },
      error: (error) => {
        console.log(JSON.stringify(error));
        if (error instanceof NotFoundError) {
        } else {
          // con('something went wrong');
        }
      }
    });
  }

  GetTaskCategory(tenantId: number): void {
    this.taskCategory.GetTaskCategory(tenantId).subscribe({
      next: (taskCategories: ListTaskCategoryResponse) => {
        // console.log('taskCategories', taskCategories);
        if (taskCategories && taskCategories.items && taskCategories.items.length > 0) {
          let taskCategoryResponse: TaskCategoryResponse[] = taskCategories.items.filter((obj: TaskCategoryResponse) => String(obj.name_primary_language).toLowerCase() === 'sales');
          if (taskCategoryResponse && taskCategoryResponse.length > 0)
            this.taskCategoryId = taskCategoryResponse[0].id;
          else {
            this.error.next(null);
            this.errorMessages.push("The task category of style sales is not configured in this center");
            this.showErrorPopup?.next(true);
          }
        }
        else {
          this.error.next(null);
          this.errorMessages.push("The task category is not configured in this center");
          this.showErrorPopup?.next(true);
        }
      },
      error: (error) => {
        this.error.next(error);
        this.errorMessages.push([]);
        this.showErrorPopup?.next(true);
      }
    });
  }

  GetOpportunityStageMaster(tenantId: number) {
    this.opportunityStageService.OpportunityStageMasterListService(tenantId, OpportunityBoardType.NewMemberBoard).subscribe({
      next: (obj: ListOpportunityStageMasterResponse) => {
        console.log('ListOpportunityStageMasterResponse -> ', obj);
        if (obj && obj.items && obj.items.length > 0) {
          let leadOpps = obj.items.find((o: OpportunityStageMasterResponse) => o.stage_master_key == OpportunityStageForNonMember.NewLeads);
          if (leadOpps && leadOpps.id > 0)
            this.stageMasterId = leadOpps.id;
          else {
            this.error.next(null);
            this.errorMessages.push("The opportunity stage master with New lead is not configured in this center");
            this.showErrorPopup?.next(true);
          }
        }
        else {
          this.error.next(null);
          this.errorMessages.push("The opportunity stage master is not configured in this center");
          this.showErrorPopup?.next(true);
        }
      },
      error: (error) => {
        this.error.next(error);
        this.errorMessages = [];
        this.showErrorPopup?.next(true);
      }
    });
  }

  GetCountry(countryCode: string): void {
    this.countryService.GetCountry(environment.BASE_URL).subscribe({
      next: (country: Country[]) => {
        // console.log('country', country);
        let countries: Country[] = country.filter((o: Country) => {
          return (
            String(o.three_letter_code).toLowerCase() == String(countryCode).toLowerCase()
            || String(o.two_letter_code).toLowerCase() == String(countryCode).toLowerCase()
          )
        });
        //let countries: Country[] = country.filter((o: Country) => { return String(o.name).toLowerCase() == String(countryName).toLowerCase() })
        if (countries.length > 0) {
          this.mobile_country_code = '+' + countries[0].dial_code;
          // this.mobile_country_code = '91'; //issue in api not returining dial code
          this.country_code = countries[0].three_letter_code;

        }
        else {
          // console.log('country not found error');
          throw new Error('Country Not Found');
        }
      },
      error: (error) => {
        console.log(JSON.stringify(error));
        if (error instanceof NotFoundError) {
          console.log('errrr', error.originalError.message)
        } else {
          alert('something went wrong');
        }
      }
    });
  }

  SetFormFields(): void {
    this.fg = this.fb.group({
      name: new FormControl('', [Validators.required]),
      emailId: new FormControl('', [Validators.required, Validators.email]),
      mobileNo: new FormControl('', [Validators.required]),
      channel: new FormControl('', [Validators.required]),
      source: new FormControl('', []),
      selectedGender: new FormControl('', [Validators.required]),
      promotionalPreference: new FormControl([] as Preference[], []),
      promotionalSms: new FormControl('', []),
      promotionalEmail: new FormControl('', []),
      promotionalPhone: new FormControl('', []),
      createTask: new FormControl(false, []),
      taskType: new FormControl(null, []),
      taskOn: new FormControl([], []),
      taskOnDate: new FormControl('', []),
      interestedIn: new FormControl([] as Option[], []),
    });

    this.fg.controls.channel.valueChanges.subscribe((mode: any) => {
      // console.log('channel', mode);
      if (mode && mode.channel) {
        this.fg.controls.source.setValidators([Validators.required]);
      }
      else {
        this.fg.controls.source.clearValidators();
      }

      this.fg.controls.source.updateValueAndValidity();
    });

    this.fg.controls.taskOn.valueChanges.subscribe((mode: any) => {
      console.log('taskOn', mode);
      if (mode && mode.value == 'custom date') {
        this.fg.controls.taskOnDate.setValidators([Validators.required]);
      }
      else {
        this.fg.controls.taskOnDate.clearValidators();
      }

      this.fg.controls.taskOnDate.updateValueAndValidity();
    });

    this.fg.controls.createTask.valueChanges.subscribe((mode: any) => {
      console.log('createTask', mode);
      if (mode) {
        this.fg.controls.taskType.setValidators([Validators.required]);
        this.fg.controls.taskOn.setValidators([Validators.required]);
        this.fg.controls.interestedIn.setValidators([Validators.required]);
      }
      else {
        this.fg.controls.taskType.clearValidators();
        this.fg.controls.taskOn.clearValidators();
        this.fg.controls.interestedIn.clearValidators();
      }

      this.fg.controls.taskType.updateValueAndValidity();
      this.fg.controls.taskOn.updateValueAndValidity();
      this.fg.controls.interestedIn.updateValueAndValidity();
    });

    this.fg.patchValue({
      selectedGender: this.EnumGender.find(obj => obj.value == 'PreferNotToRespond')
    });

  }

  ngOnInit(): void {
  }

  //#region form field getter

  get NameControl(): FormControl { return this.fg.controls.name as FormControl; }
  get EmailIdControl(): FormControl { return this.fg.controls.emailId as FormControl; }
  get MobileNoControl(): FormControl { return this.fg.controls.mobileNo as FormControl; }
  get ChannelControl(): FormControl { return this.fg.controls.channel as FormControl; }
  get SourceControl(): FormControl { return this.fg.controls.source as FormControl; }
  get PromotionalPreferenceControl(): FormControl { return this.fg.controls.promotionalPreference as FormControl; }
  get PromotionalSmsControl(): FormControl { return this.fg.controls.promotionalSms as FormControl; }
  get PromotionalEmailControl(): FormControl { return this.fg.controls.promotionalEmail as FormControl; }
  get PromotionalPhoneControl(): FormControl { return this.fg.controls.promotionalPhone as FormControl; }
  get CreateTaskControl(): FormControl { return this.fg.controls.createTask as FormControl; }
  get TaskTypeControl(): FormControl { return this.fg.controls.taskType as FormControl; }
  get TaskOnControl(): FormControl { return this.fg.controls.taskOn as FormControl; }
  get TaskOnDateControl(): FormControl { return this.fg.controls.taskOnDate as FormControl; }
  get InterestedInControl(): FormControl { return this.fg.controls.interestedIn as FormControl; }
  get SelectedGender(): FormControl { return this.fg.controls.selectedGender as FormControl; }
  //#endregion form field getter

  //#region form field setter

  set ChannelControl(value: any) {
    console.log('set ChannelControl : ', value);
    this.ChannelControl.setValue(value);
    console.log(value, this.ChannelControl.value);
  }

  set PromotionalPreferenceControl(value: any) {
    console.log('set PromotionalPreferenceControl', value);
    let preferences: Preference[] = this.PromotionalPreferenceControl.value;
    const index: number = preferences.indexOf(value);
    if (index !== -1)
      preferences.splice(index, 1);
    else
      preferences.push(value);

    this.PromotionalPreferenceControl.setValue(preferences);
    console.log(value, this.PromotionalPreferenceControl.value);
  }

  //#endregion form field setter

  get CreateTask(): boolean {
    return this.fg.controls.createTask.value as boolean;
  }

  get Source(): string[] {
    return this.ChannelControl.value.source;
  }

  get DisableSource(): boolean {
    return !(this.Source && this.Source.length > 0);
  }

  get TaskOnDate(): Date {
    return this.TaskOnDateControl.value;
  }

  IsPromotionOptionSelected(value: Preference): boolean {
    return this.fg.controls.promotionalPreference.value.filter((o: Preference) => o.name === value.name).length > 0
  }

  SetGender(gender: any): void {
    this.fg.controls.selectedGender.setValue(gender)
  }

  GetSelectedGender(): ClientGender {
    let selectedGender: ClientGender = ClientGender.PreferNotToRespond;
    let gender = this.SelectedGender.value;
    switch (String(gender.value).toLowerCase()) {
      case "male": selectedGender = ClientGender.Male; break;
      case "female": selectedGender = ClientGender.Female; break;
      case "nonbinaryornonconforming": selectedGender = ClientGender.NonBinaryOrNonConforming; break;
      case "prefernottorespond": selectedGender = ClientGender.PreferNotToRespond; break;
      case "transgender": selectedGender = ClientGender.Transgender; break;
      default: console.log('wrong gender selected'); break;
    }

    return selectedGender;
  }

  setHidePopup() {
    this.showPopup = false;
    this.fg.reset();
    this.hidePopup.emit(this.showPopup);
  }

  public  markAllControlsAsDirty(abstractControls: AbstractControl[]): void {
    abstractControls.forEach(abstractControl => {
      if (abstractControl instanceof FormControl) {
        (abstractControl as FormControl).markAsDirty({ onlySelf: true });
      } else if (abstractControl instanceof FormGroup) {
        this.markAllControlsAsDirty(Object.values((abstractControl as FormGroup).controls));
      } else if (abstractControl instanceof FormArray) {
        this.markAllControlsAsDirty((abstractControl as FormArray).controls);
      }
    });
  }

  CreateProspectSubmit(): void {
    this.fg.markAllAsTouched();
    this.markAllControlsAsDirty(Object.values(this.fg.controls));
    if (this.fg.valid) {

      let client: CreateProspectRequest = this.SetProspectObject();
      this.errorMessages = [];
      this.error.next(null);
      this.clientService.createProspect(environment.BASE_URL, client).subscribe({
        next: (res: any) => {
          console.log('res', res);
          if (res && res.id > 0) {
            this.translate.get('Shared.CREATE-PROSPECT.CLIENT_SUCCESS').subscribe((translation) => {
              this.messageService.add({ severity: 'success', summary: 'Success', detail: translation?.replace("{{client_id}}", res.id), });
            });
          }
          else {
            this.errorMessages.push('Something went wrong');
            this.showErrorPopup?.next(true);
            throw new Error('Error on creating prospect.');
          }
        },
        error: (error) => {
          console.log(error, 'errororor');
          this.error.next(error);
          this.showErrorPopup?.next(true);
        }
      });
    }
  }

  SetProspectObject(): CreateProspectRequest {
    let client: CreateProspectRequest = {} as CreateProspectRequest;

    client.Prospect = {
      full_name: this.NameControl.value,
      mobile_country_code: this.mobile_country_code, // get from country api
      mobile_number: this.MobileNoControl.value,
      email_id: this.EmailIdControl.value,
      date_of_birth: null,
      gender: this.GetSelectedGender(),
      location: "",
      second_mobile_country_code: null,
      second_mobile_number: null,
      preferred_language: null,
      place_id: null,
      address: null,
      state_code: this.state_code, // get from tenant selected
      city_code: this.city_code, // get from tenant selected
      country_code: this.country_code,  // get from tenant selected
      address_pin_code: 0,
      emergency_contact_number: null,
      emergency_contact_person: null,
      source: this.SourceControl.value,
      channel: this.ChannelControl.value.channel,
      heartrate_monitoring_id: null,
      referred_by: 0,
      promotional_sms: this.PromotionalPreferenceControl.value.filter((o: Preference) => { return String(o.code).toLowerCase() === 'sms' }).length > 0,
      promotional_email: this.PromotionalPreferenceControl.value.filter((o: Preference) => { return String(o.code).toLowerCase() === 'email' }).length > 0,
      promotional_phone: this.PromotionalPreferenceControl.value.filter((o: Preference) => { return String(o.code).toLowerCase() === 'phone' }).length > 0,
      medical_alert: null,
      note: null,
      taxnumber: null,
      custom_fields: null,
      current_keyfob: null,
      client_representative_id: this.authService.loggedInStaffId,
      createdin_tenant_id: this.selectedTenant,
      place_id_request: null,
      migration_id: null,
      image_base64: null,
      password: '',
    } as CreateClientRequest;

    if (this.CreateTask) {
      client.Opportunity = {
        name: client.Prospect.full_name,
        stage_master_id: this.stageMasterId,
        service_type_ids: this.InterestedInIds,
        value_in_minor_currency: 0,
        expected_closure_date: this.TaskOnControl.value.date(), // set null after venky API update
        assigned_to_staff_id: client.Prospect.client_representative_id,
        comments: '',
        hot_warm_cold: OpportunitiesType.Hot,
      } as CreateOpportunityOnClientRequest;


      client.Task = {
        assigned_to_type: TaskAssignToType.ClientRep,
        assigned_to_staff_id: null,
        type: this.TaskTypeControl.value.name,
        category_id: this.taskCategoryId,
        title: client.Prospect.full_name,
        notes: 'Task created at the time of prospect creation',
        priority: TaskPriority.High,
        call_id: null,
        email_id: null,
        sms_id: null,
        scheduled_date: this.TaskOnControl.value.date(),
      } as CreateTaskByOpportunityOnClientRequest;
    }
    console.log(client);
    return client;
  }

  get InterestedInIds(): number[] {
    if (this.InterestedInControl.value.length > 0) {
      return this.InterestedInControl.value.map((item: Option) => { return item['value'] });
    }

    return [];
  }

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    console.log(event);
    this.closeDialog = false;
    this.setHidePopup();
  }

}

function FactoryHttpLoader(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, 'assets/dist/i18n/', '.json');
}

@NgModule({
  declarations: [CreateProspectComponent],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    DialogModule,
    BrowserModule,
    BrowserAnimationsModule,
    SelectButtonModule,
    InputMaskModule,
    InputSwitchModule,
    DropdownModule,
    ToastModule,
    MultiSelectModule,
    ButtonModule,
    InputTextModule,
    CalendarModule,
    ErrorMsgComponentModule,
    ErrorsComponentModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: FactoryHttpLoader,
        deps: [HttpClient]
      }
    })
  ],
  exports: [CreateProspectComponent]
})
export class CreateProspectComponentModule {
}

<ng-container *ngIf="{allClientData: clientData$ | async} as $">
<p-dialog [(visible)]="assignKeyFobModal" [resizable]="false" appendTo="body" [modal]="true" [closeOnEscape]="true"
  (onHide)="closeDialogEvent()" [breakpoints]="{'991px': '100vw'}" [style]="{width: '700px'}"
  styleClass="no-header-modal">

  <ng-template pTemplate="header" styleClass="hidden"></ng-template>
  <ng-template pTemplate="content" styleClass="p-0">
    <!-- Header -->
    <div class="h-4rem md:pl-4 pl-3 pr-5 flex align-items-center relative border-bottom-1 border-gray-300">

      <div class="flex align-items-center">
        <h2 class="mb-0 text-base md:text-lg font-normal">
          {{'CLIENT_PROFILE.KYE_FOB.ASSIGN_KEY_FOB_FOR' | translate}} <strong>{{$.allClientData.full_name}} </strong>
          <span class="text-xs text-gray-600"> - {{$.allClientData.id}}</span></h2>
      </div>
      <div class="pi pi-times opacity-50 absolute right-0 top-0 mt-3 mr-3 md:mt-4 md:mr-4 cursor-pointer"
        (click)="closeDialogEvent()"></div>
    </div>
    <!-- Header -->


    <!-- Content -->
    <div class="flex">

      <!-- Left Side-->
      <div class="border-right-1 border-gray-300 lg:w-8 w-full surface-100 flex flex-column justify-content-between">
        <form [formGroup]="assignKeyFobFormGroup" (ngSubmit)="postAssignKeyFob()">
          <!-- Modal Content -->

          <div class="py-3 md:py-5 px-3 md:px-5">
            <div class="mb-4 text-sm" *ngIf="$.allClientData.current_keyfob">
              <label class="font-medium text-gray-700 text-xs mb-2 block uppercase">{{'CLIENT_PROFILE.KYE_FOB.CURRENT_KEYFOB_NUMBER' | translate}}</label>
              <strong class="text-sm">{{$.allClientData.current_keyfob}}</strong>
            </div>
            <label class="font-medium text-gray-700 text-xs mb-2 block uppercase"><span
                *ngIf="!$.allClientData.current_keyfob">{{'CLIENT_PROFILE.KYE_FOB.ENTER_KEY_FOB_FOR' | translate}}</span><span
                *ngIf="$.allClientData.current_keyfob">{{'CLIENT_PROFILE.KYE_FOB.CHANGE_KEY_FOB_NUMBER' | translate}}</span>
            </label>
            <input type="tel" class="w-full text-sm" pInputText formControlName="keyBobNumber" />
            <app-error-msg *ngIf="KeyBobNumber.touched && KeyBobNumber.invalid" [errors]="KeyBobNumber.errors"
              [fieldLabel]="'ASSIGN_KEY_FOB.ENTER_KEYFOB_NUMBER' | translate">
            </app-error-msg>
          </div>
          <!-- Modal Content -->

          <!-- Modal Footer -->
          <div class="lg:px-4 px-2 py-3 border-top-1 border-gray-400 bg-white">
            <div class="flex align-items-center justify-content-between">
              <div class="w-4">
                <p-button type="button" [label]="'CLIENT_PROFILE.KYE_FOB.CANCEL' | translate"
                  styleClass="p-button-link text-sm" (click)="closeDialogEvent()">
                </p-button>
              </div>
              <div class="w-8 text-right">
                <button type="submit" class="text-sm" [disabled]="!assignKeyFobFormGroup.valid" pButton pRipple
                  [label]="'CLIENT_PROFILE.KYE_FOB.ASSIGN' | translate"></button>
              </div>
            </div>
          </div>
          <!-- Modal Footer -->
        </form>
      </div>
      <!-- Left Side -->


      <!-- Right Side -->
      <div class="pl-3 w-4 hidden lg:flex align-items-center justify-content-center p-3 text-right">
        <img width="160" src="/assets/img/assign-keyFOB-img.svg" alt="Image" />
      </div>
      <!-- Right Side -->

    </div>
    <!-- Content -->


  </ng-template>

</p-dialog>
</ng-container>
<p-toast position="top-right"></p-toast>
export interface TENANT_MODEL {
  id: number;
  company_id: number;
  created_by: number;
  created_date: string;
  updated_by: number;
  updated_date: string;
  status: string;
  name_primary_language: string;
  name_secondary_language: string;
  primary_language: string;
  secondary_language: string;
  phone1: string;
  email_id: string;
  website: string;
  zone: string;
  place_id: string;
  latitude: number;
  longitude: number;
  city: string;
  state: string;
  country: string;
  zip_code: string;
  ip_setting: string;
  live_members: number;
  image: string;
  exclude_from_app: boolean;
}

export interface ALL_TENANTS {
  items: Array<TENANTSINTERFACE>;
}
export interface TENANTSINTERFACE {
  accessed_tenant: Array<TENANT_MODEL>;
  selected_tenant: TENANT_MODEL;
}

import { PlanType, PlanIntervalType, ServiceBehaviour, TaxType, PlanCategory, SignUpStatus, SignUpFinalStatus, TrackMode } from "@fitness-force/enum";

export interface SubscriptionPayload {
    orderSummary: SubscriptionSignUpOrderSummaryResponse;
    err: any;
}


export interface SubscriptionSignUpWithBillingJsonResponse {
    id: number;
    uuid: string;
    company_id: number;
    tenant_id: number;
    client_id: number;
    plan_id: number;
    client_subscription_id: number;
    status: SignUpStatus;
    final_status: SignUpFinalStatus;
    short_link: string;
    full_link: string;
    track_mode: TrackMode;
    link_expiry_date: string;
    link_last_opened: string;
    created_by: number;
    created_date: string;
    updated_by: number;
    updated_date: string;
    order_details: SubscriptionSignUpOrderSummaryResponse;
    task_id: number;
    opportunity_id: number;
    booking_id: number;
    appointment_id: number;
}

export interface SubscriptionSignUpOrderSummaryResponse {
    subscription_start_date: string;
    subscription_end_date: string;
    subscription_bill_cycle_day: number;
    subscription_category: PlanCategory;
    billing_interval_type: PlanIntervalType;
    billing_interval_value: number;
    minimum_contract_commitment: number;
    name_primary_language: string;
    name_secondary_language: string;
    description_primary_language: string;
    description_secondary_language: string;
    invoice_issue_date: string;
    invoice_billing_name: string;
    prorata_days_calculated: number;
    is_coupon_code_applied: boolean;
    coupon_code: string;
    charging_today: SubscriptionSignUpChargingToday;
    charging_recurring: SubscriptionSignupRecurringService;
}

export interface SubscriptionSignupRecurringService {
    services: SubscriptionSignupService[];
}

export interface SubscriptionSignUpChargingToday {
    service_first_charge: SubscriptionSignupServiceFirstCharge;
    service_non_recurring: SubscriptionSignupNonRecurringService;
    signup_charges: SubscriptionSignupCharges[];
}

export interface SubscriptionSignupServiceFirstCharge {
    service_start_date: string;
    service_end_date: string;
    service_prorata_days: number;
    services: SubscriptionSignupService[];
}

export interface SubscriptionSignupNonRecurringService {
    services: SubscriptionSignupService[];
}

export interface SubscriptionSignupCharges {
    chrgmst_id: number;
    chrgmst_chrg_category_id: number;
    chrgmst_name_pri_lang: string;
    chrgmst_name_sec_lang: string;
    chrgty_name_pri_lang: string;
    chrgty_name_sec_lang: string;
    chrgmst_net_cost_in_minor_curr: number;
    chrgmst_base_cost_in_minor_curr: number;
    chrgmst_discount_amount_in_minor_curr: number;
    chrgmst_tax_grp_id: number;
    chrgmst_migration_id: string;
    tax: SubscriptionSignupTax;
}

export interface SubscriptionSignupService {
    id: number;
    category_id: number;
    taxable_cost_in_minor_curr: number;
    base_cost_in_minor_curr: number;
    discount_amount_in_minor_curr: number;
    sessions: number;
    category_name_pri_lang: string;
    category_name_sec_lang: string;
    category_description_pri_lang: string;
    category_description_sec_lang: string;
    behaviour: ServiceBehaviour;
    tax_item_code: string;
    is_base_membership: boolean;
    require_base_membership: boolean;
    is_session_owner_required: boolean;
    category_icon: string;
    taxgrp_id: number;
    tax: SubscriptionSignupTax;
}

export interface SubscriptionSignupTax {
    taxgrp_id: number;
    taxgrp_name_pri_lang: string;
    taxgrp_name_sec_lang: string;
    tax_line_items: SubscriptionSignupTaxLineItem[];
}

export interface SubscriptionSignupTaxLineItem {
    id: number;
    amt_in_minor_curr: number;
    name_pri_lang: string;
    name_sec_lang: string;
    type: TaxType;
    rate: number;
}

export interface SubscriptionSignUpStatus {
    title: string;
    text: string;
    icon: string;
    step: number;
    status: string;
}

export interface StatusandSubscriptionDetails{
    client_subscription_id :number;
    status : SignUpStatus;
    client_id:number;
}
 
import { ClientGender, ClientStatus, ClientSubStatus, TrackMode } from "@fitness-force/enum";

export interface Client {
  clt_id: number;
  clt_createdin_tenant_id: number;
  clt_company_id: number;
  clt_full_name: string;
  clt_gender: ClientGender | null;
  clt_dateofbirth: string | null;
  clt_emailid: string;
  clt_image: string;
  clt_mobile_no: number;
  clt_mobile_country_code: string;
  clt_client_status: ClientStatus | null;
  clt_second_mobile_no: number | null;
  clt_second_mobile_country_code: string;
  clt_addr: string;
  clt_location: string;
  clt_country_code: string;
  clt_state_code: string;
  clt_city_code: string;
  clt_addr_pincode: number;
  clt_place_id: string;
  clt_placeid_request: string;
  clt_preferred_language: string;
  clt_emergency_contact_no: string;
  clt_emergency_contact_person: string;
  clt_medical_alert: string;
  clt_note: string;
  clt_outstanding_due: number;
  clt_source: string;
  clt_channel: string;
  clt_referred_by: number;
  clt_heartrate_monitoring_id: string;
  clt_dropout_score: number;
  clt_lifetime_value: number;
  clt_taxnumber: string;
  clt_bulk_insert_guid: string;
  clt_client_rep_id: number | null;
  clt_home_club_tenant_id: number;
  clt_latest_waiver_date: string;
  clt_latest_waiver_path: string;
  clt_password_hash: string;
  clt_checkin_status: boolean;
  clt_last_visited_date: string;
  clt_last_visited_centre: number;
  clt_is_erroneous: boolean;
  clt_is_mobile_verified: boolean;
  clt_is_email_verified: boolean;
  clt_is_blacklisted: boolean;
  clt_is_waiver: boolean;
  clt_promotional_sms: boolean;
  clt_promotional_email: boolean;
  clt_migration_id: string;
  clt_custom_fields: string;
  clt_current_keyfob: string;
  clt_is_irregular: boolean;
  clt_created_by: number;
  clt_updated_by: number;
  clt_created_date: string;
  clt_updated_date: string;
  clt_track_mode: TrackMode | null;
  clt_online_booking_stopped_until: string;
  clt_total_outstanding_in_minor_curr: number;
  clt_client_since: string;
  clt_app_installed: boolean;
  clt_sub_status: ClientSubStatus | null;
}


export interface clientInterface {
  id: number
  company_id: number;
  client_status: string,
  is_mobile_verified: boolean;
  is_email_verified: boolean;
  image: string | null;
  home_club_tenant_id: number;
  is_waiver: boolean;
  is_blacklisted: boolean;
  online_booking_stopped_until: string;
  created_by: number;
  created_date: string;
  updated_by: number;
  updated_date: string;
  full_name: string;
  mobile_country_code: string;
  mobile_no: number;
  email_id: string;
  date_of_birth: string;
  gender: string;
  location: string;
  client_representative_id: number;
  createdin_tenant_id: number;
  source: string;
  channel: string;
  total_outstanding_in_minor_currency: number;
  client_since: string;
  application_installed: boolean;
  lifetime_value: number;
  stage_master_id: number;
  opportunity_board_type: string
}
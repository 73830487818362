import { TaxType } from "@fitness-force/enum";

export interface PlanTaxGroup {
    name_primary_language: string;
    name_secondary_language: string;
    tax_line_items: PlanTaxLineItems[];
}

export interface PlanTaxLineItems {
    name_primary_language: string;
    name_secondary_language: string;
    type: TaxType;
    rate: number;
}
import { BOARD_MASTER_IDS, OpportunityBoardType, PayModeLevel1, PayModeLevel2, TaxType } from "@fitness-force/enum";
import { OverLappingSubscriptionSignUpResponse } from "@fitness-force/models";
import { getDayMilliseconds } from "@mobiscroll/angular/dist/js/core/util/datetime";

export interface PlanBreakUpCalculationRequest {
        client_id: number;
        issue_date: string;
        subscription_start_date: string;
        subscription_bill_cycle_day: number;
        is_coupon_code_applied: boolean;
        coupon_code: string;
    }

export interface PlanBreakUpCalculationResponse {
    overlapping_subscription: OverLappingSubscriptionSignUpResponse;
    plan_breakup_calculation: PlanBreakUpCalculation;
}

export interface PlanBreakUpCalculation {
    charging_today: ChargingToday;
    charging_recurring: RecurringBreakUp;
}

export interface ChargingToday {
    service_first_charge: ServiceFirstCharge;
    service_non_recurring: NonRecurringBreakUp;
    signup_charges: ChargeBreakUp[];
}

export interface ServiceFirstCharge {
    service_start_date: string;
    service_end_date: string;
    service_prorata_days: number;
    services: ServiceBreakUp[];
}

export interface ChargeBreakUp {
    id: number;
    name_primary_language:string;
    name_secondary_language:string;
    amount_in_minor_currency: number;
    discount_amt_in_minor_currency: number;
    taxable_cost_in_minor_currency: number;
    taxgrp_id: number;
    tax: TaxLineItemBreakUp[];
}

export interface RecurringBreakUp {
    services: ServiceBreakUp[];
}

export interface NonRecurringBreakUp {
    services: ServiceBreakUp[];
}

export interface ServiceBreakUp {
    id: number;
    category_id:number;
    base_cost_in_minor_currency: number;
    discount_amt_in_minor_currency: number;
    taxable_cost_in_minor_currency: number;
    taxgrp_id: number;
    tax: TaxLineItemBreakUp[];
}

export interface TaxLineItemBreakUp {
    id: number;
    type: TaxType;
    amt_in_minor_currency: number;
    rate: number;
}


export interface TaxAggregate {    
    type: TaxType;
    amt_in_minor_currency: number;
    rate: number;
}

//------------------------

export interface SignUpResponse {
    clientsignupuuId: string;
    clientsubscriptionuuId: string;
}

export interface SignUpRequest {
    plan_id: number;
    start_date: string;
    bill_cycle_day: number;
    sales_rep: number;
    issue_date: string;
    billing_name: string;
    current_keyfob: string;
    is_offline_payment: boolean;
    offline_payment: PaymentSignUp;
    is_coupon_code_applied: boolean;
    sigup_coupon_code: string;
    charging_today: ChargingTodaySignUp;
    charging_recurring: RecurringSignUp;
    crm: Crm;
    schedule: ScheduleSignUp;
}

export interface PaymentSignUp {
    paymode_level1: PayModeLevel1;
    paymode_level2_id: PayModeLevel2;
    amount_in_minor_currency: number;
    upi_provider: string;
    vpa_id: string;
    card_number: string;
    network_type: string;
    auth_code: string;
    exp_date: Date | null;
    bank_name: string;
    cheque_date: Date | null;
}

export interface ServiceFirstChargeSignUp {
    service_start_date: string;
    service_end_date: string;
    service_prorata_days: number;
    services: ServiceSignUp[];
}

export interface ChargeSignUp {
    id: number;
    amount_in_minor_currency: number;
    discount_amt_in_minor_currency: number;
    taxable_cost_in_minor_currency: number;
    taxgrp_id: number;
    tax: TaxLineItemSignUp[];
}

export interface RecurringSignUp {
    services: ServiceSignUp[];
}

export interface NonRecurringSignUp {
    services: ServiceSignUp[];
}

export interface ServiceSignUp {
    id: number;
    category_id: number;
    base_cost_in_minor_currency: number;
    discount_amt_in_minor_currency: number;
    taxable_cost_in_minor_currency: number;
    taxgrp_id: number;
    tax: TaxLineItemSignUp[];
}

export interface ChargingTodaySignUp {
    service_first_charge: ServiceFirstChargeSignUp;
    service_non_recurring: NonRecurringSignUp;
    signup_charges: ChargeSignUp[];
}

export interface RecurringSignUp {
    services: ServiceSignUp[];
}

export interface Crm {
    sigup_sts_tsk_id: number;
    sigup_sts_opp_id: number;
    sigup_sts_board_type: OpportunityBoardType;
}

export interface ScheduleSignUp {
    signup_sts_class_booking_id: number;
    signup_sts_appt_id: number;
}

export interface TaxLineItemSignUp {
    id: number;
    type: TaxType;
    amt_in_minor_currency: number;
    rate: number;
}



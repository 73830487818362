<ng-container *ngIf="{
  row: columnData | async
} as $">
<p-overlayPanel #member styleClass="overflow-hidden">
    <ng-template pTemplate>

      <!-- Dropdown Content -->
      <div class="bg-white -mx-4 -mt-4 flex align-items-start justify-content-between px-3 py-2" *ngIf="$.row">
        <div class="flex mt-2 w-10rem line-height-1">
          <span class="text-base font-medium text-800">{{$.row.full_name}}</span>
          <span class="text-xs text-600 ml-1 mt-1">{{$.row.id}}</span>
        </div>
        <div [innerHTML]="clientStatus($.row)"></div>

        <!-- <span
          class="bg-blue-50 px-2 py-1 font-bold border-round border-1 border-primary text-primary text-xxs">{{getClientType($.row)| titlecase}}</span> -->
      </div>
      <!-- Dropdown Content -->
      
      <!-- Dropdown Footer -->
      <div class="px-3 py-2 -mx-4 -mb-4 bg-gray-200 text-800 text-xs" *ngIf="$.row">
        <div class="flex align-items-center mb-2">
          <i class="pi pi-phone mr-2 text-gray-500"></i>
          {{$.row.mobile_country_code}}{{$.row.mobile_number}}
        </div>
        <div class="flex align-items-center mb-1">
          <i class="pi pi-envelope mr-2 text-gray-500"></i>
          <span class="break-word">{{$.row.email_id | slice:0:12}}</span>
        </div>
      </div>
      <!-- Dropdown Footer -->

    </ng-template>
  </p-overlayPanel>

  <div class="flex align-items-center" *ngIf="$.row">
    <span class="cursor-pointer flex align-items-center" (mouseenter)="member.show($event)" (mouseleave)="member.hide()"
      (click)="gotoClient($.row.id)">

      <p-avatar *ngIf="!$.row.image; else elseBlock" [label]="$.row.full_name | titlecase | shortName " shape="circle">
      </p-avatar>
      <ng-template #elseBlock>
        <p-avatar [image]="$.row.image" shape="circle"></p-avatar>
      </ng-template>

      <strong class="ml-2 text-primary">{{$.row.full_name | slice:0:12 }}</strong>
    </span>

    <!-- <span class="bg-blue-50 px-2 py-1 ml-3 border-round border-1 border-primary text-primary text-xxs">MEMBER</span> -->
    <div class="flex align-items-center justify-content-end text-sm ml-5">
      <!-- TODO: Change this static value when requirement freezes    -->
      <i *ngIf="$.row?.client_status=='Client'" class="pi pi-user text-gray-600 mr-2"></i> <span>{{getClientType($.row)|
        titlecase}}</span>
    </div>

  </div>
</ng-container>
export interface Category {
    id:number,
    settingid:number,
    name: string;
    description: string;
    icon: string;
    redirectUrl:string
}

export interface Settings {
    id:number,
    name: string;
    shortname: string;
    icon: string;
    categories: Category[];
}
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AppErrors, NotFoundError } from '@fitness-force/errors';
import { environment } from 'apps/ff-frontend/src/environments/environment';
import { map, catchError, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ChargeMasterService {
  constructor(private http: HttpClient, private router: Router) {}
  BASE_URL: string = environment.BASE_URL;
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  // Charge Master
  getChargeMasters(tenantId: number,allParams:Array<string>=[]) {
    let parsedParams = new HttpParams()
    if(allParams.length > 0){

     allParams.forEach((p: any) => {

       parsedParams = parsedParams.append(p.name, p.value);
     });
    }
    return this.http
      .get(
        `${environment.BASE_URL}/tenants/${tenantId}/charge_masters`,
        {headers: this.httpOptions.headers , params: parsedParams}
      )
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError((error: Response) => {
          if (error.status === 404) {
            return throwError(() => new NotFoundError(error));
          }
          return throwError(() => new AppErrors(error));
        })
      );
  }

  getSingleChargeMaster(tenantId: number, charge_master_id: number) {
    return this.http
      .get(
        `${environment.BASE_URL}/tenants/${tenantId}/charge_masters/${charge_master_id}`,
        this.httpOptions
      )
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError((error: Response) => {
          if (error.status === 404) {
            return throwError(() => new NotFoundError(error));
          }
          return throwError(() => new AppErrors(error));
        })
      );
  }

  createChargeMaster(tenantId: number, createChargeMasters: any) {
    return this.http
      .post(
        `${environment.BASE_URL}/tenants/${tenantId}/charge_masters`,
        createChargeMasters,
        this.httpOptions
      )
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError((error: Response) => {
          if (error.status === 404) {
            return throwError(() => new NotFoundError(error));
          }
          return throwError(() => new AppErrors(error));
        })
      );
  }

  updateChargeMaster(tenantId: number, charge_master_id: number, data: any) {
    return this.http
      .put(
        `${environment.BASE_URL}/tenants/${tenantId}/charge_masters/${charge_master_id}`,
        data,
        this.httpOptions
      )
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError((error: Response) => {
          if (error.status === 404) {
            return throwError(() => new NotFoundError(error));
          }
          return throwError(() => new AppErrors(error));
        })
      );
  }

  activeChargeMaster(tenantId: number, charge_master_id: number) {
    return this.http
      .post(
        `${environment.BASE_URL}/tenants/${tenantId}/charge_masters/${charge_master_id}/mark_as_active`,
        {},
        this.httpOptions
      )
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError((error: Response) => {
          if (error.status === 404) {
            return throwError(() => new NotFoundError(error));
          }
          return throwError(() => new AppErrors(error));
        })
      );
  }
  inActiveChargeMaster(tenantId: number, charge_master_id: number) {
    return this.http
      .post(
        `${environment.BASE_URL}/tenants/${tenantId}/charge_masters/${charge_master_id}/mark_as_inactive`,
        {},
        this.httpOptions
      )
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError((error: Response) => {
          if (error.status === 404) {
            return throwError(() => new NotFoundError(error));
          }
          return throwError(() => new AppErrors(error));
        })
      );
  }

  deleteChargeMaster(tenantId: number, charge_master_id: number) {
    return this.http
      .delete(
        `${environment.BASE_URL}/tenants/${tenantId}/charge_masters/${charge_master_id}`
      )
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError((error: Response) => {
          if (error.status === 404) {
            return throwError(() => new NotFoundError(error));
          }
          return throwError(() => new AppErrors(error));
        })
      );
  }

  // Charge master category
  getChargeCategoryMasters(tenantId: number) {
    return this.http
      .get(
        `${environment.BASE_URL}/tenants/${tenantId}/charge_category_master`,
        this.httpOptions
      )
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError((error: Response) => {
          if (error.status === 404) {
            return throwError(() => new NotFoundError(error));
          }
          return throwError(() => new AppErrors(error));
        })
      );
  }

  getSingleChargeCategoryMaster(
    tenantId: number,
    charge_master_category_id: number
  ) {
    return this.http
      .get(
        `${environment.BASE_URL}/tenants/${tenantId}/charge_category_master/${charge_master_category_id}`,
        this.httpOptions
      )
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError((error: Response) => {
          if (error.status === 404) {
            return throwError(() => new NotFoundError(error));
          }
          return throwError(() => new AppErrors(error));
        })
      );
  }

  createChargeCategoryMasters(
    tenantId: number,
    createChargeCategoryMaster: any
  ) {
    return this.http
      .post(
        `${environment.BASE_URL}/tenants/${tenantId}/charge_category_master`,
        createChargeCategoryMaster,
        this.httpOptions
      )
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError((error: Response) => {
          if (error.status === 404) {
            return throwError(() => new NotFoundError(error));
          }
          return throwError(() => new AppErrors(error));
        })
      );
  }

  updateChargeCategoryMaster(
    tenantId: number,
    createChargeCategoryMaster: number,
    data: any
  ) {
    return this.http
      .put(
        `${environment.BASE_URL}/tenants/${tenantId}/charge_category_master/${createChargeCategoryMaster}`,
        data,
        this.httpOptions
      )
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError((error: Response) => {
          if (error.status === 404) {
            return throwError(() => new NotFoundError(error));
          }
          return throwError(() => new AppErrors(error));
        })
      );
  }

  activeChargeCategoryMaster(
    tenantId: number,
    charge_category_master_id: number
  ) {
    return this.http
      .post(
        `${environment.BASE_URL}/tenants/${tenantId}/charge_category_master/${charge_category_master_id}/mark_as_active`,
        {},
        this.httpOptions
      )
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError((error: Response) => {
          if (error.status === 404) {
            return throwError(() => new NotFoundError(error));
          }
          return throwError(() => new AppErrors(error));
        })
      );
  }
  inActiveChargeCategoryMaster(
    tenantId: number,
    charge_category_master_id: number
  ) {
    return this.http
      .post(
        `${environment.BASE_URL}/tenants/${tenantId}/charge_category_master/${charge_category_master_id}/mark_as_inactive`,
        {},
        this.httpOptions
      )
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError((error: Response) => {
          if (error.status === 404) {
            return throwError(() => new NotFoundError(error));
          }
          return throwError(() => new AppErrors(error));
        })
      );
  }

  deleteChargeCategoryMaster(
    tenantId: number,
    charge_category_master_id: number
  ) {
    return this.http
      .delete(
        `${environment.BASE_URL}/tenants/${tenantId}/charge_category_master/${charge_category_master_id}`
      )
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError((error: Response) => {
          if (error.status === 404) {
            return throwError(() => new NotFoundError(error));
          }
          return throwError(() => new AppErrors(error));
        })
      );
  }
}

    export interface Appointment {
        id: number;
        company_id: number;
        tenant_id: number;
        type_id: number;
        subtype_id: number;
        client_id: number;
        status: string;
        start_datetime: string| Date;
        end_datetime: string| Date;
        type_name_primary_language: string;
        type_name_secondary_language: string;
        tenant_name_primary_language: string;
        tenant_name_secondary_language: string;
        recurring_template_id: number;
        recurring_rule:string;
        type_color: string;
        subtype_name_primary_language: string;
        subtype_name_secondary_language: string;
        subtype_description_primary_language: string;
        subtype_description_secondary_language: string;
        subtype_things_to_carry_primary_language: string;
        subtype_things_to_carry_secondary_language: string;
        behaviour: string;
        booking_opens_in_minutes: number;
        duration_in_minutes: number;
        is_virtual: boolean;
        virtual_url: string;
        tag_name_primary_language: string[];
        tag_name_secondary_language: string[];
        coach_id: number;
        coach_name: string;
        coach_description: string;
        coach_image_url: string;
        room_id:number;
        created_by: number;
        created_date: Date;
        updated_by: number;
        updated_date: Date;
        guid:string;
        type:string;
        clients:Clients[]
    }

    export interface Statistic {
        id: number;
        company_id: number;
        tenant_id: number;
        type_id: number;
        subtype_id: number;
        client_id: number;
        status: string;
        start_datetime: Date;
        end_datetime: Date;
        type_name_primary_language: string;
        type_name_secondary_language: string;
        tenant_name_primary_language: string;
        tenant_name_secondary_language: string;
        recurring_template_id: number;
        type_color: string;
        subtype_name_primary_language: string;
        subtype_name_secondary_language: string;
        subtype_description_primary_language: string;
        subtype_description_secondary_language: string;
        subtype_things_to_carry_primary_language: string;
        subtype_things_to_carry_secondary_language: string;
        behaviour: string;
        booking_opens_in_minutes: number;
        duration_in_minutes: number;
        is_virtual: boolean;
        virtual_url: string;
        tag_name_primary_language: string[];
        tag_name_secondary_language: string[];
        coach_id: number;
        coach_name: string;
        coach_description: string;
        coach_image_url: string;
        created_by: number;
        created_date: Date;
        updated_by: number;
        updated_date: Date;
        clients:Clients[]
    }

    export interface Clients {
        client_id: number,
        full_name: string,
        mobile_country_code: string,
        mobile_no: 0,
        emailid: string,
        image: string
    }

    export interface AppointmentListResponse {
        items: Appointment[];
        statistics: Statistic[];
        total_items: number;
        total_pages: number;
        current_page: number;
    }

    export interface ListAppointmentRequest {
        tenant_id:number;
        start_datetime: string;
        end_datetime: string;
    }

    export interface AppointmentById extends Appointment {
        subscription_id: number;
        membership_id: number;
        room_id: number;
        room_name_primary_language: string;
        room_name_secondary_language: string;
        is_recurring: boolean;
        is_payment_required: boolean;
        is_first_time_appt: boolean;
        is_comp_session: boolean;
        appt_comp_id: number;
        session_revenue: number;
        commission_mode: string;
        commission_flatfee_amount_in_minor_currency: number;
        commission_revenue_share_percentage: number;
        is_commission_processed: boolean;
        comments: string;
        minimum_booking_cancel_time: number;
        is_late_cancel: boolean;
        cancellation_reason: string;
        created_track_mode: string;
        status_updated_track_mode: string;
    }


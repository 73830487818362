<div class="min-h-screen flex surface-100 text-sm">

    <!-- Sidebar -->
    <app-sidebar></app-sidebar>
    <!-- Sidebar -->

  <!-- Site Content -->
  <div class="min-h-screen flex flex-column relative flex-auto overflow-x-hidden">

     <!-- Header -->
     <app-header [tenantList]="getAccessTenantList$ | async"
     class=""
     >
    </app-header>
   <!-- Header -->

    <!-- Body Content-->

    <form [formGroup]="createClassForm" (ngSubmit)="CreateClass(createClassForm)">
      <div class="pt-2 px-4 pb-8">

        <app-breadcrumb></app-breadcrumb>

        <div class="flex align-items-center justify-content-between">
          <h1 class="text-xl md:text-2xl text-900 font-normal mr-2 mb-0 line-height-1">{{'SCHEDULE.CREATECLASS.SCHEDULE_CLASS' | translate}} <i
              class="pi pi-question-circle text-xl text-400 cursor-pointer" pTooltip="This is a sample!"
              tooltipStyleClass="text-xs"></i>
          </h1>
  
          <button pButton type="button" icon="pi pi-plus" class="text-sm md:w-9rem flex-row-reverse" (click)="GoToClassType()">
            <span class="hidden md:inline ml-2">{{'SCHEDULE.CREATECLASS.ADDNEWCLASS' | translate}}</span>
          </button>
        </div>

        <p-divider styleClass="my-2"></p-divider>

        <!-- Card -->
        <div class="shadow-2 border-round bg-white text-sm">

          <div class="p-3">
            <h3 class="font-medium text-gray-700 text-xs mb-0 block uppercase">{{'SCHEDULE.CREATECLASS.SELECTCLASS' | translate}}</h3>
          </div>

          <!-- Class Selection for Desktop -->
          <div *ngIf="(classTypeList$ | async).length > 0; else noClassShow" class="hidden md:flex align-items-center flex-wrap px-3 py-2 border-bottom-1 border-gray-300">
            <ng-container *ngFor="let classType of (classTypeList$ | async);let i = index">
              <button pButton pRipple class="py-1 my-1 h-2rem text-sm border-2 mr-2 w-9rem" *ngIf="i <= classTypeToShow" (click)="SelectClassType(classType)"
              [ngClass]="selectedClassType == classType ? getSelectedColor(classType) : 'p-button-secondary bg-white border-gray-400 text-gray-600'"
               type="button">
               <span class="flex w-full align-items-center justify-content-between">{{classType.name_primary_language}}</span>
                <!-- <span class="pi pi-video ml-3"></span> -->
              </button>
           </ng-container>
            <div *ngIf="classTypeToShow < classTypeList$.value.length" class="cursor-pointer my-2 text-sm text-primary flex align-items-center md:ml-3"  
              (click)="classTypeToShow = classTypeToShow+5">...{{ classTypeList$.value.length - classTypeToShow | number}} {{'SCHEDULE.CREATEAPPOINTMENT.LOAD_MORE' | translate}}
               <i class="pi pi-arrow-down ml-1 text-gray-500 text-sm"></i>
            </div>
            <div *ngIf="classTypeToShow == classTypeList$.value.length" class="cursor-pointer my-2 text-sm text-primary flex align-items-center md:ml-3"  
              (click)="classTypeToShow = 5">{{'SCHEDULE.CREATEAPPOINTMENT.LOAD_LESS' | translate}}
              <i class="pi pi-arrow-down ml-1 text-gray-500 text-sm"></i>
            </div>

          </div>
          <ng-template #noClassShow>
            <div class="px-2 py-1 -mt-4 mx-2">
              <p-messages severity="warn">
                <ng-template pTemplate>
                  <div class="p-warn flex align-items-center w-full -m-1">
                    <i class="pi pi-exclamation-triangle p-warn text-xl"></i>
                    <div class="ml-2 line-height-3 text-xs">{{'SCHEDULE.CREATECLASS.NO_CLASS_INFO' | translate}}
                      <strong class="underline cursor-pointer" (click)="GoToClassType()">{{'SCHEDULE.CREATECLASS.ADD_CLASS' | translate}}
                      </strong>
                      &nbsp; {{'SCHEDULE.CREATECLASS.NO_CLASS_INFO1' | translate}}
                    </div>
                  </div>
                </ng-template>
              </p-messages>
            </div>
          </ng-template>
          <!-- Class Selection for Desktop -->

          <!-- Class Selection for Mobile -->
          <div class="px-3 md:hidden mt-3">
            <p-dropdown *ngIf="(classTypeList$ | async).length > 0"  [options]="classTypeList$ | async"  optionLabel="name_primary_language" filterBy="name_primary_language"
              styleClass="w-full text-sm" placeholder="{{'SCHEDULE.CREATEAPPOINTMENT.SELECTWITHDOTS' | translate}}"
              formControlName="classType" (onChange)="SelectClassType($event)">
              <ng-template pTemplate="selectedItem">
                <div *ngIf="selectedClassType" class="flex align-items-center text-sm">
                  <span class="text-{{selectedClassType.color}}">{{selectedClassType.name_primary_language}}</span>
                  <span *ngIf="selectedClassType.logo" class="{{selectedClassType.logo}} ml-3"></span>
                </div>
              </ng-template>
              <ng-template let-class pTemplate="item">
                <div class="flex align-items-center text-sm"> 
                  <span class="text-{{class.color}}">{{class.name_primary_language}}</span>
                  <span *ngIf="class.logo" class="{{class.logo}} ml-3"></span>
                </div>
              </ng-template>
            </p-dropdown>
          </div>
          <!-- Class Selection for Mobile -->

          <div class="p-3">
           <label class="font-medium text-gray-700 text-xs mb-2 block uppercase" *ngIf="showClssSubType | async">{{'SCHEDULE.VIEWAPPOINTMENT.SUBPURPOSE' | translate}}</label>

            <!-- For Desktop -->
            <div class="hidden md:flex p-buttonset flex-wrap" *ngIf="showClssSubType | async">
              <ng-container *ngFor="let subType of (classSubTypeList$ | async)">
                <button pButton pRipple [ngClass]="selectedClassSubType! && selectedClassSubType == subType ? 'text-sm border-right-1 border-white':'p-button-secondary text-sm border-right-1 border-white border-1'"
                  type="button" (click)="selectedClassSubType = subType" label="{{subType.name_primary_language}}">
                </button>
             </ng-container>
            </div>
            <!-- For Desktop -->

            <!-- For Mobile -->
            <p-dropdown [options]="classSubTypeList$ | async"  formControlName="classSubType" optionLabel="name_primary_language" filterBy="name_primary_language"
              styleClass="w-full text-sm md:hidden" placeholder="{{'SCHEDULE.CREATEAPPOINTMENT.SELECTWITHDOTS' | translate}}" *ngIf="showClssSubType | async">
              <ng-template pTemplate="selectedItem">
                <div *ngIf="selectedClassSubType" class="text-sm">{{selectedClassSubType.name_primary_language}}</div>
              </ng-template>
              <ng-template let-subClass pTemplate="item">
                <div class="text-sm">{{subClass.name_primary_language}}</div>
              </ng-template>
            </p-dropdown>
            <!-- For Mobile -->

          </div>

        </div>
        <!-- Card -->

        <!-- Card -->
        <div class="shadow-2 border-round bg-white text-sm mt-3">

          <div class="p-3 border-bottom-1 border-gray-300">
            <h3 class="text-sm font-normal mb-3">{{'SCHEDULE.CREATECLASS.REPEATINGCLSTIME' | translate}}</h3>
            <p class="mb-0 flex text-600 text-xs md:text-sm"><i class="pi pi-info-circle cursor-pointer mr-2"
                pTooltip="{{'SCHEDULE.CREATECLASS.TIMEINFO' | translate}}" tooltipStyleClass="text-xs" tooltipStyleClass="text-xs"></i>{{'SCHEDULE.CREATECLASS.TIMEINFO' | translate}}</p>
          </div>

          <div class="grid my-0 px-4 py-2">

            <div class="col-12 md:col-6 mb-3">
              <label class="font-medium text-gray-700 text-xs mb-2 block uppercase">{{'SCHEDULE.CREATECLASS.STARTTIMEINFO' | translate}}</label>
              <p-calendar [timeOnly]="true" icon="pi pi-clock" [showIcon]="true" formControlName="startTime" 
                placeholder="{{'SCHEDULE.VIEWCLASS.SELECTTIME' | translate}}" [hourFormat]="12" inputStyleClass="text-sm" 
                styleClass="w-full" [minDate]="minimumDate">
              </p-calendar>
                <app-error-msg
                *ngIf="StartTimeFormControl.touched && StartTimeFormControl.invalid"
                [errors]="StartTimeFormControl.errors"
                [fieldLabel]="'SCHEDULE.CREATEAPPOINTMENT.LBL_STARTTIME' | translate">
                </app-error-msg>
            </div>

            <div class="col-12 md:col-6 mb-3">
              <label class="font-medium text-gray-700 text-xs mb-2 block uppercase">{{'SCHEDULE.CREATECLASS.STARTDATEINFO' | translate}}</label>
              <p-calendar [showIcon]="true" formControlName="startDate" placeholder="{{'SCHEDULE.VIEWCLASS.SELECTDATE' | translate}}" 
                inputStyleClass="text-sm" [minDate]="minimumDate"
                styleClass="w-full"></p-calendar>
                <app-error-msg
                *ngIf="StartDateFormControl.touched && StartDateFormControl.invalid"
                [errors]="StartDateFormControl.errors"
                [fieldLabel]="'SCHEDULE.CREATEAPPOINTMENT.LBL_STARTDATE' | translate">
                </app-error-msg>
            </div>

            <div class="col-12 md:col-6 mb-3">
              <label class="font-medium text-gray-700 text-xs mb-2 block uppercase">{{'SCHEDULE.CREATECLASS.CLASSREPEATS' | translate}}</label>
              <div>
              <div *ngIf="!RRuleObject.RRuleText" class="p-input-icon-right w-full text-sm" (click)="showRRule=true">
                <div class="p-inputtext p-component p-element cursor-pointer bg-blue-50 w-full">{{'SCHEDULE.CREATEAPPOINTMENT.REPEAT' | translate}}</div>
              </div>
              <div *ngIf="RRuleObject.RRuleText" class="p-input-icon-right w-full text-sm cursor-pointer">
                <i class="pi pi-times text-gray-500" (click)="showRRule=false; RRuleObject.RRule =undefined;RRuleObject.RRuleText =undefined"></i>
                <div class="p-inputtext p-component p-element w-full" (click)="showRRule=true">{{RRuleObject.RRuleText}}</div>
              </div>
              </div>
            </div>

            <div class="col-12 md:col-6 mb-3">
              <label class="font-medium text-gray-700 text-xs mb-2 block uppercase">{{'SCHEDULE.CREATECLASS.CHOOSELOCATION' | translate}}</label>
              <ff-google-places  [competitorName]="PlaceNameControl" [placeHolderName]="'SCHEDULE.VIEWCLASS.SEARCH_LOCATION' | translate" (fetchPlaceInfo)="getPlaceDetails($event)"></ff-google-places>
            </div>

            <div class="col-12 mb-3">
              <label class="font-medium text-gray-700 text-xs mb-2 block uppercase">{{'SCHEDULE.CREATECLASS.SELECTTRAINER' | translate}}</label>
              <staff-selector [formGroup]="createClassForm" [controlName]="SelectedTrainerFormControl"
              [styleClass]="'w-full px-1 py-0 align-items-center'" [placeHolder]="'SCHEDULE.CREATEAPPOINTMENT.SELECT' | translate "
              [filterstaff]="filterStaffIds"> </staff-selector> 
              <app-error-msg
              *ngIf="SelectedTrainerFormControl.touched && SelectedTrainerFormControl.invalid"
              [errors]="SelectedTrainerFormControl.errors"
              [fieldLabel]="'SCHEDULE.CREATEAPPOINTMENT.LBL_TRAINER' | translate">
              </app-error-msg>

            </div>

            <div class="col-12 mb-3">
              <label class="font-medium text-gray-700 text-xs mb-2 block uppercase">{{'SCHEDULE.VIEWCLASS.CLASS_TYPE' | translate}}</label>
              <div class="p-buttonset">
                <button pButton pRipple icon="pi pi-users" label="{{'SCHEDULE.CREATEAPPOINTMENT.PHYSICAL' | translate}}" type="button" class="text-sm border-right-1 border-white" (click)="SetIsVirtual(false)"  [ngClass]="isVirtal == false ? '' : 'p-button-secondary' "></button>
                <button pButton pRipple icon="pi pi-video" label="{{'SCHEDULE.CREATEAPPOINTMENT.VIRTUAL' | translate}}" type="button" class="text-sm border-right-1 border-white border-1" (click)="SetIsVirtual(true)"  [ngClass]="isVirtal ? '' : 'p-button-secondary' "></button>
              </div>
            </div>

            <div class="col-12 mb-3">
              <div *ngIf="isVirtal;else physical">
               <input type="text" class="w-full text-sm" pInputText formControlName="virtualUrl" placeholder="{{'SCHEDULE.CREATEAPPOINTMENT.VIRTUALURL' | translate}}" />

               <app-error-msg
               *ngIf="VirtualUrlFormControl.touched && VirtualUrlFormControl.invalid"
               [errors]="VirtualUrlFormControl.errors"
               [fieldLabel]="'SCHEDULE.CREATEAPPOINTMENT.LBL_VIRTUALURL' | translate">
               </app-error-msg>

              </div>
              <ng-template #physical>
                <label class="font-medium text-gray-700 text-xs mb-2 block uppercase">{{'SCHEDULE.CREATECLASS.CHOOSEROOM' | translate}}</label>
                <p-dropdown [options]="roomList$ | async" styleClass="w-full" placeholder="{{'SCHEDULE.CREATEAPPOINTMENT.CHOOSEROOM' | translate}}"
                formControlName="selectedRoom" optionLabel="name_primary_language" filterBy="name_primary_language">
               <ng-template pTemplate="selectedItem">
                  <div *ngIf="GetSelectedRoomFormValue" class="text-sm">{{GetSelectedRoomFormValue.name_primary_language}}</div>
               </ng-template>
               <ng-template let-room pTemplate="item">
                  <div class="text-sm">{{room.name_primary_language}} </div>
               </ng-template>
               </p-dropdown>
               <app-error-msg
                  *ngIf="SelectedRoomFormControl.touched && SelectedRoomFormControl.invalid"
                  [errors]="SelectedRoomFormControl.errors"
                  [fieldLabel]="'SCHEDULE.CREATEAPPOINTMENT.LBL_ROOM' | translate">
                </app-error-msg>
              </ng-template>
            </div>

            <div class="col-12">
              <ul class="list-none md:flex p-0 m-0">
                <li class="flex align-items-center my-2 md:w-4">
                  <p-inputSwitch formControlName="isDropInAllowed" formControlName="isDropInAllowed"></p-inputSwitch> <span class="ml-3">{{'SCHEDULE.CREATECLASS.DROPINALLOWED' | translate}}</span>
                </li>
                <li class="flex my-2 md:w-4" [ngClass]="showMaxCapacityInput ? '' : 'align-items-center' ">
                  <p-inputSwitch (onChange)="toggleMaxCapacity($event.checked)"></p-inputSwitch>
                  <div class="ml-3">
                    <div>{{'SCHEDULE.CREATECLASS.LIMITCAPACITYINFO' | translate}}</div>
                    <p-inputNumber *ngIf="showMaxCapacityInput" formControlName="maxCapacity" mode="decimal" class="w-full md:w-12rem text-sm mt-2"
                      placeholder="{{'SCHEDULE.CREATECLASS.LIMIT_IN_NUMBER' | translate}}" [format]="false"></p-inputNumber>
                      <app-error-msg
                      *ngIf="MaxCapacityFormControl.touched && MaxCapacityFormControl.invalid"
                      [errors]="MaxCapacityFormControl.errors"
                      [fieldLabel]="'SCHEDULE.CREATECLASS.LBL_MAXCAPACITY' | translate">
                    </app-error-msg>
                  </div>
                </li>
                <li class="flex my-2 md:w-4" [ngClass]="showMaxWaitlistInput ? '' : 'align-items-center' ">
                  <p-inputSwitch (onChange)="toggleMaxWaitlist($event.checked)"></p-inputSwitch>
                  <div class="ml-3">
                    <div>{{'SCHEDULE.CREATECLASS.LIMITWAITLISTINFO' | translate}}</div>
                    <p-inputNumber *ngIf="showMaxWaitlistInput" mode="decimal"  formControlName="maxWaitlist" class="w-full md:w-12rem text-sm mt-2"
                      placeholder="{{'SCHEDULE.CREATECLASS.WAITLIST_IN_NUMBER' | translate}}" [format]="false"></p-inputNumber>
                      <app-error-msg
                      *ngIf="MaxWaitListFormControl.touched && MaxWaitListFormControl.invalid"
                      [errors]="MaxWaitListFormControl.errors"
                      [fieldLabel]="'SCHEDULE.CREATECLASS.LBL_MAXWAITLIST' | translate">
                    </app-error-msg>
                  </div>
                </li>
              </ul>
            </div>

            <div class="col-12">
              <label class="font-medium text-gray-700 text-xs mb-2 block uppercase">{{'SCHEDULE.CREATEAPPOINTMENT.COMMENT' | translate}}</label>
              <textarea placeholder="{{'SCHEDULE.CREATECLASS.ADDCOMMENTS' | translate}}" formControlName="comments" pInputText class="w-full text-sm h-5rem"></textarea>
            </div>

          </div>

          <p-divider styleClass="my-0"></p-divider>

          <div class="flex align-items-center justify-content-between p-3">
            <button pButton type="button" label="{{'common.BUTTONS.CANCEL-BUTTON-TEXT' | translate}}" (click)="GoToFitnessCalendar()" class="text-sm p-button-text"></button>
            <button pButton type="submit" label="{{'SCHEDULE.CREATECLASS.CREATE_REP_CLASS_BTN' | translate}}" [disabled]="disableSaveBtn" class="text-sm"></button>
          </div>

        </div>
        <!-- Card -->

      </div>
    </form>

    <!-- Body Content-->

    <!-- Footer -->
    <app-footer></app-footer>
    <!-- Footer -->

  </div>
  <!-- Site Content -->

</div>




<rrule [visible]="showRRule" [showNeverStop]="true" [minimumDate]="minimumDate" [rRuleObj]="RRuleObject" (hideRrule)="HideRRule($event)"
(emitRRule)="FetchRRuleData($event)">
</rrule>

<app-errors *ngIf="showErrorPopup$ | async as showPopup" [error]="error" [visible]="showPopup" [errorData]="errorMessages"
  (hideErrorPopup)="HideErrorPopup($event)">
</app-errors>

<p-toast position="top-right"></p-toast>

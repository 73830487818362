
  <ng-container *ngIf="{
    row: columnData | async,
    selections: isAllSelected | async,
    selected :  selectedRows | async
  } as $">

  <ng-container *ngIf="!$.selections && !$.selected.has(rowIndex)">
    <div
    class="w-2rem h-2rem border-round bg-white mx-auto flex align-items-center justify-content-center cursor-pointer hover:bg-gray-100" pStyleClass="@next" enterClass="hidden" enterActiveClass="scalein" leaveToClass="hidden"
    leaveActiveClass="fadeout" [hideOnOutsideClick]="true"><i class="pi pi-ellipsis-v text-600"></i>
  </div>

    <!-- Dropdown -->
    <ul class="list-none ml-0 px-0 py-2 border-round shadow-2 absolute text-800 hidden origin-top w-21rem right-0 top-0 mt-5 bg-white text-left text-sm z-3">
      <li class="px-3 py-2 flex align-items-center hover:bg-gray-200 cursor-pointer"
        (click)="toggleEditProfileDialog()">
        <i class="pi pi-pencil mr-3 text-600"></i>
        <span>{{'CLIENT_PROFILE.EDIT_PROFILE' | translate}} </span>
      </li>
      <li class="px-3 py-2 flex align-items-center hover:bg-gray-200 cursor-pointer"
        (click)="showOpp();">
        <i class="pi pi-user-plus mr-3 text-600"></i>
        <span>{{'CLIENT_PROFILE.CREATE_OPPORTUNITY' | translate}}</span>
      </li>
      <li class="px-3 py-2 flex align-items-center hover:bg-gray-200 cursor-pointer" (click)="newTask($.row)">
        <i class="pi pi-list mr-3 text-600"></i>
        <span>{{'CLIENT_PROFILE.CREATE_TASK' | translate}}</span>
      </li>
      <li class="px-3 py-2 flex align-items-center hover:bg-gray-200 cursor-pointer" (click)="navigateToBillinging($.row)">
        <i class="pi pi-plus-circle mr-3 text-600"></i>
        <span>{{'CLIENT_PROFILE.CREATE_SUBSCRIPTION' | translate}}</span>
      </li>
      <!-- <li class="px-3 py-2 flex align-items-center hover:bg-gray-200 cursor-pointer">
        <i class="pi pi-money-bill mr-3 text-600"></i>
        <span>{{'CLIENT_PROFILE.CHARGE_A_FEE' | translate}}</span>
      </li> -->
      <!-- <li class="px-3 py-2 flex align-items-center hover:bg-gray-200 cursor-pointer"
        (click)="toggleChangePasswordDialog()">
        <i class="pi pi-lock mr-3 text-600"></i>
        <span>{{'CLIENT_PROFILE.CHANGE_PASSWORD' | translate}}</span>
      </li> -->
      <li class="px-3 py-2 flex align-items-center hover:bg-gray-200 cursor-pointer"
        (click)="toggleChangeSaleRepDialog()">
        <i class="pi pi-users mr-3 text-600"></i>
        <span>{{'CLIENT_PROFILE.CHANGE_SALES_REP' | translate}}</span>
      </li>
      <!-- <li class="px-3 py-2 flex align-items-center hover:bg-gray-200 cursor-pointer">
        <i class="pi pi-paperclip mr-3 text-600"></i>
        <span>{{'CLIENT_PROFILE.ATTACHEMENTS' | translate}}</span>
      </li> -->
      <li class="px-3 py-2 flex align-items-center hover:bg-gray-200 cursor-pointer"
        (click)="toggleAssignKeyFobDialog()">
        <i class="pi pi-key mr-3 text-600"></i>
        <span>{{'CLIENT_PROFILE.ASSIGN_CHANGE_KEY_FOB' | translate}}</span>
      </li>
      <!-- <li class="px-3 py-2 flex align-items-center hover:bg-gray-200 cursor-pointer"
        (click)="deleteProfileModal = true">
        <i class="pi pi-user-minus mr-3 text-600"></i>
        <span>{{'CLIENT_PROFILE.DELETE_ANONYMIZE_PROFILE' | translate}}</span>
      </li> -->
      <!-- <li class="px-3 py-2 flex align-items-center hover:bg-gray-200 cursor-pointer"
        (click)="mergeProfileModal=true">
        <i class="pi pi-arrows-v mr-3 text-600"></i>
        <span>{{'CLIENT_PROFILE.MERGE_PROFILE_WITH_OTHER' | translate}}</span>
      </li> -->
      <li class="px-3 py-2 flex align-items-center hover:bg-gray-200 cursor-pointer"
        (click)="toggleBlockClientDialog()">
        <i class="pi pi-times-circle mr-3 text-600"></i>
        <ng-container *ngIf="$.row">
          <span *ngIf="!$.row?.is_blacklisted">{{'CLIENT_PROFILE.BLOCK_CLIENT' | translate}}</span>
          <span *ngIf="$.row?.is_blacklisted">{{'CLIENT_PROFILE.UNBLOCK_CLIENT' | translate}}</span>
         </ng-container>
      </li>
    </ul>
  <!-- Dropdown -->

   <!-- Activities -->

  <!-- Activities -->



  <edit-profile *ngIf="toggleEditProfileDialog$ | async" [editProfileModal]="toggleEditProfileDialog$ | async" [clientData$]="columnData"
    (closeEditProfileDialog)="closeEditProfile($event)">
  </edit-profile>

  <client-profile-change-password  *ngIf="toggleChangePasswordDialog$ | async"  [changePasswordModal]="toggleChangePasswordDialog$ | async">
  </client-profile-change-password>


  <!-- Assign Key FOB Modal  -->
  <assign-key-fob *ngIf="toggleAssignKeyFobDialog$ | async" [assignKeyFobModal]="toggleAssignKeyFobDialog$ | async" [clientData$]="columnData"
    (closeassignKeyFobModalDialog)="closeAssignKeyFob($event)"></assign-key-fob>
  <!-- Assign Key FOB Modal -->
  <!-- Block Profile Modal  -->
  <block-client *ngIf="toggleBlockClientDialog$ | async" [blockClientModal]="toggleBlockClientDialog$ | async" [clientData$]="columnData"
    (closeBlockClientDialog)="closeBlockClientDialog($event)">
  </block-client>

  <!-- Block Profile Modal -->

  <!-- Change Sales Rep Modal  -->
  <change-sales-representative *ngIf="toggleChangeSaleRepDialog$ | async" [changeRepresentativeModal]="toggleChangeSaleRepDialog$ | async"
    [clientData$]="columnData" (closeChangeRepresentativeDialog)="closeChangeSaleRepDialog($event)">
  </change-sales-representative>
  <!-- Change Sales Rep Modal  -->
  <create-opportunity *ngIf="showCreateOpportunityPopup" [visible]="showCreateOpportunityPopup" [clientInfo]="clientDetails"
    (hidePopup)="hideOpportunity($event)">
  </create-opportunity>
  <task-create *ngIf="showTaskCreate" [visible]="showTaskCreate" [cltIds]="clientIds" (hide)="HideTaskCreate($event)">
  </task-create>

  <!-- <billing-summary *ngIf="showPaidFullSidebar" [visible]="showPaidFullSidebar" [planSummarydata]="planSummarydataObject"
  (hideSidePopup)="HideBillingSummary($event)" closeOnEscape="false">

</billing-summary> -->

  <!--NOT COVERED ON THIS SPRINT-->

  <!-- Cancel Invoice Modal  -->
  <p-dialog [(visible)]="cancelInvoiceModal" [resizable]="false" appendTo="body" [modal]="true"
    [breakpoints]="{'767px': '100vw'}" [style]="{width: '660px'}" styleClass="no-header-modal">

    <ng-template pTemplate="header" styleClass="hidden"></ng-template>
    <ng-template pTemplate="content" styleClass="p-0">


      <!-- Header -->
      <div class="h-4rem md:pl-4 pl-3 pr-5 flex align-items-center relative border-bottom-1 border-gray-300">

        <div class="flex align-items-center">
          <h2 class="mb-0 text-base md:text-lg font-normal">Cancel Invoice for <strong>Andrea Solan </strong>
            <span class="text-xs text-gray-600"> - 45653663</span></h2>
        </div>
        <div class="pi pi-times opacity-50 absolute right-0 top-0 mt-3 mr-3 md:mt-4 md:mr-4 cursor-pointer"
          (click)="cancelInvoiceModal = false"></div>
      </div>
      <!-- Header -->


      <!-- Modal Content -->
      <div class="text-left">
        <p-message severity="warn" text="Cancelling the invoice will cancel its receipt as well." styleClass=" w-full">
        </p-message>
      </div>
      <div class="surface-100 py-3 md:py-5 px-3 md:px-5">

        <h4 class="text-sm font-normal mb-2">Cancellation Reason</h4>
        <textarea pInputTextarea class="w-full h-5rem"></textarea>

      </div>
      <!-- Modal Content -->


      <!-- Modal Footer -->
      <div class="lg:px-4 px-2 py-3 border-top-1 border-gray-400 bg-white">
        <div class="flex align-items-center justify-content-between">
          <div class="w-4">
            <p-button label="Cancel" styleClass="p-button-link text-sm" (click)="cancelInvoiceModal = false">
            </p-button>
          </div>
          <div class="w-8 text-right">
            <button class="text-sm" disabled pButton pRipple label="Cancel Invoice"></button>
          </div>
        </div>
      </div>
      <!-- Modal Footer -->


    </ng-template>

  </p-dialog>
  <!-- Cancel Invoice Modal -->




  <!-- Change Bill Owner Modal  -->
  <p-dialog [(visible)]="changeBillOwnerModal" [resizable]="false" appendTo="body" [modal]="true"
    [breakpoints]="{'767px': '100vw'}" [style]="{width: '660px'}" styleClass="no-header-modal">

    <ng-template pTemplate="header" styleClass="hidden"></ng-template>
    <ng-template pTemplate="content" styleClass="p-0">


      <!-- Header -->
      <div class="h-3rem md:pl-4 pl-3 pr-5 flex align-items-center relative border-bottom-1 border-gray-300">

        <div class="flex align-items-center">
          <h2 class="mb-0 text-base md:text-lg font-normal">Change Bill Owner for <strong>Andrea Solan </strong>
            <span class="text-xs text-gray-600"> - 45653663</span></h2>
        </div>
        <div class="pi pi-times opacity-50 absolute right-0 top-0 mt-3 mr-3 md:mr-4 cursor-pointer"
          (click)="changeBillOwnerModal = false"></div>
      </div>
      <!-- Header -->


      <!-- Modal Content -->
      <div class="surface-100 border-bottom-1 border-gray-300 p-3 md:px-5">

        <!-- Column -->
        <div class="md:flex align-items-center">
          <div class="md:w-4 pr-2 text-sm">Current Bill Owner</div>
          <div class="md:w-8 flex align-items-center mt-2 md:mt-0">
            <span><img class="w-2rem h-2rem border-circle mr-2" src="/assets/img/avatar.jpg" alt=""></span>
            <span class="text-sm">Sukma Beet</span>
          </div>
        </div>
        <!-- Column -->

        <!-- Column -->
        <div class="md:flex align-items-center mt-4">
          <div class="md:w-4 pr-2 text-sm">New Bill Owner</div>
          <div class="md:w-8 relative">
            <div class="flex align-items-center mt-2 md:mt-0 text-sm p-inputtext py-1 h-3rem" contentEditable="true"
              pStyleClass="@next" enterClass="hidden" enterActiveClass="scalein" leaveToClass="hidden"
              leaveActiveClass="fadeout" [hideOnOutsideClick]="true">
              <p-avatar label="JD" styleClass="p-mr-2" shape="circle">
              </p-avatar>
              <span class="ml-2">John Doe</span>
            </div>
            <ul
              class="list-none p-0 m-0 border-round shadow-0 lg:shadow-2 lg:absolute surface-overlay hidden origin-top h-14rem w-full overflow-y-auto z-2">
              <li
                class="flex align-items-center py-2 px-3 hover:bg-gray-100 border-bottom-1 border-gray-300 cursor-pointer">
                <p-avatar label="JD" styleClass="p-mr-2" shape="circle">
                </p-avatar>
                <span class="ml-2"><span class="text-primary">Su</span>rya Mishra</span>
              </li>
              <li
                class="flex align-items-center py-2 px-3 hover:bg-gray-100 border-bottom-1 border-gray-300 cursor-pointer">
                <p-avatar label="JD" styleClass="p-mr-2" shape="circle">
                </p-avatar>
                <span class="ml-2"><span class="text-primary">Su</span>kma Beet</span>
              </li>
              <li
                class="flex align-items-center py-2 px-3 hover:bg-gray-100 border-bottom-1 border-gray-300 cursor-pointer">
                <img class="w-2rem h-2rem border-circle" src="/assets/img/avatar.jpg" alt="Sumaya Karogera" />
                <span class="ml-2"><span class="text-primary">Su</span>maya Karogera</span>
              </li>
              <li
                class="flex align-items-center py-2 px-3 hover:bg-gray-100 border-bottom-1 border-gray-300 cursor-pointer">
                <p-avatar label="JD" styleClass="p-mr-2" shape="circle">
                </p-avatar>
                <span class="ml-2"><span class="text-primary">Su</span>rya Mishra</span>
              </li>
              <li
                class="flex align-items-center py-2 px-3 hover:bg-gray-100 border-bottom-1 border-gray-300 cursor-pointer">
                <p-avatar label="JD" styleClass="p-mr-2" shape="circle">
                </p-avatar>
                <span class="ml-2"><span class="text-primary">Su</span>kma Beet</span>
              </li>
              <li
                class="flex align-items-center py-2 px-3 hover:bg-gray-100 border-bottom-1 border-gray-300 cursor-pointer">
                <img class="w-2rem h-2rem border-circle" src="/assets/img/avatar.jpg" alt="Sumaya Karogera" />
                <span class="ml-2"><span class="text-primary">Su</span>maya Karogera</span>
              </li>
            </ul>
          </div>
        </div>
        <!-- Column -->
        <div class="flex align-items-center justify-content-between mt-3 border-top-1 border-gray-300 pt-3">
          <div class="w-4">
            <p-button label="Cancel" styleClass="p-button-link p-0 text-sm" (click)="changeBillOwnerModal = false">
            </p-button>
          </div>
          <div class="w-8 text-right">
            <button class="text-sm" disabled pButton pRipple label="Update Bill Owner"></button>
          </div>
        </div>

      </div>
      <!-- Modal Content -->


      <!-- Modal Content -->
      <div class="bg-white px-3 py-4 md:px-5">

        <h5 class="text-sm md:text-base font-normal">Bill Owner Change History</h5>

        <p-table [value]="tableData" styleClass="shadow-2 p-0 my-3" responsiveLayout="scroll">
          <ng-template pTemplate="header">
            <tr class="text-xs font-bold">
              <th class="text-gray-600 border-none py-2">DATE & TIME</th>
              <th class="text-gray-600 border-none py-2">CREATED BY</th>
              <th class="text-gray-600 border-none py-2">BILL OWNER</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-tableData>
            <tr class="text-sm">
              <td class="py-2 border-bottom-1 border-gray-300 white-space-nowrap">16 May 2021
                <span class="ml-3">12:30 PM</span></td>
              <td class="py-3 border-bottom-1 border-gray-300 white-space-nowrap">
                <button class="p-button-link p-0 text-sm" pButton label="John Dew"
                  (click)="createdBy2.toggle($event)"></button>
                <p-overlayPanel #createdBy2>
                  <ng-template pTemplate>
                    <div class="flex">
                      <p-avatar label="JD" styleClass="p-mr-2" shape="circle" size="large">
                      </p-avatar>
                      <div class="ml-3">
                        <h6 class="text-base mb-1 font-medium">John Dew</h6>
                        <ul class="list-none p-0 m-0 text-gray-600 text-sm">
                          <li class="mt-1">Department - ADMIN</li>
                          <li class="mt-1">Mobile - 98990 00963</li>
                          <li class="mt-1">Email - <a class="no-underline text-primary"
                              href="mailto:surabhi@surabhirout.com">surabhi@surabhirout.com</a></li>
                        </ul>
                      </div>
                    </div>
                  </ng-template>
                </p-overlayPanel>
              </td>
              <td class="py-3 border-bottom-1 border-gray-300 white-space-nowrap">
                <button class="p-button-link p-0 text-sm" pButton label="John Dew"
                  (click)="billOwner2.toggle($event)"></button>
                <p-overlayPanel #billOwner2>
                  <ng-template pTemplate>
                    <div class="flex">
                      <p-avatar label="JD" styleClass="p-mr-2" shape="circle" size="large">
                      </p-avatar>
                      <div class="ml-3">
                        <h6 class="text-base mb-1 font-medium">John Dew</h6>
                        <ul class="list-none p-0 m-0 text-gray-600 text-sm">
                          <li class="mt-1">Department - ADMIN</li>
                          <li class="mt-1">Mobile - 98990 00963</li>
                          <li class="mt-1">Email - <a class="no-underline text-primary"
                              href="mailto:surabhi@surabhirout.com">surabhi@surabhirout.com</a></li>
                        </ul>
                      </div>
                    </div>
                  </ng-template>
                </p-overlayPanel>
              </td>
            </tr>
          </ng-template>
        </p-table>


        <h5 class="text-sm md:text-base font-normal mt-4">Bill Owner Change History</h5>

        <p-table [value]="tableData" [scrollable]="true" crollHeight="400px" scrollDirection="both">
          <ng-template pTemplate="header">
            <tr class="text-xs">
              <th class="text-gray-600 bg-gray-100 font-bold border-none w-4 py-2">DATE & TIME</th>
              <th class="text-gray-600 bg-gray-100 font-bold border-none w-4 py-2">CREATED BY</th>
              <th class="text-gray-600 bg-gray-100 font-bold border-none w-4 py-2">BILL OWNER</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-tableData>
            <tr class="text-sm">
              <td colspan="3"
                class="py-0 justify-content-center flex-wrap font-bold border-bottom-1 border-gray-300 white-space-nowrap w-full">
                <div class="my-3 w-full text-center">No records!</div>
                <img width="220" src="/assets/img/cat.svg" alt="No Records!" />
              </td>
          </ng-template>
        </p-table>

      </div>
      <!-- Modal Content -->


    </ng-template>

  </p-dialog>
  <!-- Change Bill Owner Modal  -->


  <!-- Merge Profile Modal  -->
  <p-dialog [(visible)]="mergeProfileModal" [resizable]="false" appendTo="body" [modal]="true"
    [breakpoints]="{'767px': '100vw'}" [style]="{width: '950px'}" styleClass="no-header-modal">

    <ng-template pTemplate="header" styleClass="hidden"></ng-template>
    <ng-template pTemplate="content" styleClass="p-0">


      <!-- Header -->
      <div class="md:px-4 p-3 flex align-items-center justify-content-between border-bottom-1 border-gray-300">

        <h2 class="mb-0 text-base md:text-lg font-normal">Merge Profile</h2>

        <div class="pi pi-times text-gray-500 cursor-pointer" (click)="mergeProfileModal = false"></div>
      </div>
      <!-- Header -->


      <!-- Modal Content -->
      <div class="surface-100 border-bottom-1 border-gray-300 p-3">

        <div class="lg:flex align-items-center justify-content-between lg:my-4">

          <!-- Left Side -->
          <div class="lg:w-4 text-center">
            <div class="relative inline-block">
              <img class="w-7rem h-7rem border-circle block mx-auto mb-3" src="/assets/img/avatar.jpg"
                alt="Andrea Solan's" />
              <div
                class="bg-gray-400 border-1 border-white w-2rem h-2rem border-circle p-2 flex align-items-center justify-content-center absolute right-0 bottom-0 mb-4 mr-3">
                <img src="/assets/img/icons/gold-crown.svg" alt="Icon" />
              </div>
            </div>
            <h4 class="text-base md:text-lg mb-1 font-bold">Andrea Solan - 676778</h4>
            <p class="mb-0">andrea@andreasolan.com</p>
          </div>
          <!-- Left Side -->
          <!-- Center Side -->
          <div class="lg:w-4 text-center p-error my-4 lg:my-0">
            <i class="pi pi-arrow-right text-7xl hidden lg:block"></i>
            <i class="pi pi-arrow-down text-5xl lg:hidden"></i>
          </div>
          <!-- Center Side -->
          <!-- Right Side -->
          <div class="lg:w-4 text-center">
            <div
              class="w-7rem h-7rem border-circle mb-3 surface-200 flex align-items-center justify-content-center block mx-auto">
              <i class="pi pi-question text-6xl text-gray-700"></i></div>

            <!-- Search Box -->

            <div class="relative z-2">

              <!-- Input Field -->
              <div *ngIf="hideDropdown" class="p-input-icon-right w-full" pStyleClass=".search-dropdown"
                enterClass="hidden" enterActiveClass="scalein" leaveToClass="hidden" leaveActiveClass="fadeout"
                [hideOnOutsideClick]="true">
                <i class="pi pi-search text-gray-500"></i>
                <input type="text" pInputText class="w-full text-sm" placeholder="Search client by Name or Client ID" />
              </div>
              <!-- Input Field -->
              <!-- Selected Result -->
              <div *ngIf="!hideDropdown"
                class="border-1 bg-white border-gray-300 border-round p-2 flex align-items-center justify-content-between mx-1"
                pStyleClass=".search-dropdown" enterClass="hidden" enterActiveClass="scalein" leaveToClass="hidden"
                leaveActiveClass="fadeout" [hideOnOutsideClick]="true">
                <div class="flex align-items-center text-sm">
                  <span><img class="border-circle" width="30" src="assets/img/avatar.jpg" alt="Avatar" /></span>
                  <span class="mx-2">Ferry Camill<strong class="text-primary">ar</strong></span>
                  <span class="text-600 font-normal text-xs">233434</span>
                </div>
                <div class="flex align-items-center">
                  <span class="bg-green-400 px-2 py-1 font-bold border-round text-white text-xxs">TRIAL</span>
                  <span class="pi pi-times text-gray-500 ml-2 cursor-pointer" (click)="hideDropdown=true"></span>
                </div>
              </div>
              <!-- Selected Result -->
              <!-- Dropdown -->
              <div *ngIf="hideDropdown"
                class="search-dropdown absolute right-0 hidden origin-top w-full bg-white border-1 border-round border-gray-300 shadow-2">
                <ul class="list-none m-0 p-0 text-sm overflow-y-auto h-12rem">
                  <li (click)="hideDropdown=false"
                    class="border-bottom-1 border-gray-300 p-2 cursor-pointer hover:bg-gray-100 flex align-items-center justify-content-between">
                    <div class="flex align-items-center">
                      <span><img class="h-1rem w-1rem md:h-2rem md:w-2rem" class="border-circle" width="32"
                          src="assets/img/avatar.jpg" alt="Avatar" /></span>
                      <span class="mx-2">Ferry Camill<strong class="text-primary">ar</strong></span>
                      <span class="text-600 font-normal text-xs">233434</span>
                    </div>
                    <span class="bg-green-400 px-2 py-1 font-bold border-round text-white text-xxs">TRIAL</span>
                  </li>
                  <li (click)="hideDropdown=false"
                    class="border-bottom-1 border-gray-300 p-2 cursor-pointer hover:bg-gray-100 flex align-items-center justify-content-between">
                    <div class="flex align-items-center">
                      <span><img class="h-1rem w-1rem md:h-2rem md:w-2rem" class="border-circle" width="32"
                          src="assets/img/avatar.jpg" alt="Avatar" /></span>
                      <span class="mx-2">Ferry Camill<strong class="text-primary">ar</strong></span>
                      <span class="text-600 font-normal text-xs">233434</span>
                    </div>
                    <span class="bg-red px-2 py-1 font-bold border-round text-white text-xxs">ENQUIRY</span>
                  </li>
                  <li (click)="hideDropdown=false"
                    class="border-bottom-1 border-gray-300 p-2 cursor-pointer hover:bg-gray-100 flex align-items-center justify-content-between">
                    <div class="flex align-items-center">
                      <span><img class="h-1rem w-1rem md:h-2rem md:w-2rem" class="border-circle" width="32"
                          src="assets/img/avatar.jpg" alt="Avatar" /></span>
                      <span class="mx-2">Luettg<strong class="text-primary">ar</strong> Sterling</span>
                      <span class="text-600 font-normal text-xs">233434</span>
                    </div>
                    <span class="bg-blue-400 px-2 py-1 font-bold border-round text-white text-xxs">FREEZED</span>
                  </li>
                  <li (click)="hideDropdown=false"
                    class="border-bottom-1 border-gray-300 p-2 cursor-pointer hover:bg-gray-100 flex align-items-center justify-content-between">
                    <div class="flex align-items-center">
                      <span><img class="h-1rem w-1rem md:h-2rem md:w-2rem" class="border-circle" width="32"
                          src="assets/img/avatar.jpg" alt="Avatar" /></span>
                      <span class="mx-2">Zb<strong class="text-primary">ar</strong>cak Kole</span>
                      <span class="text-600 font-normal text-xs">233434</span>
                    </div>
                    <span class="bg-green-700 px-2 py-1 font-bold border-round text-white text-xxs">MEMBER</span>
                  </li>
                  <li (click)="hideDropdown=false"
                    class="border-bottom-1 border-gray-300 p-2 cursor-pointer hover:bg-gray-100 flex align-items-center justify-content-between">
                    <div class="flex align-items-center">
                      <span><img class="h-1rem w-1rem md:h-2rem md:w-2rem" class="border-circle" width="32"
                          src="assets/img/avatar.jpg" alt="Avatar" /></span>
                      <span class="mx-2">Ferry Camill<strong class="text-primary">ar</strong></span>
                      <span class="text-600 font-normal text-xs">233434</span>
                    </div>
                    <span class="bg-green-400 px-2 py-1 font-bold border-round text-white text-xxs">TRIAL</span>
                  </li>
                  <li (click)="hideDropdown=false"
                    class="border-bottom-1 border-gray-300 p-2 cursor-pointer hover:bg-gray-100 flex align-items-center justify-content-between">
                    <div class="flex align-items-center">
                      <span><img class="h-1rem w-1rem md:h-2rem md:w-2rem" class="border-circle" width="32"
                          src="assets/img/avatar.jpg" alt="Avatar" /></span>
                      <span class="mx-2">Ferry Camill<strong class="text-primary">ar</strong></span>
                      <span class="text-600 font-normal text-xs">233434</span>
                    </div>
                    <span class="bg-red px-2 py-1 font-bold border-round text-white text-xxs">ENQUIRY</span>
                  </li>
                  <li (click)="hideDropdown=false"
                    class="border-bottom-1 border-gray-300 p-2 cursor-pointer hover:bg-gray-100 flex align-items-center justify-content-between">
                    <div class="flex align-items-center">
                      <span><img class="h-1rem w-1rem md:h-2rem md:w-2rem" class="border-circle" width="32"
                          src="assets/img/avatar.jpg" alt="Avatar" /></span>
                      <span class="mx-2">Ferry Camill<strong class="text-primary">ar</strong></span>
                      <span class="text-600 font-normal text-xs">233434</span>
                    </div>
                    <span class="bg-blue-400 px-2 py-1 font-bold border-round text-white text-xxs">FREEZED</span>
                  </li>
                  <li (click)="hideDropdown=false"
                    class="border-bottom-1 border-gray-300 p-2 cursor-pointer hover:bg-gray-100 flex align-items-center justify-content-between">
                    <div class="flex align-items-center">
                      <span><img class="h-1rem w-1rem md:h-2rem md:w-2rem" class="border-circle" width="32"
                          src="assets/img/avatar.jpg" alt="Avatar" /></span>
                      <span class="mx-2">Ferry Camill<strong class="text-primary">ar</strong></span>
                      <span class="text-600 font-normal text-xs">233434</span>
                    </div>
                    <span class="bg-green-700 px-2 py-1 font-bold border-round text-white text-xxs">MEMBER</span>
                  </li>
                </ul>

              </div>
              <!-- Dropdown -->
            </div>

            <!-- Search Box -->

          </div>
          <!-- Right Side -->

        </div>

      </div>
      <!-- Modal Content -->


      <!-- Modal Content -->
      <div class="surface-200 border-bottom-1 border-gray-300 p-3">
        <h3 class="mb-0 font-bold text-base lg:text-2xl text-center">Andrea Solan - 676778 will be merged into
          ........</h3>
      </div>
      <!-- Modal Content -->

      <!-- Modal Content -->
      <div class="bg-white px-3 flex align-items-center justify-content-center">

        <ul class="list-none pl-0 text-gray-600 text-left line-height-3 text-sm">
          <li class="my-3 relative pl-5"><i class="pi pi-arrow-right absolute left-0 mt-1"></i>Lorem ipsum dolor sit
            amet, consectetur adipiscing elit.</li>
          <li class="my-3 relative pl-5"><i class="pi pi-arrow-right absolute left-0 mt-1"></i>In at magna a metus
            efficitur rutrum.</li>
          <li class="my-3 relative pl-5"><i class="pi pi-arrow-right absolute left-0 mt-1"></i>Quisque ac nisl
            mattis, fermentum ex id, consequat est.</li>
          <li class="my-3 relative pl-5"><i class="pi pi-arrow-right absolute left-0 mt-1"></i>Nunc id arcu posuere,
            suscipit augue ut, tempor augue.</li>
        </ul>

      </div>
      <!-- Modal Content -->

      <!-- Modal Footer -->
      <div class="lg:px-4 px-2 py-3 border-top-1 border-gray-400 bg-white">
        <div class="flex align-items-center justify-content-between">
          <div class="w-3">
            <p-button label="Cancel" styleClass="p-button-link text-sm" (click)="mergeProfileModal = false">
            </p-button>
          </div>
          <div class="w-9 flex align-items-center justify-content-end">
            <div class="p-error text-xs md:text-sm font-bold flex align-items-center justify-content-center mr-2"><i
                class="pi pi-exclamation-triangle mr-2"></i>This action can’t be undone!</div>
            <button class="text-sm" pButton pRipple label="Merge" (click)="mergeProfileModal = false"></button>
          </div>
        </div>
      </div>
      <!-- Modal Footer -->


    </ng-template>

  </p-dialog>
  <!-- Merge Profile Modal  -->
  <!-- Delete Profile Modal  -->
  <p-dialog [(visible)]="deleteProfileModal" [resizable]="false" appendTo="body" [modal]="true"
    [breakpoints]="{'767px': '100vw'}" [style]="{width: '580px'}" styleClass="no-header-modal">

    <ng-template pTemplate="header" styleClass="hidden"></ng-template>
    <ng-template pTemplate="content" styleClass="p-0">

      <!-- Content -->
      <div class="bg-white relative text-center p-3 md:p-4">

        <div class="pi pi-times opacity-50 absolute right-0 top-0 mt-3 mr-3 md:mt-4 md:mr-4 cursor-pointer"
          (click)="deleteProfileModal = false"></div>

        <div class="relative inline-block">
          <img class="w-7rem h-7rem border-circle block mx-auto mb-3" src="/assets/img/avatar.jpg" alt="Andrea Solan's" />
          <div
            class="bg-gray-400 border-1 border-white w-2rem h-2rem border-circle p-2 flex align-items-center justify-content-center absolute right-0 bottom-0 mb-4 mr-3">
            <img src="/assets/img/icons/gold-crown.svg" alt="Icon" />
          </div>
        </div>

        <h4 class="text-lg mb-2">Delete Andrea Solan's profile</h4>
        <p class="text-gray-600 mb-0">Are you sure you want to anonyomize this profile?</p>

        <div class="border-bottom-1 border-gray-300 my-4"></div>

        <h5 class="font-normal text-sm mb-2">Please enter "I confirm Andrea Solan can be deleted" on the below
          textbook.</h5>
        <input type="text" pInputText placeholder="" class="p-inputtext-sm w-full" />

      </div>
      <!-- Content -->

      <!-- Content -->
      <div class="surface-100 p-4 md:flex justify-content-center">
        <ol class="list-decimal text-xs text-gray-600 md:pl-6 m-0">
          <li class="my-1">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</li>
          <li class="my-1">Sed id elit at erat pretium efficitur at ut sapien.</li>
          <li class="my-1">Donec gravida diam at tortor vestibulum laoreet.</li>
          <li class="my-1">Nunc et nulla quis diam pretium condimentum.</li>
          <li class="my-1">Aliquam commodo dui vel purus maximus, non sodales tellus facilisis.</li>
        </ol>
      </div>
      <!-- Content -->

      <!-- Modal Footer -->
      <div class="lg:px-4 px-2 py-3 border-top-1 border-gray-400 bg-white">
        <div class="flex align-items-center justify-content-between">
          <div class="w-4">
            <p-button label="Cancel" styleClass="p-button-link text-sm" (click)="deleteProfileModal = false">
            </p-button>
          </div>
          <div class="w-8 text-right">
            <p-button label="Continue" styleClass="text-sm p-button-danger" (click)="deleteProfileModal = false"
              (click)="confirmDeleteProfileModal = true">
            </p-button>
          </div>
        </div>
      </div>
      <!-- Modal Footer -->


    </ng-template>

  </p-dialog>
  <!-- Delete Profile Modal -->


  <!-- Confirm Delete Profile Modal  -->
  <p-dialog [(visible)]="confirmDeleteProfileModal" [resizable]="false" appendTo="body" [modal]="true"
    [breakpoints]="{'767px': '100vw'}" [style]="{width: '470px'}" styleClass="no-header-modal">

    <ng-template pTemplate="header" styleClass="hidden"></ng-template>
    <ng-template pTemplate="content" styleClass="p-0">

      <!-- Content -->
      <div class="relative text-center px-5 pt-5 pb-3">

        <div class="pi pi-times opacity-50 absolute right-0 top-0 mt-3 mr-3 md:mt-4 md:mr-4 cursor-pointer"
          (click)="confirmDeleteProfileModal = false"></div>

        <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-alert-triangle w-5rem h-5rem"
          width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="#dc3545" fill="none" stroke-linecap="round"
          stroke-linejoin="round">
          <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
          <path d="M12 9v2m0 4v.01"></path>
          <path d="M5 19h14a2 2 0 0 0 1.84 -2.75l-7.1 -12.25a2 2 0 0 0 -3.5 0l-7.1 12.25a2 2 0 0 0 1.75 2.75">
          </path>
        </svg>
        <h4 class="text-lg mb-2 my-5">Are you sure?</h4>
        <p class="text-gray-600 mb-3 text-sm">If you terminate this member, he will not be able to:</p>
        <ul class="list-none pl-0 ml-2 mb-5 text-gray-600 text-left line-height-4 text-sm">
          <li class="my-2 relative pl-5"><i class="pi pi-arrow-right absolute left-0 mt-2"></i>Access mobile app if
            installed.</li>
          <li class="my-2 relative pl-5"><i class="pi pi-arrow-right absolute left-0 mt-2"></i>Purchase any other
            membership unless the termination is revoked.</li>
          <li class="my-2 relative pl-5"><i class="pi pi-arrow-right absolute left-0 mt-2"></i>Only someone with
            access right will be able to revoke termination.</li>
        </ul>

      </div>
      <!-- Content -->

      <!-- Modal Footer -->
      <div class="lg:px-4 px-2 py-3 border-top-1 border-gray-400 bg-white">
        <div class="flex align-items-center justify-content-between">
          <div class="w-4">
            <p-button label="Cancel" styleClass="p-button-link text-sm" (click)="confirmDeleteProfileModal = false">
            </p-button>
          </div>
          <div class="w-8 text-right">
            <p-button label="Continue" styleClass="text-sm p-button-danger" (click)="confirmDeleteProfileModal = false">
            </p-button>
          </div>
        </div>
      </div>
      <!-- Modal Footer -->

    </ng-template>

  </p-dialog>
  <!-- Confirm Delete Profile Modal -->
  </ng-container>

</ng-container>

import { Pipe, PipeTransform, NgModule } from '@angular/core';
import { DatePipe } from '@angular/common';
import { LOCALE_ID, Inject } from '@angular/core';
import { TimeconversionService } from '@fitness-force/api';
import { TranslateService } from '@ngx-translate/core';
@Pipe({
  name: 'datetimeformat',
})
export class DateTimeFormatPipe  implements PipeTransform {
  constructor(@Inject(LOCALE_ID) public locale: string, private timeService: TimeconversionService,
  private translateService: TranslateService) {}

  private readonly datePatterns = new Map<string, string>([
    ['short', 'd/M/y, h:mm:ss a'],
    ['medium', 'MMM d, y, h:mm:ss a'],
    ['long', 'EEEE, LLLL d, y, h:mm:ss a'],
    ['dateOnly', 'MMM d, y'],
    ['full','EEEE, MMMM d, y, h:mm:ss a zzzz'],
    ['shortDate','M/d/yy'],
    ['mediumDate','MMM d, y'],
    ['longDate','MMMM d, y'],
    ['fullDate','EEEE, MMMM d, y'],
    ['shortTime','h:mm a'],
    ['mediumTime','h:mm:ss a'],
    ['longTime','h:mm:ss a z'],
    ['fullTime','h:mm:ss a zzzz'],
    ['atDate','dd MMM yyyy,hh:mm a'],
    ['usformat','M/d/yyyy'],
    ['expiryDate','M/d'],
    ['date','d'],
    ['month','M'],
    ['year','yyyy'],
  ]);


  // transform(date: Date | string  ,format?: string): string | null {

  //   let localDate= this.timeService.ConvertUTCToLocalDateTime(date);
  //   if (format) {
  //     const pattern = this.datePatterns.get(format!);
  //     if (pattern)
  //     {
  //       if(format == 'atDate')
  //       {
  //         return new DatePipe(this.locale).transform(localDate, pattern.split(',')[0])+" at "+ new DatePipe(this.locale).transform(localDate,pattern.split(',')[1]);
  //       }
  //       else return new DatePipe(this.locale).transform(localDate, pattern);
  //     }
  //     else return new DatePipe(this.locale).transform(localDate, format);
  //   } else {
  //     return new DatePipe(this.locale).transform(localDate,this.datePatterns.get('dateOnly'));
  //   }
  // }


  transform(date: Date | string, format?: string): any {
    let pattern=this.datePatterns.get('dateOnly');
    if (format) pattern = this.datePatterns.get(format!);
  //  TODO:  Need to put defaultTimezone here discussion needed how localization is handled
    const datePipe: DatePipe = new DatePipe(this.translateService.currentLang);
    return datePipe.transform(date, pattern);
  }

}
export const dateCollection = [
  {
    name: "Tomorrow", value: "tomorrow", date: (): Date => {
      return new Date((new Date()).setDate((new Date()).getDate() + 1));
    }
  },
  {
    name: "Yesterday", value: "yesterday", date: (): Date => {
      return new Date((new Date()).setDate((new Date()).getDate() - 1));
    }
  },
  {
    name: "In 2 days", value: "in 2 buisness days", date: (): Date => {
      return new Date((new Date()).setDate((new Date()).getDate() + 2));
    }
  },
  {
    name: "In 3 days", value: "in 3 buisness days", date: (): Date => {
      return new Date((new Date()).setDate((new Date()).getDate() + 3));
    }
  },
  {
    name: "this week", value: "this week", date: (): Date => {
      return new Date((new Date()).setDate((new Date()).getDate() + 2));
    }
  },
  {
    name: "In 1 Week", value: "in 1 week", date: (): Date => {
      return new Date((new Date()).getTime() + 7 * 24 * 60 * 60 * 1000);
    }
  },
  {
    name: "In 2 Weeks", value: "in 2 week", date: (): Date => {
      return new Date((new Date()).getTime() + 14 * 24 * 60 * 60 * 1000);
    }
  },
  {
    name: "In 1 Month", value: "in 1 month", date: (): Date => {
      let now = new Date();
      let lastDateOfNewDate: number = new Date(now.getFullYear(), now.getMonth() + 2, 0).getDate();
      let date = (now.getDate() >= lastDateOfNewDate ? lastDateOfNewDate : now.getDate()) - 1;
      return new Date(now.getFullYear(), now.getMonth() + 1, date);
    }
  },
  {
    name: "In 2 Months", value: "in 2 months", date: (): Date => {
      let now = new Date();
      let lastDateOfNewDate: number = new Date(now.getFullYear(), now.getMonth() + 3, 0).getDate();
      let date = (now.getDate() >= lastDateOfNewDate ? lastDateOfNewDate : now.getDate()) - 1;
      return new Date(now.getFullYear(), now.getMonth() + 2, date);
    }
  },
  {
    name: "In 6 Months", value: "in 6 months", date: (): Date => {
      let now = new Date();
      let lastDateOfNewDate: number = new Date(now.getFullYear(), now.getMonth() + 7, 0).getDate();
      let date = (now.getDate() >= lastDateOfNewDate ? lastDateOfNewDate : now.getDate()) - 1;
      return new Date(now.getFullYear(), now.getMonth() + 6, date);
    }
  },
  {
    name: "Last 6 Months", value: "last 6 months", date: (): Date => {
      let now = new Date();
      let lastDateOfNewDate: number = new Date(now.getFullYear(), now.getMonth() - 7, 0).getDate();
      let date = (now.getDate() >= lastDateOfNewDate ? lastDateOfNewDate : now.getDate()) - 1;
      return new Date(now.getFullYear(), now.getMonth() - 6, date);
    }
  },
  {
    name: "Custom Date", value: "custom date", date: (): Date => {
      return new Date()
    }
  }
];

export function weekData(type: string){
  let date = new Date();
  let dayOfWeekNumber = date.getDay();
  let nameOfDay;
  let quote;

  switch(dayOfWeekNumber){
      case 0:
          nameOfDay = 'Sunday';
          quote = 'Time to chillax!';
          break;
      case 1:
          nameOfDay = 'Monday';
          quote = 'Monday morning blues!';
          break;
      case 2:
          nameOfDay = 'Tuesday';
          quote = 'Taco Time!';
          break;
      case 3:
          nameOfDay = 'Wednesday';
          quote = 'Two more days to the weekend.';
          break;
      case 4:
          nameOfDay = 'Thursday';
          quote = 'The weekend is almost here...';
          break;
      case 5:
          nameOfDay = 'Friday';
          quote = 'Weekend is here!';
          break;
      case 6:
          nameOfDay = 'Saturday';
          quote = 'Time to party!';
          break;

  }

 let curr = new Date; // get current date
 let first = curr.getDate() - curr.getDay(); // First day is the day of the month - the day of the week
let  last = first + 6; // last day is the first day + 6

let firstDayWeek = new Date(curr.setDate(first));
let lastDayWeek = new Date(curr.setDate(last));
let nextweekFristDate = new Date(curr.setDate(last+1));
  let nextweekLastDate = new Date(curr.setDate(last+7));


  if(type == 'start'){
    return firstDayWeek;//new Date(firstDayWeek.toLocaleDateString())
  } else if(type == 'end'){
    return lastDayWeek;//new Date(lastDayWeek.toLocaleDateString())
  } else if(type == 'nextweekstart'){
    return nextweekFristDate
  } else if(type == 'nextweekend'){
    return nextweekLastDate
  } else {
    return null
  }
}
@NgModule({
  declarations: [DateTimeFormatPipe],
  imports: [],
  exports: [DateTimeFormatPipe],
})
export class DateTimeFormatPipeModule {}

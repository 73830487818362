import { OpportunityBoardType } from "@fitness-force/enum";

export interface PlanSummaryCRM {
    opp_id: number;
    task_id: number;
    board_id:OpportunityBoardType
}

export interface PlanSummaryClient {
    client_id: number;
    client_name: string;
    client_status: string;
}
<p-dialog [(visible)]="showPopup" [resizable]="false" appendTo="body" (onHide)="ClosePopUp();" [modal]="true"
    [breakpoints]="{'1025px': '100vw', '640px': '100vw'}" [style]="{width: '55vw'}" styleClass="no-header-modal">
    <ng-template pTemplate="header" styleClass="hidden"></ng-template>
    <ng-template pTemplate="content" styleClass="p-0">
        <form [formGroup]="formGroup" (ngSubmit)="CreateTask()">
            <p-toast (onClose)="ClosePopUp()" ></p-toast>
            <div
                class="p-3 md:py-3 md:px-4 flex align-items-center justify-content-between relative border-bottom-1 border-gray-300">
                <div class="flex align-items-center mr-3">
                    <i class="pi pi-plus text-gray-500 mr-3"></i>
                    <h2 class="mb-0 font-normal text-lg" *ngIf="(clientIds$ | async)?.length < 2">
                        {{'Shared.TASK.TASK-CREATE.CREATE_TASK' | translate}}
                    </h2>
                    <h2 class="mb-0 font-normal text-lg" *ngIf="(clientIds$ | async)?.length > 1">
                        {{'Shared.TASK.TASK-CREATE.CREATE_TASK_FOR' | translate}}
                        <strong>{{(clientIds$ | async)?.length}} {{'Shared.TASK.TASK-CREATE.MEMBERS' | translate}}
                        </strong>
                    </h2>

                </div>
                <div class="pi pi-times text-gray-500 cursor-pointer" (click)="ClosePopUp();"></div>
            </div>
            <!-- Header -->

            <!-- Header -->
            <div class="px-1 md:px-3">
                <mini-search *ngIf="showPopup" 
                [id]="(clientIds$ | async)" 
                (clients)="setClient($event)" 
                [readonly]="(clientIds$ | async)?.length>0"></mini-search>
              </div>
            <!-- Header -->
      <!-- Content -->
      <div class="surface-50 text-sm py-3 px-4 border-bottom-1 border-gray-300">
        <div class="grid">
          <div class="col-6 md:col-3">
            <label class="text-gray-700 text-xs mb-2 block uppercase">
              {{'Shared.TASK.TASK-CREATE.TASK_CATEGORY' | translate}}
            </label>
            <task-category [formGroup]="formGroup" [controlName]="SelectedTaskCategory"
              placeHolder="{{'Shared.TASK.TASK-CREATE.SELECT' | translate}}">
            </task-category>
            <app-error-msg *ngIf="SelectedTaskCategory.dirty && SelectedTaskCategory.invalid"
              [errors]="SelectedTaskCategory.errors"
              [fieldLabel]="'Shared.TASK.TASK-CREATE.SELECT_TASK_CATEGORY' | translate">
            </app-error-msg>
          </div>
          <div class="col-6 md:col-3">
            <label class="text-gray-700 text-xs mb-2 block uppercase">
              {{'Shared.TASK.TASK-CREATE.TASK_TYPE' | translate}}
            </label>
            <task-type [formGroup]="formGroup" [controlName]="SelectedTask"
              placeHolder="{{'Shared.TASK.TASK-CREATE.SELECT' | translate}}" [langCode]="languageCode">
            </task-type>
            <app-error-msg *ngIf="SelectedTask.dirty && SelectedTask.invalid" [errors]="SelectedTask.errors"
              [fieldLabel]="'Shared.TASK.TASK-CREATE.SELECT_TASK' | translate">
            </app-error-msg>
          </div>
          <div class="col-6 md:col-3">
            <label class="text-gray-700 text-xs mb-2 block uppercase">
              {{'Shared.TASK.TASK-CREATE.PRIORITY' | translate}}
            </label>
            <priority [formGroup]="formGroup" [controlName]="SelectedPriority"
              placeHolder="{{'Shared.TASK.TASK-CREATE.SELECT' | translate}}" [langCode]="languageCode">
            </priority>
            <app-error-msg *ngIf="SelectedPriority.dirty && SelectedPriority.invalid"
              [errors]="SelectedPriority.errors" [fieldLabel]="'Shared.TASK.TASK-CREATE.SELECT_PRIORITY' | translate">
            </app-error-msg>
          </div>
          <div class="col-6 md:col-3">
            <label class="text-gray-700 text-xs mb-2 block uppercase">
              {{'Shared.TASK.TASK-CREATE.ASSIGNED_TO' | translate}}
            </label>
            <div class="w-10rem">
              <staff-selector [formGroup]="formGroup" [controlName]="SelectedAssigned" 
              [id]="assignedToId" [styleClass]="' border-none surface-50 w-full md:w-auto text-sm'">
              </staff-selector>
            </div>
            <app-error-msg *ngIf="SelectedAssigned.dirty && SelectedAssigned.invalid"
              [errors]="SelectedAssigned.errors" [fieldLabel]="'Shared.TASK.TASK-CREATE.SELECT_ASSIGNED' | translate">
            </app-error-msg>
          </div>
        </div>
        <p-divider styleClass="mt-0 mb-3"></p-divider>
        <div class="grid md:mb-0 mb-2">
          <div class="col-12 md:col-9">
            <label class="font-medium text-xs uppercase text-gray-700 mb-2 block">
              {{'Shared.TASK.TASK-CREATE.SCHEDULE' | translate}}
            </label>
            <div class="grid">
              <div class="col-6">
                <p-calendar [showIcon]="true" formControlName="scheduleDate"
                  placeholder="{{'Shared.TASK.TASK-CREATE.PLACEHOLDER_SELECT_DATE' | translate}}"
                  inputStyleClass="text-sm"></p-calendar>
                <app-error-msg *ngIf="ScheduleDate.dirty && ScheduleDate.invalid" [errors]="ScheduleDate.errors"
                  [fieldLabel]="'Shared.TASK.TASK-CREATE.SCHEDULE_DATE' | translate">
                </app-error-msg>
              </div>
              <div class="col-6 relative">
                <p-calendar [showIcon]="true" icon="pi pi-clock" hourFormat="12" [timeOnly]="true" inputId="timeonly"
                  [showIcon]="true" formControlName="scheduleTime"
                  placeholder="{{'Shared.TASK.TASK-CREATE.PLACEHOLDER_SELECT_TIME' | translate}}" StyleClass="text-sm"
                  inputStyleClass="text-sm"></p-calendar>
                <app-error-msg *ngIf="ScheduleTime.dirty && ScheduleTime.invalid" [errors]="ScheduleTime.errors"
                  [fieldLabel]="'Shared.TASK.TASK-CREATE.SCHEDULE_TIME' | translate">
                </app-error-msg>
              </div>
            </div>
          </div>
          <div class="col-12 -mt-2">
            <label class="font-medium text-xs text-gray-700 mb-2 uppercase block">
              {{'Shared.TASK.TASK-CREATE.REPEAT' | translate}}
            </label>
            <!-- Input Field -->
            <div *ngIf="!isRruleSet" class="p-input-icon-right w-full text-sm mr-3" (click)="showRRule=true">
              <i class="pi pi-angle-down text-gray-500"></i>
              <div class="p-inputtext p-component p-element bg-blue-50 md:w-5 cursor-pointer">Select...
              </div>
            </div>
            <div *ngIf="isRruleSet" class="p-input-icon-right w-full text-sm cursor-pointer">
              <i class="pi pi-times text-gray-500" (click)="clearRRuleData()"></i>
              <div class="p-inputtext p-component p-element w-full" (click)="showRRule=true">
                {{RRuleData.RRuleText}}
              </div>
            </div>
            <!-- Input Field -->
          </div>
        </div>
        <label class="font-medium text-xs text-gray-700 my-2 block">
          {{'Shared.TASK.TASK-CREATE.TITLE' | translate}}
        </label>
        <input type="text" pInputText class="w-full text-sm" formControlName="title"
          placeholder="{{'Shared.TASK.TASK-CREATE.PLACEHOLDER_TITLE' | translate}}" />
        <app-error-msg *ngIf="Title.touched && Title.invalid" [errors]="Title.errors"
          [fieldLabel]="'Shared.TASK.TASK-CREATE.SELECT_TITLE' | translate">
        </app-error-msg>
      </div>
      <!-- Content -->
      <!-- Content -->
      <div class="surface-50 px-4 py-3 text-sm border-bottom-1 border-gray-400">
        <textarea pInputTextarea class="w-full text-sm h-6rem" formControlName="comment"
          placeholder="{{'Shared.TASK.TASK-CREATE.PLACEHOLDER_COMMENTS' | translate}}..."></textarea>
        <app-error-msg *ngIf="Comment.touched && Comment.invalid" [errors]="Comment.errors"
          [fieldLabel]="'Shared.TASK.TASK-CREATE.SELECT_COMMENT' | translate">
        </app-error-msg>
      </div>
      <!-- Content -->
      <!-- Modal Footer -->
      <div class="px-4 py-3">
        <div class="flex align-items-center justify-content-between">
          <div class="w-6">
            <p-button label="{{'Shared.TASK.TASK-CREATE.CANCEL' | translate}}" styleClass="p-button-link text-sm"
              (click)="ClosePopUp();">
            </p-button>
          </div>
          <div class="w-6 text-right">
            <button type="submit" class="text-sm" pButton pRipple
              label="{{'Shared.TASK.TASK-CREATE.CREATE' | translate}}" [disabled]="!formGroup.valid"></button>
          </div>
        </div>
      </div>
      <!-- Modal Footer -->

      <!-- Child Components -->
      <rrule [visible]="showRRule" [rRuleObj]="RRuleData" [showNeverStop]="false" (hideRrule)="HideRRule($event)"
        (emitRRule)="FetchRRuleData($event)"></rrule>
      <!-- Child Components -->

      <!-- Error -->
      <app-errors *ngIf="showErrorPopup | async as show_Popup" [visible]="show_Popup" [errorData]="errorMessages"
        (hideErrorPopup)="HideErrorPopup($event)">
      </app-errors>
      <!-- Error -->

        </form>
    </ng-template>
</p-dialog>

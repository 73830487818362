
import { IPSettings, Roles, StaffGender, StaffStatus, Tenant_Access } from '@fitness-force/enum';


export interface STATE_STAFF_LIST {
    staffList: STAFFINTERFACE[],
    staffListAll: STAFFINTERFACE[]
}

export interface STAFFINTERFACE {
    id: number;
    company_id: number;
    password_last_changed: string;
    reports_to_name: string | null;
    created_by: number;
    created_date: string;
    updated_by: number;
    updated_date: string;
    is_system_staff: boolean;
    can_take_class: boolean;
    can_take_appointment: boolean;
    can_be_client_rep: boolean;
    name: string;
    claim_template_id: number;
    department: string;
    designation: string;
    role: Roles;
    is_public_bio_enabled: boolean;
    ip_setting: IPSettings;
    email_id: string;
    is_owner: boolean;
    status: StaffStatus;
    claim_template_name: string;
    ff_picture: string;
    mobile_number: number;
    mobile_country_code: string;
    tenant_access_id: number[];
}

export interface STAFF_RESPONSE {
    items: STAFFINTERFACE[],
    total_items: number,
    total_pages: number,
    current_page: number
}

export interface STAFF_LIST {
    staffList: STAFF_RESPONSE
}

export interface STAFF_MODEL {
    id: number;
    company_id: number;
    password_last_changed: Date;
    reports_to_id: number | null;
    reports_to_name: string | null;
    created_by: number;
    created_date: Date;
    updated_by: number;
    updated_date: Date;
    is_system_staff: boolean;
    can_take_class: boolean;
    can_take_appointment: boolean;
    can_be_client_rep: boolean;
    password_expires_in_hrs: number;
    claim_template_name: string;
    name: string;
    claim_template_id: number;
    department: string;
    designation: string;
    role: Roles;
    is_public_bio_enabled: boolean;
    ip_setting: IPSettings;
    mobile_country_code: string;
    mobile_number: number;
    birth_date: Date;
    gender: StaffGender;
    address: string;
    image: string;
    external_employee_id?: number;
    public_bio_video_url: string;
    public_bio_description_primary_language: string;
    public_bio_description_secondary_language: string;
    public_bio_achievements_primary_language: string;
    public_bio_achievements_secondary_language: string;
    additional_ips: string;
    referral_code: string;
    language_preference: string;
    phone_verified: boolean;
    email_verified: boolean;
    email_id: string;
    is_owner: boolean;
    status: StaffStatus;
    tenant_access: Tenant_Access[];
    zip: string;
}

export interface CREATE_STAFF {
    name: string,
    email_id: string,
    password: string,
    role: Roles,
    can_take_class: boolean,
    can_take_appointment: boolean,
    can_be_client_representative: boolean,
    reports_to_id: number,
    department: string,
    designation: string,
    ip_setting: IPSettings,
    additional_ips: string,
    mobile_number: number,
    mobile_country_code: string,
    birth_date: Date,
    ff_picture_base64: string,
    external_employee_id: string,
    gender: StaffGender,
    address: string,
    is_public_bio_enabled: boolean,
    public_bio_video_url: string,
    public_bio_description_primary_language: string,
    public_bio_description_secondary_language: string,
    public_bio_achievements_primary_language: string,
    public_bio_achievements_secondary_language: string,
    referral_code: string,
    language_preference: string,
    claim_template_id: number,
    tenant_access_id: number[],
    country: string,
    state: string,
    city: string,
    zip: string
}

export interface UPDATE_STAFF {
    name: string,
    email_id: string,
    role: Roles,
    can_take_class: boolean,
    can_take_appointment: boolean,
    can_be_client_rep: boolean,
    reports_to_id: number,
    department: string,
    designation: string,
    ip_setting: IPSettings,
    additional_ips: string,
    mobile_number: number,
    mobile_country_code: string,
    birth_date: Date,
    ff_picture_base64: string,
    external_employee_id: string,
    gender: StaffGender,
    address: string,
    is_public_bio_enabled: boolean,
    public_bio_video_url: string,
    public_bio_description_primary_language: string,
    public_bio_description_secondary_language: string,
    public_bio_achievements_primary_language: string,
    public_bio_achievements_secondary_language: string,
    referral_code: string,
    language_preference: string,
    claim_template_id: number,
    tenant_access_id: number[],
    country: string,
    state: string,
    city: string,
    zip: string
}

export interface StaffClaimFilter {    
    claim: string[], 
    role:string[],
    stricktlyFilter:boolean
}



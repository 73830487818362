import { OpportunityBoardType } from "@fitness-force/enum";
import { LatestTask } from "./opportunity";

export interface TaskOutcomeDetails{
    assignedId:number;
    convertibilityType:string
    opportunityBoardType:number;
    outcome:number;
    taskNotes:string;
    taskTitle:string;
    taskType:string;    
    taskId:number;
    client_details: ClientDetails;
    expected_closure_date: string;
    value_in_minor_currency:number;
    latest_task:LatestTask;
    stage_master_id:number;
    opportunityId:number;
}

export interface ClientDetails{
    clientId:number;
    name:string;
    emailId: string;
    image:string;
    mobileNo: number;
    countryCode: string;
    status: string;
}

import { PaymentGatewaySupported, PaymentGatewayType } from "@fitness-force/enum";

export interface GetSignUpPaymentMethodResponse {
    pgw_details: PaymentGatewayDetails[];
    doing_business_as: string;
    saved_payment_methods: SavedPaymentMethods[];
}

export interface PaymentGatewayDetails {
    payment_gateway_type: PaymentGatewayType;
    payment_gateway: PaymentGatewaySupported;
    merchant_publickey: string;
}

export interface SavedPaymentMethods {
    pay_token_id: string;
    pay_token_paymode_level1: string;
    pay_token_network_type: string;
    pay_token_exp_date: string;
    pay_token_bank_name: string;
    pay_token_masked_account_number: string;
    pay_token_upi_provider: string;
    pay_token_masked_vpa_id: string;
    pay_token_masked_card_number: string;
}


export interface CreateClientPaymentTokenResponse {
    id: number
}
<!--
<ng-template pTemplate="emptymessage" let-servicesDataEmpty>
  <tr class="text-sm"> -->
    <div
      colspan="5"
      class="
        py-0
        block
        text-center
        border-bottom-1 border-gray-300
        white-space-nowrap
        w-full
        text-sm
      "
    >
      <div class="my-5 w-full text-center text-lg">
        {{
          'ADMIN.SUBSCRIPTION.NO_SERVICE_ADDED' | translate
        }}
      </div>
      <button
        pButton
        pRipple
        type="button"
        label="{{
          'ADMIN.SUBSCRIPTION.ADD_SERVICE' | translate
        }}"
        icon="pi pi-plus"
        (click)="addServiceModal()"
        class="p-button-outlined text-sm bg-white"
      ></button>
      <br /><br /><br />
      <img
        width="300"
        src="/assets/img/no-services-added.jpg"
        alt="No service added!"
      />
      </div>
  <!-- </tr>
</ng-template> -->

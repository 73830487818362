<ng-container *ngIf="{
  row: columnData | async,
  adata: additionData | async
} as $">
  <button  pButton pRipple type="button" icon="pi pi-ellipsis-v"
    class="bg-white p-button-outlined border-white bg-white ml-2 md:ml-3 text-xs md:text-sm p-1 md:p-2 h-2rem w-2rem text-gray-600"
    (click)="action.toggle($event)"></button>

  <p-overlayPanel #action styleClass="overflow-hidden w-8rem">
    <ng-template pTemplate>
      <ul class="list-none -m-4 py-2 px-0 text-xs text-gray-600" (mouseover)="showOptions=true">
        <li *ngIf="$.adata.includes('edit')" (click)=" executeAction('edit',$.row,action) "
            class="cursor-pointer hover:bg-gray-300 py-2 px-3 flex align-items-center">
            <i class="pi pi-pencil text-xs mr-2"></i> {{ 'ADMIN.COMMON.EDIT' | translate }}
        </li>

        <li *ngIf="$.adata.includes('status')" (click)="executeAction('status',$.row,action) "
           class="cursor-pointer hover:bg-gray-300 py-2 px-3 flex align-items-center">
          <i class="pi  text-xs mr-2" [ngClass]="isActive($.row) ? 'pi-eye' : 'pi-eye-slash' "></i>
          {{ 'Shared.TASK.TASK-CREATE.MARK' | translate }} {{isActive($.row)? 'InActive': 'Active'}}
        </li>

        <li *ngIf=" $.adata.includes('delete')" (click)=" executeAction('delete',$.row,action)"
          class="cursor-pointer hover:bg-gray-300 py-2 px-3 flex align-items-center">
          <i class="pi pi-trash text-xs mr-2"></i> {{ 'ADMIN.COMMON.DELETE' | translate }}
        </li>

        <li *ngIf=" $.adata.includes('print')" (click)=" executeAction('print',$.row,action)"
          class="cursor-pointer hover:bg-gray-300 py-2 px-3 flex align-items-center">
          <i class="pi pi-print mr-2"></i> {{ 'ADMIN.COMMON.PRINT' | translate }}
        </li>

        <li *ngIf=" $.adata.includes('sendemail')" (click)=" executeAction('sendemail',$.row,action)"
          class="cursor-pointer hover:bg-gray-300 py-2 px-3 flex align-items-center">
          <i class="pi pi-envelope mr-2"></i> {{ 'ADMIN.COMMON.SENDEMAIL' | translate }}
        </li>

        <li *ngIf=" $.adata.includes('void')" (click)=" executeAction('void',$.row,action)"
          class="cursor-pointer hover:bg-gray-300 py-2 px-3 flex align-items-center">
          <i class="pi pi-ban mr-2"></i> {{ 'ADMIN.COMMON.VOID' | translate }}
        </li>

        <li *ngIf=" $.adata.includes('collectdues')" (click)=" executeAction('collectdues',$.row,action)"
          class="cursor-pointer hover:bg-gray-300 py-2 px-3 flex align-items-center">
          <i class="pi pi-money-bill mr-2"></i>
           {{ 'ADMIN.COMMON.COLLECTDUES' | translate }}
        </li>

        <li *ngIf=" $.adata.includes('refundandsalesreturn')" (click)=" executeAction('refundandsalesreturn',$.row,action)"
          class="cursor-pointer hover:bg-gray-300 py-2 px-3 flex align-items-center">
          <i class="pi pi-replay mr-2"></i>
           {{ 'ADMIN.COMMON.REFUNDSALESRETURN' | translate }}
        </li>

        <li *ngIf=" $.adata.includes('writeoff')" (click)=" executeAction('writeoff',$.row,action)"
          class="cursor-pointer hover:bg-gray-300 py-2 px-3 flex align-items-center"><i
          class="pi pi-file-excel mr-2"></i> {{ 'ADMIN.COMMON.WRITEOFF' | translate }}
        </li>

      </ul>
    </ng-template>
  </p-overlayPanel>
</ng-container>
